import React from "react";
import { useIntl } from "react-intl";
import { styled, Theme } from "@mui/material/styles";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TransitionProps } from "@mui/material/transitions";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import Drawer from "@mui/material/Drawer";
import Slide from "@mui/material/Slide";
import colors from "../assets/colors";
import IconButton from "@mui/material/IconButton";
import { Pencil } from "assets/svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

type StyledButtonProps = ButtonProps & {
  component?: typeof Link;
  to?: string;
  bgColor?: string;
};

export const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "bgColor",
})<{
  theme?: Theme;
  bgColor?: string;
}>(({ theme, bgColor }) => ({
  fontSize: "16px",
  lineHeight: "97.5%",
  borderRadius: "3px",
  gap: "6px",
  padding: "16px 36px",
  fontFamily: "NoirPro",
  color: theme.palette.secondary.main,
  backgroundColor: bgColor ?? theme.palette.action.active,
  ":hover": {
    background: bgColor ?? theme.palette.action.active,
  },
})) as React.ComponentType<StyledButtonProps>;

export const SimpleButton = styled(Button)(({ theme }) => ({
  boxShadow: "none",
}));

export const PageTitle = styled("h3")(({ theme }) => ({
  fontFamily: "NoirPro",
  fontWeight: 400,
  fontSize: "24px",
  color: `${theme.palette.text.primary}`,
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "NoirPro",
  fontWeight: 400,
  fontSize: "20px",
  color: `${theme.palette.text.primary}`,
}));

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "10px 20px",
  minHeight: "90vh",
}));

export const Content = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
}));

export const Row = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

export const Column = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const RowSpaced = styled(Row)(({ theme }) => ({
  justifyContent: "space-between",
}));

export const DataGridText = styled("span")(({ theme }) => ({
  fontSize: "13px",
  fontFamily: "NoirPro",
  lineHeight: "15px",
  color: colors.textSecondary,
  whiteSpace: "pre-line",
}));

// eslint-disable-next-line
export const ModalTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const GridWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 1,
}));

export const DataGridComp = styled(DataGrid)(({ theme }) => ({
  borderWidth: 0,
  "& td": { border: 0 },
  "& .MuiDataGrid-cellCheckbox": {
    // backgroundColor: 'red',
  },
  "& .MuiDataGrid-columnHeaderCheckbox": {
    visibility: "hidden",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontFamily: "Open Sans, sans-serif",
    fontSize: "13px",
    color: colors.textSecondary,
  },
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "rgb(255,215,115, 0.19)",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${
      theme.palette.mode === "dark" ? 0 : 0.1
    })`,
  },
}));

export const ModalDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.background.default,
  },
}));

export const DrawerForm = styled("form")(({ theme }) => ({
  margin: "0 20px 20px 20px",
  width: "400px",
}));

const CellButton = styled(IconButton)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
}));

interface Props<T> {
  rowData: GridRenderCellParams;
  onEdit: (rowData: T) => void;
}
export function ActionCell<T>({ rowData, onEdit }: Props<T>) {
  const intl = useIntl();
  const handleOnClick = () => onEdit(rowData.row);
  const copyToClipboardId = () =>
    navigator.clipboard.writeText(rowData?.row?.id ?? "");
  return (
    <Row>
      <Tooltip title={intl.formatMessage({ id: "label.edit" })}>
        <CellButton onClick={handleOnClick}>
          <Pencil size="25px" />
        </CellButton>
      </Tooltip>
      <Tooltip title={intl.formatMessage({ id: "label.copyId" })}>
        <CellButton onClick={copyToClipboardId}>
          <ContentCopyIcon />
        </CellButton>
      </Tooltip>
    </Row>
  );
}
