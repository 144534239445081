import React from "react";
import { Container, Content, PageTitle, Row } from "components/shared";
import { useIntl } from "react-intl";
import { PROFILE } from "gql/user";
import { useQuery } from "@apollo/client";
import { ProfileData } from "types/profile";
import LoadingIndicator from "components/LoadingIndicator";

function Overview() {
  const intl = useIntl();
  const { data, loading } = useQuery<ProfileData>(PROFILE);

  const profile = data?.profile;
  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <PageTitle>
            {intl.formatMessage({ id: "label.welcome" })} {profile?.firstName}
          </PageTitle>
        </Row>
      </Content>
    </Container>
  );
}
export default Overview;
