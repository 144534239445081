import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import InputRender, { formHasErrors } from "components/input/InputRender";
import { Button, ModalTitle, ModalDrawer, DrawerForm } from "components/shared";
import { useMutation } from "@apollo/client";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { FormDataType } from "components/input/types";
import { limitationsInputs, inputs, pricingInputs } from "./constants";
import { Box } from "@mui/material";
import {
  CreateSubscriptionPlanData,
  CreateSubscriptionPlanInput,
  Limitations,
  SubscriptionPlan,
} from "types/subscription";
import { Currencies, OrganizationType } from "types/shared";
import { CREATE_SUBSCRIPTION_PLAN, SUBSCRIPTION_PLANS } from "gql/subscription";
import LimitationsSection from "./LimitationsSection";
import PricingSection from "./PricingSection";

interface ContentProps {
  open: boolean;
  toggleDrawer: () => void;
  showSnackbar?: (message: string, severity: Severity) => void;
}

const initialState: FormDataType = {
  type: "",
  title: "",
  titleDE: "",
  description: "",
  descriptionDE: "",
  duration: "1",
  extraDuration: "0",
  active: null,
  defaultPlan: null,
  applicableTo: [],
};

const limitationsInitialState: FormDataType = {
  limitation: null,
  value: null,
};

const pricingInitialState: FormDataType = {
  countryCode: "DE",
  tax: "19", // vat tax in Germany
  value: "0",
  currency: Currencies.EUR,
  refundDeadline: "0",
};

function CreateDrawer({ open, toggleDrawer, showSnackbar }: ContentProps) {
  const intl = useIntl();
  const [showErrors, setShowErrors] = useState(false);
  const [data, setData] = useState<FormDataType>(initialState);
  const [limitationsState, setLimitationsState] = useState<FormDataType>(
    limitationsInitialState,
  );
  const [limitations, setLimitations] = useState<
    SubscriptionPlan["limitations"]
  >({});
  const [pricingState, setPricingState] =
    useState<FormDataType>(pricingInitialState);
  const [pricing, setPricing] = useState<SubscriptionPlan["pricing"]>([]);

  useEffect(() => {
    // when form data changes hide the errors
    showErrors && setShowErrors(false);
  }, [data]);

  const onComplete = () => {
    toggleDrawer();
    setData(initialState);
  };

  const [createSubscriptionPlan] = useMutation<
    CreateSubscriptionPlanData,
    CreateSubscriptionPlanInput
  >(CREATE_SUBSCRIPTION_PLAN, {
    onCompleted: onComplete,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      {
        query: SUBSCRIPTION_PLANS,
        variables: { payload: { includeAll: true } },
      },
    ],
  });
  const formInputs = inputs(intl);
  const limitationInputs = limitationsInputs(intl);
  const pricingInputFields = pricingInputs(intl);

  const createRecord = () => {
    const payload = {
      type: data.type as SubscriptionPlan["type"],
      title: data.title as string,
      titleDE: data.titleDE as string,
      description: (data.description as string) || undefined,
      descriptionDE: (data.descriptionDE as string) || undefined,
      duration: Number(data.duration),
      extraDuration: Number(data.extraDuration),
      active: (data.active as boolean) ?? false,
      defaultPlan: (data.defaultPlan as boolean) ?? false,
      applicableTo: data.applicableTo as OrganizationType[],
      limitations: limitations as SubscriptionPlan["limitations"],
      pricing: pricing,
    };
    createSubscriptionPlan({ variables: { payload } });
  };

  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formHasErrors(formInputs, data)) {
      setShowErrors(true);
      return;
    }
    createRecord();
  };

  const onAddLimitation = () => {
    if (formHasErrors(limitationInputs, limitationsState)) {
      setShowErrors(true);
      return;
    }
    setLimitations({
      ...limitations,
      [limitationsState.limitation as Limitations]: Number(
        limitationsState.value,
      ),
    });
  };

  const onAddPricing = () => {
    if (pricingState && formHasErrors(pricingInputFields, pricingState)) {
      setShowErrors(true);
      return;
    }
    setPricing([
      ...pricing.filter(
        (price) => price.countryCode !== pricingState.countryCode,
      ),
      {
        countryCode: pricingState.countryCode as string,
        value: Number(pricingState.value),
        currency: pricingState.currency as Currencies,
        tax: Number(pricingState.tax),
        refundDeadline: Number(pricingState.refundDeadline),
      },
    ]);
    setPricingState(pricingInitialState);
  };

  const onRemovePricing = (countryCode: string) => {
    setPricing(pricing.filter((price) => price.countryCode !== countryCode));
  };

  return (
    <ModalDrawer anchor="right" open={open} onClose={toggleDrawer}>
      <ModalTitle p={2}>
        {intl.formatMessage({ id: "label.createSubscriptionPlan" })}
      </ModalTitle>
      <DrawerForm noValidate onSubmit={handleOnsubmit}>
        {formInputs.map((input) => {
          return (
            <InputRender
              key={input.key}
              data={data}
              input={input}
              inputs={formInputs}
              setData={setData}
              showErrors={showErrors}
            />
          );
        })}

        <PricingSection
          showErrors={showErrors}
          pricingState={pricingState}
          setPricingState={setPricingState}
          onAddPricing={onAddPricing}
          pricing={pricing}
          onRemovePricing={onRemovePricing}
        />

        <LimitationsSection
          limitationInputs={limitationInputs}
          limitations={limitations}
          limitationsState={limitationsState}
          setLimitationsState={setLimitationsState}
          showErrors={showErrors}
          onAddLimitation={onAddLimitation}
        />

        <Box height={30} />
        <Button fullWidth type="submit" variant="contained" color="primary">
          {intl.formatMessage({ id: "label.create" })}
        </Button>
        <Box height={30} />
      </DrawerForm>
    </ModalDrawer>
  );
}

CreateDrawer.defaultProps = {
  showSnackbar: () => {},
};

export default withSnackbar(CreateDrawer);
