import { gql } from "@apollo/client";

export const SERVICE_TYPES = gql`
  query SERVICE_TYPES($includeAll: Boolean!) {
    serviceTypes(includeAll: $includeAll) {
      total
      list {
        id
        title
        description
        titleDE
        descriptionDE
        parentId
        published
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const CREATE_SERVICE_TYPE = gql`
  mutation CREATE_SERVICE_TYPE($payload: CreateServiceTypeInput!) {
    createServiceType(payload: $payload) {
      id
    }
  }
`;

export const DELETE_SERVICE_TYPE = gql`
  mutation DELETE_SERVICE_TYPE($id: ID!) {
    deleteServiceType(id: $id) {
      status
    }
  }
`;

export const UPDATE_SERVICE_TYPE = gql`
  mutation UPDATE_SERVICE_TYPE($payload: UpdateServiceTypeInput!) {
    updateServiceType(payload: $payload) {
      id
    }
  }
`;
