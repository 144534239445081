import React from "react";
import { Container, Content, PageTitle, Row } from "components/shared";
import { useIntl } from "react-intl";
import { USER_BY_ID } from "gql/user";
import { UserByIdData } from "types/profile";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import LoadingIndicator from "components/LoadingIndicator";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void;
}
function UserDetails({ showSnackbar }: Props) {
  const params = useParams() as { userId: string };
  const intl = useIntl();
  const { data, loading } = useQuery<UserByIdData>(USER_BY_ID, {
    variables: { userId: params.userId },
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
  });

  const user = data?.userById;
  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <PageTitle>{intl.formatMessage({ id: "label.users" })}</PageTitle>
        </Row>
        <p>
          {user?.firstName} {user?.lastName}
        </p>
        <p>{user?.email}</p>
      </Content>
    </Container>
  );
}
export default withSnackbar(UserDetails);
