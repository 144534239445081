import { isValidText } from "utils/validators";
import { Category } from "types/category";
import { GridColDef } from "@mui/x-data-grid";
import { ActionCell, DataGridText } from "components/shared";
import { format } from "date-fns";
import { InputType, Type } from "components/input/types";

const publishedOptions = [
  { label: "published", value: "published" },
  { label: "unpublished", value: "unpublished" },
];

export const inputs = (parentCategories: Category[]): InputType[] => [
  {
    key: "title",
    label: "label.title",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: true,
    fullWidth: true,
    margin: "none",
    validators: [
      {
        method: isValidText,
        label: "label.required",
      },
    ],
  },
  {
    key: "description",
    label: "label.description",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  {
    key: "titleDE",
    label: "label.titleDE",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    validators: [],
  },
  {
    key: "descriptionDE",
    label: "label.descriptionDE",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  {
    key: "parentId",
    label: "label.parent",
    type: Type.AUTOCOMPLETE,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    options: parentCategories.map((category) => ({
      label: category.title,
      value: category.id,
    })),
    validators: [],
  },
  {
    key: "published",
    label: "label.published",
    type: Type.SINGLE_SELECT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    options: publishedOptions,
    validators: [],
  },
];

// Group categories focused on the frontend
// First level categories must always be added to the frontend
export const groupCategories = (
  categories: Category[],
  focusedCategory: Category,
): Category[][] => {
  // const children's column
  const column = categories.filter(
    (category) => category.parentId === focusedCategory.id,
  );
  const parent = categories.find((cat) => cat.id === focusedCategory.parentId);

  if (!parent) return [column];
  return [...groupCategories(categories, parent), column];
};

export const categoryDown = (
  categoryGroups: Category[][],
  focusedCategory: Category,
) => {
  const column =
    categoryGroups.find((col) =>
      col.some((category) => category.id === focusedCategory.id),
    ) || [];
  const index =
    column.findIndex((category) => category.id === focusedCategory.id) || 0;
  const nextCategory =
    index >= column.length - 1 ? column[0] : column[index + 1];

  if (!nextCategory) return null;
  return nextCategory;
};

export const categoryUp = (
  categoryGroups: Category[][],
  focusedCategory: Category,
) => {
  const column =
    categoryGroups.find((col) =>
      col.some((category) => category.id === focusedCategory.id),
    ) || [];
  const index =
    column.findIndex((category) => category.id === focusedCategory.id) || 0;
  const nextCategory =
    index <= 0 ? column[column.length - 1] : column[index - 1];

  if (!nextCategory) return null;
  return nextCategory;
};

export const categoryLeft = (
  categoryGroups: Category[][],
  focusedCategory: Category,
) => {
  const index =
    categoryGroups.findIndex((col) =>
      col.some((category) => category.id === focusedCategory.id),
    ) || 0;
  const nextCategory =
    index === 0 ? focusedCategory : categoryGroups[index - 1][0];

  if (!nextCategory) return null;
  return nextCategory;
};

export const categoryRight = (
  categoryGroups: Category[][],
  focusedCategory: Category,
) => {
  const index =
    categoryGroups.findIndex((col) =>
      col.some((category) => category.id === focusedCategory.id),
    ) || 0;
  const nextCategory =
    index >= categoryGroups.length - 1
      ? focusedCategory
      : categoryGroups[index + 1][0];

  if (!nextCategory) return null;
  return nextCategory;
};

export const columns = (onEdit: (data: Category) => void): GridColDef[] => [
  {
    field: "actions",
    headerName: "Actions",
    width: 100,
    headerAlign: "center",
    renderCell: (row) => <ActionCell rowData={row} onEdit={onEdit} />,
  },
  {
    field: "id",
    headerName: "Id",
    width: 200,
    sortable: false,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
    hide: true,
  },
  {
    field: "title",
    headerName: "Title",
    width: 200,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
  },

  {
    field: "published",
    headerName: "Published",
    width: 150,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{row.value ? "Published" : "Unpublished"}</DataGridText>
    ),
  },
  {
    field: "deletedAt",
    headerName: "Deleted at",
    width: 120,
    renderCell: (row) => (
      <DataGridText style={row.value ? { color: "red" } : {}}>
        {row.value ? format(new Date(row.value), "dd/MM/yyyy") || "-" : "-"}
      </DataGridText>
    ),
  },
  {
    field: "updatedAt",
    headerName: "Updated at",
    width: 200,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), "dd/MM/yyyy HH:mm:ss") || "-"}
      </DataGridText>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 200,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), "dd/MM/yyyy HH:mm:ss") || "-"}
      </DataGridText>
    ),
  },
];
