import React, { useState } from "react";
import {
  ActionCell,
  Container,
  Content,
  PageTitle,
  Row,
} from "components/shared";
import { useIntl } from "react-intl";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridComp, DataGridText } from "components/shared";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/LoadingIndicator";
import { format } from "date-fns";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddSharp";
import CreateDrawer from "./CreateDrawer";
import EditDrawer from "./EditDrawer";
import { SERVICE_TYPES } from "gql/service";
import { ServiceType, ServiceTypeData } from "types/service";

const columns = (
  onEdit: (data: ServiceType) => void,
  serviceTypes: ServiceType[],
): GridColDef[] => [
  {
    field: "actions",
    headerName: "Actions",
    width: 100,
    headerAlign: "center",
    renderCell: (row) => <ActionCell rowData={row} onEdit={onEdit} />,
  },
  {
    field: "id",
    headerName: "Id",
    width: 200,
    sortable: false,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
    hide: true,
  },
  {
    field: "title",
    headerName: "Title",
    width: 200,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
  },

  {
    field: "parentId",
    headerName: "Parent",
    width: 200,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>
        {serviceTypes.find((i) => i.id === row.value)?.title}
      </DataGridText>
    ),
  },

  {
    field: "published",
    headerName: "Published",
    width: 200,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{row.value ? "Yes" : "No"}</DataGridText>
    ),
  },
  {
    field: "deletedAt",
    headerName: "Deleted at",
    width: 120,
    renderCell: (row) => (
      <DataGridText style={row.value ? { color: "red" } : {}}>
        {row.value ? format(new Date(row.value), "dd/MM/yyyy") || "-" : "-"}
      </DataGridText>
    ),
  },
  {
    field: "updatedAt",
    headerName: "Updated at",
    width: 200,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), "dd/MM/yyyy HH:mm:ss") || "-"}
      </DataGridText>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 200,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), "dd/MM/yyyy HH:mm:ss") || "-"}
      </DataGridText>
    ),
  },
];

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void;
}
function ServiceTypes({ showSnackbar }: Props) {
  // const [page, setPage] = useState(0);
  // const [pageSize, setPageSize] = useState(25);
  const [createOpen, setCreateOpen] = useState(false);
  const [editServiceType, setEditServiceType] = useState<ServiceType>(); // used also as a flag to show the drawer

  const intl = useIntl();
  const { data, loading } = useQuery<ServiceTypeData>(SERVICE_TYPES, {
    variables: { includeAll: true },
  });
  const serviceTypes = data?.serviceTypes.list || [];

  // const onPageChange = (_page: number) => setPage(_page);
  // const onPageSizeChange = (_pageSize: number) => setPageSize(_pageSize);
  const toggleCreateDrawer = () => setCreateOpen(!createOpen);
  const toggleEditDrawer = () => setEditServiceType(undefined);
  const handleOnEdit = (serviceType: ServiceType) =>
    setEditServiceType(serviceType);
  const tableColumns = columns(handleOnEdit, serviceTypes);

  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <PageTitle>
            {intl.formatMessage({ id: "label.serviceTypes" })} (
            {data?.serviceTypes.total ?? 0})
          </PageTitle>
          <Button onClick={toggleCreateDrawer}>
            <AddIcon />
          </Button>
        </Row>
        <div style={{ height: "80vh" }}>
          <DataGridComp
            // initialState={{ pagination: { page, pageSize } }}
            // onPageChange={onPageChange}
            // onPageSizeChange={onPageSizeChange}
            rows={serviceTypes}
            columns={tableColumns}
            // rowCount={data?.serviceTypes.total ?? 0}
            disableSelectionOnClick
            hideFooter
            rowsPerPageOptions={[data?.serviceTypes.total ?? 0]}
            // rowsPerPageOptions={[25, 50, 100]}
            // paginationMode="server"
          />
        </div>
      </Content>
      <CreateDrawer
        open={createOpen}
        toggleDrawer={toggleCreateDrawer}
        serviceTypes={serviceTypes}
      />
      {editServiceType && (
        <EditDrawer
          open={!!editServiceType}
          serviceType={editServiceType}
          toggleDrawer={toggleEditDrawer}
          serviceTypes={serviceTypes}
        />
      )}
    </Container>
  );
}
export default withSnackbar(ServiceTypes);
