import React from "react";
import { styled } from "@mui/material/styles";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getAuth } from "lib/localStorage";
import ROUTES from "lib/routes";
import { DASHBOARD_ROUTES } from "lib/navigation";
import AppDrawer from "./fragments/AppDrawer";
import { drawerWidth } from "./fragments/drawer";
import Toolbar from "@mui/material/Toolbar";

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const Content = styled("main")(({ theme }) => ({
  flexGrow: 1,
  // the following properties are aligned with the size of the sidebar
  marginLeft: theme.spacing(9),
  [theme.breakpoints.up("sm")]: {
    marginLeft: `${drawerWidth}px`,
  },
}));

function Dashboard() {
  const auth = getAuth();
  const navigate = useNavigate();

  // no session stored
  if (!auth?.userId) {
    return navigate(ROUTES.INDEX);
  }

  return (
    <Container>
      <AppDrawer />
      <Toolbar />

      <Content>
        <Routes>
          {DASHBOARD_ROUTES.map((item) => {
            const Element = item.component;
            return (
              <Route
                key={`route_${item.route}`}
                path={item.route}
                element={<Element />}
              />
            );
          })}
        </Routes>
      </Content>
    </Container>
  );
}

export default Dashboard;
