import { gql } from "@apollo/client";

export const WORKSPACES = gql`
  query WORKSPACES($payload: WorkspaceListInput!) {
    workspaceList(payload: $payload) {
      total
      list {
        id
        title
        itemCount
        nestCount
        organizationId
        archived
        members {
          firstName
          userId
        }
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

// workspace-type gql

export const WORKSPACE_TYPES = gql`
  query WORKSPACE_TYPES($includeAll: Boolean!) {
    workspaceTypesList(includeAll: $includeAll) {
      total
      list {
        id
        title
        description
        titleDE
        descriptionDE
        published
        priority
        featured
        applicable {
          organizationTypes
        }
        coverImage {
          full
          medium
          thumbnail
        }
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const CREATE_WORKSPACE_TYPE = gql`
  mutation CREATE_WORKSPACE_TYPE($payload: WorkspaceTypeInput!) {
    createWorkspaceType(payload: $payload) {
      id
    }
  }
`;

export const DELETE_WORKSPACE_TYPE = gql`
  mutation DELETE_WORKSPACE_TYPE($id: ID!) {
    deleteWorkspaceType(id: $id) {
      status
    }
  }
`;

export const UPDATE_WORKSPACE_TYPE = gql`
  mutation UPDATE_WORKSPACE_TYPE($payload: UpdateWorkspaceTypeInput!) {
    updateWorkspaceType(payload: $payload) {
      id
    }
  }
`;
