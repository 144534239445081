import React from "react";
import { Container, Content, PageTitle, Row } from "components/shared";
import { useIntl } from "react-intl";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridComp, DataGridText } from "components/shared";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/LoadingIndicator";
import { format } from "date-fns";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { WorkspaceListData } from "types/workspace";
import { WORKSPACES } from "gql/workspace";
import { WorkspaceMembersCell } from "./fragments";
import { DateFormat } from "utils/datetime";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "Id",
    width: 150,
    sortable: false,
    headerAlign: "center",
    hide: true,
  },
  {
    field: "title",
    headerName: "Title",
    width: 150,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
  },
  // {
  //   field: "organizationId",
  //   headerName: "Organization Id",
  //   width: 250,
  //   headerAlign: "center",
  // },
  {
    field: "nestCount",
    headerName: "Nest Count",
    width: 100,
    align: "center",
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
  },

  {
    field: "itemCount",
    headerName: "Item Count",
    align: "center",
    width: 100,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
  },

  {
    field: "members",
    headerName: "Members/Contacts",
    width: 250,
    headerAlign: "center",
    renderCell: (row) => <WorkspaceMembersCell rowData={row} />,
  },
  {
    field: "archived",
    headerName: "Archived",
    width: 80,
    align: "center",
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText style={row.value ? { color: "red" } : {}}>
        {row.value ? "Yes" : "No"}
      </DataGridText>
    ),
  },
  {
    field: "deletedAt",
    headerName: "Deleted at",
    headerAlign: "center",
    width: 100,
    align: "center",
    renderCell: (row) => (
      <DataGridText style={row.value ? { color: "red" } : {}}>
        {row.value ? format(new Date(row.value), DateFormat.date) || "-" : "-"}
      </DataGridText>
    ),
  },

  {
    field: "updatedAt",
    headerName: "Updated at",
    width: 160,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), DateFormat.datetime) || "-"}
      </DataGridText>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 160,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), DateFormat.datetime) || "-"}
      </DataGridText>
    ),
  },
];

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void;
}
function Workspaces({ showSnackbar }: Props) {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const intl = useIntl();
  const payload = { limit: pageSize, page };
  const { data, loading } = useQuery<WorkspaceListData>(WORKSPACES, {
    variables: { payload },
  });

  const workspaces = data?.workspaceList.list || [];
  const onPageChange = (_page: number) => setPage(_page);
  const onPageSizeChange = (_pageSize: number) => setPageSize(_pageSize);

  const tableColumns = columns;
  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <PageTitle>
            {intl.formatMessage({ id: "label.boards" })} (
            {data?.workspaceList.total})
          </PageTitle>
        </Row>
        <div style={{ height: "80vh" }}>
          <DataGridComp
            initialState={{ pagination: { page, pageSize } }}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={workspaces}
            columns={tableColumns}
            rowCount={data?.workspaceList.total || 0}
            disableSelectionOnClick
            rowsPerPageOptions={[25, 50, 100]}
            paginationMode="server"
          />
        </div>
      </Content>
    </Container>
  );
}
export default withSnackbar(Workspaces);
