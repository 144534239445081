import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate, Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { useIntl } from "react-intl";
import useMediaQuery from "@mui/material/useMediaQuery";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
// import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/NotificationsNoneOutlined";
// import CalendarIcon from "@mui/icons-material/EventNoteRounded";
// import ChatIcon from "@mui/icons-material/ChatBubbleOutline";
// import Badge from "@mui/material/Badge";
import ROUTES from "lib/routes";
import BrandLogo from "assets/images/headerLogo.png";
import Logo from "assets/images/logo.png";
import { clearSession } from "lib/localStorage";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { getMenuItems } from "./constants";
import {
  Drawer,
  DrawerHeader,
  ListSection,
  UL,
  LogoImg,
  AppBar,
  HeaderSeparator,
  // Search,
  // StyledInputBase,
  // SearchIconWrapper,
} from "./drawer";

function AppDrawer() {
  const navigate = useNavigate();
  const intl = useIntl();
  const items = getMenuItems(intl);
  const theme = useTheme();

  const handleSignOut = () => {
    clearSession();
    // TODO: add apollo cache reset
    navigate(ROUTES.INDEX, { replace: true });
  };

  // open only on large screen
  const fullSize = useMediaQuery(theme.breakpoints.up("sm"));
  const [selectedKey, setSelectedKey] = React.useState("");
  const onIconClick = () => {};
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar>
          <HeaderSeparator />
          {/* <Search>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
            <SearchIconWrapper onClick={onSearchClick}>
              <SearchIcon color="primary" />
            </SearchIconWrapper>
          </Search> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {/* <IconButton size="large" onClick={onIconClick}>
              <Badge badgeContent={4} color="error">
                <CalendarIcon />
              </Badge>
            </IconButton>
            <IconButton size="large" onClick={onIconClick}>
              <Badge badgeContent={17} color="error">
                <ChatIcon />
              </Badge>
            </IconButton> */}
            <IconButton size="large" edge="end" onClick={onIconClick}>
              <NotificationsIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        anchor="left"
        open={fullSize}
        color={theme.palette.background.default}
      >
        <DrawerHeader>
          <Button component={Link} to={ROUTES.DASHBOARD}>
            <LogoImg
              src={fullSize ? BrandLogo : Logo}
              alt={intl.formatMessage({ id: "alt.appLogo" })}
            />
          </Button>
        </DrawerHeader>

        <List subheader={<li />}>
          {items.map((item) => (
            <ListSection key={`section-${item.section}`}>
              <UL>
                {fullSize && (
                  <ListSubheader sx={{ background: "inherit" }}>
                    {item.section}
                  </ListSubheader>
                )}
                {item.entries.map((entry) => {
                  if (!entry) return null;
                  const { key, icon: Icon } = entry;

                  const handleOnClick = () => {
                    setSelectedKey(key);
                    if (entry.route === "logout") {
                      handleSignOut();
                    } else if (entry.route) {
                      navigate(entry.route);
                    }
                  };
                  const isSelected = selectedKey === key;

                  return (
                    <ListItem
                      button
                      selected={isSelected}
                      key={key}
                      onClick={handleOnClick}
                      sx={{
                        "&:hover": {
                          backgroundColor: `rgb(56,198,189,.25)!important`,
                        },
                      }}
                    >
                      <ListItemIcon>
                        {/* @ts-expect-error: add width for non MUI icons */}
                        <Icon width={25} />
                      </ListItemIcon>
                      <ListItemText primary={entry.title} />
                    </ListItem>
                  );
                })}
              </UL>
            </ListSection>
          ))}
        </List>
      </Drawer>
    </div>
  );
}

export default AppDrawer;
