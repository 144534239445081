import { styled, Theme } from "@mui/material/styles";
import { FontFamily } from "assets";

export const H1 = styled("h1")<{
  theme?: Theme;
  color?: string;
  fontFamily?: FontFamily;
  bold?: boolean;
  disabled?: boolean;
}>(({ theme, color, fontFamily, bold, disabled }) => ({
  fontSize: "32px",
  lineHeight: "41.6px",
  fontFamily: fontFamily || FontFamily.NoirPro,
  color: color || theme.palette.text.primary,
  fontWeight: 500,
  margin: 0,
  padding: 0,
  ...(bold && { fontWeight: "bold" }),
  ...(disabled ? { color: theme.palette.text.disabled } : {}),
  [theme.breakpoints.down("sm")]: {
    fontSize: "24px",
    lineHeight: "130%",
  },
}));

export const H2 = styled("h2")<{
  theme?: Theme;
  color?: string;
  fontFamily?: FontFamily;
  bold?: boolean;
  disabled?: boolean;
}>(({ theme, color, fontFamily, bold, disabled }) => ({
  fontSize: "24px",
  lineHeight: "130%",
  fontFamily: fontFamily || FontFamily.NoirPro,
  color: color || theme.palette.text.primary,
  fontWeight: 500,
  margin: 0,
  padding: 0,
  ...(bold && { fontWeight: "bold" }),
  ...(disabled ? { color: theme.palette.text.disabled } : {}),
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
}));

export const H3 = styled("h3")<{
  theme?: Theme;
  color?: string;
  fontFamily?: FontFamily;
  disabled?: boolean;
}>(({ theme, color, fontFamily, disabled }) => ({
  fontSize: "20px",
  lineHeight: "130%",
  fontFamily: fontFamily || FontFamily.NoirPro,
  color: color || theme.palette.text.primary,
  fontWeight: 500,
  margin: 0,
  padding: 0,
  ...(disabled ? { color: theme.palette.text.disabled } : {}),
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
}));

export const H4 = styled("h4")<{
  theme?: Theme;
  color?: string;
  fontFamily?: FontFamily;
  disabled?: boolean;
}>(({ theme, color, fontFamily, disabled }) => ({
  fontSize: "18px",
  lineHeight: "130%",
  fontFamily: fontFamily || FontFamily.NoirPro,
  color: color || theme.palette.text.primary,
  fontWeight: 500,
  margin: 0,
  padding: 0,
  ...(disabled ? { color: theme.palette.text.disabled } : {}),
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));

export const H5 = styled("h5")<{
  theme?: Theme;
  color?: string;
  fontFamily?: FontFamily;
  disabled?: boolean;
}>(({ theme, color, fontFamily, disabled }) => ({
  fontSize: "16px",
  lineHeight: "130%",
  fontFamily: fontFamily || FontFamily.NoirPro,
  color: color || theme.palette.text.primary,
  fontWeight: 500,
  margin: 0,
  padding: 0,
  ...(disabled ? { color: theme.palette.text.disabled } : {}),
}));

export const H6 = styled("h6")<{
  theme?: Theme;
  color?: string;
  fontFamily?: FontFamily;
  disabled?: boolean;
}>(({ theme, color, fontFamily, disabled }) => ({
  fontSize: "14px",
  lineHeight: "130%",
  fontFamily: fontFamily || FontFamily.NoirPro,
  color: color || theme.palette.text.primary,
  fontWeight: 500,
  margin: 0,
  padding: 0,
  ...(disabled ? { color: theme.palette.text.disabled } : {}),
}));

export const BodyText = styled("span")<{
  theme?: Theme;
  color?: string;
  fontFamily?: FontFamily;
  bold?: boolean;
  disabled?: boolean;
}>(({ theme, color, fontFamily, bold, disabled }) => ({
  fontSize: "14px",
  lineHeight: "145%",
  fontFamily: fontFamily || FontFamily.OpenSans,
  color: color || theme.palette.text.primary,
  fontWeight: 600,
  margin: 0,
  padding: 0,
  ...(bold && { fontWeight: "bold" }),
  ...(disabled ? { color: theme.palette.text.disabled } : {}),
}));

export const BodyTextLight = styled(BodyText)(({ theme }) => ({
  fontWeight: 400,
}));

export const CaptionText = styled("span")<{
  theme?: Theme;
  color?: string;
  fontFamily?: FontFamily;
  bold?: boolean;
  fontSize?: string;
  lineHeight?: string;
  disabled?: boolean;
}>(({ theme, color, fontFamily, bold, fontSize, lineHeight, disabled }) => ({
  fontSize: fontSize ?? "12px",
  lineHeight: lineHeight ?? "145%",
  fontFamily: fontFamily || FontFamily.OpenSans,
  color: color || theme.palette.text.primary,
  fontWeight: 400,
  margin: 0,
  padding: 0,
  ...(bold && { fontWeight: "bold" }),
  ...(disabled ? { color: theme.palette.text.disabled } : {}),
}));

// similar to BodyText, used for markdown
export const ParagraphText = styled("p")<{
  theme?: Theme;
  color?: string;
  fontFamily?: FontFamily;
  bold?: boolean;
  disabled?: boolean;
}>(({ theme, color, fontFamily, bold, disabled }) => ({
  fontSize: "14px",
  lineHeight: "145%",
  fontFamily: fontFamily || FontFamily.OpenSans,
  color: color || theme.palette.text.primary,
  fontWeight: 600,
  // margin: 0,
  // padding: 0,
  ...(bold && { fontWeight: "bold" }),
  ...(disabled ? { color: theme.palette.text.disabled } : {}),
}));
