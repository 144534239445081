import React from "react";
import {
  isPositiveNumber,
  isValidNumber,
  isValidOption,
  isValidText,
} from "utils/validators";
import { OrganizationType } from "types/shared";
import { InputType, Type } from "components/input/types";
import {
  Limitations,
  SubscriptionPlan,
  SubscriptionPlanType,
} from "types/subscription";
import { IntlShape } from "react-intl";
import { currencies } from "lib/constants";
import { format } from "date-fns";
import { GridColDef } from "@mui/x-data-grid";
import { ActionCell, DataGridText, Row } from "components/shared";
import { DateFormat } from "utils/datetime";
import { getMonetaryValue } from "utils/monetary";
import { Chip } from "@mui/material";
import countries from "assets/static/countries.json";

const orgTypes = [
  OrganizationType.personal,
  OrganizationType.family,
  OrganizationType.business,
];

export const inputs = (intl: IntlShape): InputType[] => [
  {
    key: "type",
    label: "label.subscriptionType",
    type: Type.SINGLE_SELECT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    options: Object.values(SubscriptionPlanType).map((key) => ({
      value: key,
      label: intl.formatMessage({ id: `subscription.type.${key}` }),
    })),
    validators: [{ method: isValidOption, label: "label.required" }],
  },
  {
    key: "title",
    label: "label.title",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: true,
    fullWidth: true,
    margin: "none",
    validators: [
      {
        method: isValidText,
        label: "label.required",
      },
    ],
  },
  {
    key: "titleDE",
    label: "label.titleDE",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    validators: [
      {
        method: isValidText,
        label: "label.required",
      },
    ],
  },
  {
    key: "description",
    label: "label.description",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    multiline: true,
    validators: [
      {
        method: isValidText,
        label: "label.required",
      },
    ],
  },
  {
    key: "descriptionDE",
    label: "label.descriptionDE",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    multiline: true,
    validators: [
      {
        method: isValidText,
        label: "label.required",
      },
    ],
  },
  {
    key: "duration",
    label: "label.duration",
    type: Type.NUMBER,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    validators: [
      {
        method: isPositiveNumber,
        label: "label.positiveNumber",
      },
    ],
    infoLabel: "label.subscriptionDurationInMonths",
  },
  {
    key: "extraDuration",
    label: "label.extraDuration",
    type: Type.NUMBER,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    validators: [
      {
        method: isPositiveNumber,
        label: "label.positiveNumber",
      },
    ],
    infoLabel: "label.subscriptionExtraDurationInMonths",
  },
  {
    key: "active",
    label: "label.active",
    type: Type.CHECKBOX,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    validators: [],
    infoLabel: "label.subscriptionActiveInfo",
  },
  {
    key: "defaultPlan",
    label: "label.defaultSubscription",
    type: Type.CHECKBOX,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    validators: [],
    infoLabel: "label.subscriptionDefaultInfo",
  },
  {
    key: "applicableTo",
    label: "label.applicableOrganizationTypes",
    infoLabel: "label.applicableOrganizationTypesInfo",
    type: Type.MULTI_SELECT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    useTranslation: true,
    fullWidth: true,
    options:
      orgTypes.map((input) => ({
        value: input,
        label: input,
      })) || [],
    validators: [],
  },
];

export const limitationsInputs = (intl: IntlShape): InputType[] => [
  {
    key: "limitation",
    label: "label.property",
    type: Type.AUTOCOMPLETE,
    autoFocus: true,
    fullWidth: true,
    margin: "none",
    options: Object.keys(Limitations).map((k) => ({
      value: k,
      label: intl.formatMessage({
        id: `subscription.limitation.${k}`,
        defaultMessage: k,
      }),
    })),
    validators: [{ method: isValidOption, label: "label.required" }],
  },
  {
    key: "value",
    label: "label.value",
    type: Type.NUMBER,
    autoFocus: false,
    fullWidth: true,
    margin: "none",
    validators: [
      { method: isValidNumber, label: "label.required" },
      { method: isPositiveNumber, label: "label.positiveNumber" },
    ],
  },
];

type Keys = keyof typeof countries;
const countryCodes: Keys[] = ["DE", "AT", "CH", "NL", "BE", "FR"];

export const pricingInputs = (intl: IntlShape): InputType[] => [
  {
    key: "value",
    unitKey: "currency",
    label: "label.price",
    type: Type.PRICE,
    fullWidth: true,
    margin: "none",
    options: currencies,
    unitValidators: [{ method: isValidOption, label: "label.required" }],
    validators: [{ method: isPositiveNumber, label: "label.positiveNumber" }],
  },
  {
    key: "tax",
    label: "label.taxVat",
    type: Type.NUMBER,
    autoFocus: false,
    fullWidth: true,
    infoLabel: "label.taxVatInfo",
    margin: "none",
    validators: [
      { method: isValidNumber, label: "label.required" },
      { method: isPositiveNumber, label: "label.positiveNumber" },
    ],
  },

  {
    key: "refundDeadline",
    label: "label.refundDeadline",
    type: Type.NUMBER,
    autoFocus: false,
    fullWidth: true,
    infoLabel: "label.refundDeadlineInfo",
    margin: "none",
    validators: [
      { method: isValidNumber, label: "label.required" },
      { method: isPositiveNumber, label: "label.positiveNumber" },
    ],
  },
  {
    key: "countryCode",
    label: "label.country",
    type: Type.COUNTRY,
    autoFocus: false,
    margin: "none",
    fullWidth: true,
    options: countryCodes.map((code) => ({
      value: code,
      label: `${countries?.[code as unknown as Keys]}${
        code !== "DE"
          ? ` - ${intl.formatMessage({ id: "label.comingSoon" })}`
          : ""
      }`,
      disabled: code !== "DE", // all but germany disabled for now
    })),
    validators: [
      {
        method: isValidText,
        label: "label.required",
      },
    ],
  },
];

// Data grid table columns
export const columns = (
  onEdit: (record: SubscriptionPlan) => void,
  intl: IntlShape,
): GridColDef[] => [
  {
    field: "actions",
    headerName: "Actions",
    width: 100,
    headerAlign: "center",
    renderCell: (row) => <ActionCell rowData={row} onEdit={onEdit} />,
  },
  {
    field: "id",
    headerName: "Id",
    width: 150,
    sortable: false,
    headerAlign: "center",
    hide: true,
  },
  {
    field: "type",
    headerName: intl.formatMessage({ id: "label.type" }),
    width: 80,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>
        {intl.formatMessage({ id: `subscription.type.${row.value}` })}
      </DataGridText>
    ),
  },
  {
    field: "title",
    headerName: "Title",
    width: 150,
    headerAlign: "center",
    renderCell: ({ row }) => (
      <DataGridText>
        {row.title}/{row.titleDE}
      </DataGridText>
    ),
  },
  {
    field: "price",
    headerName: "Pricing",
    width: 180,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }: { row: SubscriptionPlan }) => (
      <Row>
        {row.pricing.map((price, index) => (
          <DataGridText key={price.countryCode}>{`•${
            price.countryCode
          }: ${getMonetaryValue(price)} `}</DataGridText>
        ))}
      </Row>
    ),
  },
  {
    field: "duration",
    headerName: "Duration (Months)",
    width: 150,
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => (
      <DataGridText>
        {row.duration} (+{row.extraDuration})
      </DataGridText>
    ),
  },
  {
    field: "defaultPlan",
    headerName: "Default plan",
    width: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <DataGridText>{row.value ? "Yes" : "No"}</DataGridText>
    ),
  },
  {
    field: "active",
    headerName: "Active",
    width: 150,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <DataGridText style={row.value ? {} : { color: "red" }}>
        {row.value ? "Active" : "Disabled"}
      </DataGridText>
    ),
  },
  {
    field: "organizationTypes",
    headerName: "Organization Types",
    width: 280,
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => (
      <Row>
        {(row.applicableTo ?? [])?.length === 0 ? (
          <DataGridText>All</DataGridText>
        ) : (
          row.applicableTo?.map((item: string) => (
            <Chip key={item} color="primary" label={item} />
          ))
        )}
      </Row>
    ),
  },
  {
    field: "deletedAt",
    headerName: "Deleted at",
    headerAlign: "center",
    width: 120,
    align: "center",
    renderCell: (row) => (
      <DataGridText style={row.value ? { color: "red" } : {}}>
        {row.value ? format(new Date(row.value), DateFormat.date) || "-" : "-"}
      </DataGridText>
    ),
  },

  {
    field: "updatedAt",
    headerName: "Updated at",
    width: 200,
    align: "center",
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), DateFormat.datetime) || "-"}
      </DataGridText>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 200,
    headerAlign: "center",
    align: "center",
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), DateFormat.datetime) || "-"}
      </DataGridText>
    ),
  },
];
