import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useIntl } from "react-intl";
import Logo from "assets/images/headerLogo.png";
import LogoIcon from "assets/images/logo.png";
import ROUTES from "lib/routes";
import { linkStyle } from "assets/styles/links";

const ToolbarComponent = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const LogoImg = styled("img")(({ theme }) => ({
  height: "40px",
  objectFit: "contain",
  marginTop: "8px",
}));

const Spacer = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1",
  flexDirection: "row",
}));

function AppHeader() {
  const intl = useIntl();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <AppBar color="default" elevation={0}>
      <ToolbarComponent>
        <Button component={Link} to={ROUTES.INDEX}>
          <LogoImg
            src={isBigScreen ? Logo : LogoIcon}
            alt={intl.formatMessage({ id: "alt.appLogo" })}
          />
        </Button>
        <Spacer />
        <>
          {/* <Button component={Link} style={linkStyle} to={ROUTES.REGISTER}>
            {intl.formatMessage({ id: "label.register" })}
          </Button> */}
          <Button component={Link} style={linkStyle} to={ROUTES.LOGIN}>
            {intl.formatMessage({ id: "label.login" })}
          </Button>
        </>
      </ToolbarComponent>
    </AppBar>
  );
}

export default AppHeader;
