import React from "react";
import { styled } from "@mui/material/styles";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { DataGridText, Row } from "components/shared";
import Chip from "@mui/material/Chip";
import appConfig from "config/app";

const TitleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const CoverImage = styled("img")(({ theme }) => ({
  height: "24px",
  width: "32px",
  borderRadius: "5px",
  objectFit: "cover",
  marginRight: "8px",
}));

interface CellProps {
  rowData: GridRenderCellParams;
}
export function TitleCell({ rowData }: CellProps) {
  const { row } = rowData;
  const cover = row.coverImage;
  const coverImage = cover.thumbnail;
  return (
    <TitleWrapper>
      {coverImage && <CoverImage src={`${appConfig.media}${coverImage}`} />}
      <DataGridText>{rowData.value}</DataGridText>
    </TitleWrapper>
  );
}

export function OrgTypesCell({ rowData }: { rowData: GridRenderCellParams }) {
  return (
    <Row>
      {rowData.row.applicable?.organizationTypes?.map((item: string) => (
        <Chip key={item} color="primary" label={item} />
      ))}
    </Row>
  );
}
