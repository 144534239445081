import React, { useState } from "react";
import {
  ActionCell,
  Container,
  Content,
  PageTitle,
  Row,
} from "components/shared";
import { useIntl } from "react-intl";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridComp, DataGridText } from "components/shared";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/LoadingIndicator";
import { format } from "date-fns";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddSharp";
import { GROUP_TYPES } from "gql/group";
import { GroupType, GroupTypeData } from "types/group";
import CreateDrawer from "./CreateDrawer";
import { OrgTypesCell, TitleCell } from "./fragments";
import EditDrawer from "./EditDrawer";

const columns = (onEdit: (data: GroupType) => void): GridColDef[] => [
  {
    field: "actions",
    headerName: "Actions",
    width: 100,
    headerAlign: "center",
    renderCell: (row) => <ActionCell rowData={row} onEdit={onEdit} />,
  },
  {
    field: "id",
    headerName: "Id",
    width: 200,
    sortable: false,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
    hide: true,
  },
  {
    field: "title",
    headerName: "Title",
    width: 200,
    headerAlign: "center",
    renderCell: (row) => <TitleCell rowData={row} />,
  },

  {
    field: "published",
    headerName: "Published",
    width: 200,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{row.value ? "Yes" : "No"}</DataGridText>
    ),
  },

  {
    field: "featured",
    headerName: "Featured",
    width: 200,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{row.value ? "Yes" : "No"}</DataGridText>
    ),
  },
  {
    field: "priority",
    headerName: "Priority",
    width: 200,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
  },
  {
    field: "deletedAt",
    headerName: "Deleted at",
    width: 120,
    renderCell: (row) => (
      <DataGridText style={row.value ? { color: "red" } : {}}>
        {row.value ? format(new Date(row.value), "dd/MM/yyyy") || "-" : "-"}
      </DataGridText>
    ),
  },
  {
    field: "organizationTypes",
    headerName: "Organization Types",
    width: 280,
    headerAlign: "center",
    renderCell: (row) => <OrgTypesCell rowData={row} />,
  },
  {
    field: "updatedAt",
    headerName: "Updated at",
    width: 200,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), "dd/MM/yyyy HH:mm:ss") || "-"}
      </DataGridText>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 200,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), "dd/MM/yyyy HH:mm:ss") || "-"}
      </DataGridText>
    ),
  },
];

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void;
}
function GroupTypes({ showSnackbar }: Props) {
  // const [page, setPage] = useState(0);
  // const [pageSize, setPageSize] = useState(25);
  const [createOpen, setCreateOpen] = useState(false);
  const [editRecord, setEditRecord] = useState<GroupType>(); // used also as a flag to show the drawer

  const intl = useIntl();
  const { data, loading } = useQuery<GroupTypeData>(GROUP_TYPES, {
    variables: { includeAll: true },
  });
  const records = data?.groupTypesList.list || [];

  // const onPageChange = (_page: number) => setPage(_page);
  // const onPageSizeChange = (_pageSize: number) => setPageSize(_pageSize);
  const toggleCreateDrawer = () => setCreateOpen(!createOpen);
  const toggleEditDrawer = () => setEditRecord(undefined);
  const handleOnEdit = (record: GroupType) => setEditRecord(record);
  const tableColumns = columns(handleOnEdit);

  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <PageTitle>
            {intl.formatMessage({ id: "label.groupTypes" })} (
            {data?.groupTypesList.total ?? 0})
          </PageTitle>
          <Button onClick={toggleCreateDrawer}>
            <AddIcon />
          </Button>
        </Row>
        <div style={{ height: "80vh" }}>
          <DataGridComp
            rows={records}
            columns={tableColumns}
            disableSelectionOnClick
            hideFooter
            rowsPerPageOptions={[data?.groupTypesList.total ?? 0]}
          />
        </div>
      </Content>
      <CreateDrawer open={createOpen} toggleDrawer={toggleCreateDrawer} />

      {editRecord && (
        <EditDrawer
          open={!!editRecord}
          record={editRecord}
          toggleDrawer={toggleEditDrawer}
        />
      )}
    </Container>
  );
}
export default withSnackbar(GroupTypes);
