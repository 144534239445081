import React, { useState } from "react";
import { useIntl } from "react-intl";
import InputRender, { formHasErrors } from "components/input/InputRender";
import { Button, ModalTitle, ModalDrawer, DrawerForm } from "components/shared";
import { useMutation } from "@apollo/client";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { inputs } from "./constants";
import {
  DeleteProviderCategoryData,
  DeleteProviderCategoryInput,
  ProviderCategory,
  UpdateProviderCategoryData,
  UpdateProviderCategoryInput,
} from "types/provider-category";
import {
  DELETE_PROVIDER_CATEGORY,
  PROVIDER_CATEGORIES,
  UPDATE_PROVIDER_CATEGORY,
} from "gql/provider-category";
import { FormDataType } from "components/input/types";

interface ContentProps {
  open: boolean;
  toggleDrawer: () => void;
  record: ProviderCategory;
  showSnackbar?: (message: string, severity: Severity) => void;
}

function EditDrawer({
  open,
  toggleDrawer,
  record,
  showSnackbar,
}: ContentProps) {
  const intl = useIntl();
  const [showErrors, setShowErrors] = useState(false);
  const [data, setData] = useState<FormDataType>({
    title: record.title,
    description: record.description || "",
    titleDE: record.titleDE || "",
    descriptionDE: record.descriptionDE || "",
    published: record.published ? "published" : "unpublished",
  });

  const onComplete = () => toggleDrawer();
  const [updateCategory] = useMutation<
    UpdateProviderCategoryData,
    UpdateProviderCategoryInput
  >(UPDATE_PROVIDER_CATEGORY, {
    onCompleted: onComplete,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      { query: PROVIDER_CATEGORIES, variables: { includeAll: true } },
    ],
  });

  const [deleteCategory] = useMutation<
    DeleteProviderCategoryData,
    DeleteProviderCategoryInput
  >(DELETE_PROVIDER_CATEGORY, {
    onCompleted: onComplete,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      { query: PROVIDER_CATEGORIES, variables: { includeAll: true } },
    ],
  });

  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formHasErrors(inputs, data)) {
      setShowErrors(true);
      return;
    }
    const payload = {
      id: record.id,
      data: {
        title: data.title as string,
        description: data.description as string,
        titleDE: data.titleDE as string,
        descriptionDE: data.descriptionDE as string,
        published: data.published === "published",
      },
    };
    updateCategory({ variables: { payload } });
  };

  const handleDelete = () =>
    deleteCategory({ variables: { categoryId: record.id } });

  return (
    <ModalDrawer anchor="right" open={open} onClose={toggleDrawer}>
      <ModalTitle p={2}>
        {intl.formatMessage({ id: "label.nestType" })}
      </ModalTitle>
      <DrawerForm noValidate onSubmit={handleOnsubmit}>
        {inputs.map((input) => {
          return (
            <InputRender
              key={input.key}
              data={data}
              input={input}
              inputs={inputs}
              setData={setData}
              showErrors={showErrors}
            />
          );
        })}
        <Button type="submit" fullWidth variant="contained" color="primary">
          {intl.formatMessage({ id: "label.update" })}
        </Button>
        {!record.deletedAt && (
          <Button
            fullWidth
            onClick={handleDelete}
            variant="contained"
            color="error"
            style={{ marginTop: "10px" }}
          >
            {intl.formatMessage({ id: "label.delete" })}
          </Button>
        )}
      </DrawerForm>
    </ModalDrawer>
  );
}

EditDrawer.defaultProps = {
  showSnackbar: () => {},
};

export default withSnackbar(EditDrawer);
