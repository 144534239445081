/**
 * Generic json object type.
 * Note! Does not work with nested array Array<Array>
 */
export interface JsonObject {
  [key: string]:
    | string
    | number
    | bigint
    | Array<string | number | bigint | boolean | JsonObject | null | undefined>
    | boolean
    | JsonObject
    | null
    | undefined;
}

export interface Session {
  userId: string;
  token: string;
  expiresAt: string;
}

export interface Status {
  status: "success" | "failed";
}

export enum AccessRoles {
  owner = "OWNER",
  manager = "MANAGER",
  member = "MEMBER",
}

export enum ConfirmationStatus {
  pending = "PENDING",
  confirmed = "CONFIRMED",
  rejected = "REJECTED",
}

export enum OrganizationType {
  personal = "PERSONAL", // for individual users
  family = "FAMILY", // for families
  business = "BUSINESS", // for companies
}

export enum Currencies {
  EUR = "EUR",
  USD = "USD",
  GBP = "GBP", // British Pound
  CZK = "CZK", // Czech Koruna
}

export interface FileEntry {
  file: File;
  // fileType: string
  title?: string;
  documentType?: DocumentType;
}
