import React from "react";
import { Container, Content, PageTitle, Row } from "components/shared";
import { IntlShape, useIntl } from "react-intl";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridComp, DataGridText } from "components/shared";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/LoadingIndicator";
import { format } from "date-fns";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { DateFormat } from "utils/datetime";
import { ORGANIZATIONS } from "gql/organization";
import { OrganizationsData, OrganizationsInput } from "types/organization";
import { RefNumberCell } from "./fragments";
import countries from "assets/static/countries.json";
import { get } from "lodash";
import { SubscriptionPlan, SubscriptionPlanData } from "types/subscription";
import { SUBSCRIPTION_PLANS } from "gql/subscription";

const columns = (
  intl: IntlShape,
  subscriptionPlans: SubscriptionPlan[],
): GridColDef[] => [
  {
    field: "id",
    headerName: "#",
    width: 0,
    sortable: false,
    headerAlign: "center",
    hide: true,
  },
  {
    field: "referenceNumber",
    headerName: "Reference number",
    width: 200,
    headerAlign: "center",
    renderCell: ({ row }) => <RefNumberCell record={row} />,
  },
  {
    field: "type",
    headerName: "Type",
    align: "center",
    width: 100,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>
        {intl.formatMessage({
          id: `input.applicableTo.${row.value}`,
          defaultMessage: row.value,
        })}
      </DataGridText>
    ),
  },
  {
    field: "country",
    headerName: "Country of T&C",
    align: "center",
    width: 110,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{get(countries, row.value)}</DataGridText>
    ),
  },

  {
    field: "deletedAt",
    headerName: "Deleted at",
    headerAlign: "center",
    width: 100,
    align: "center",
    renderCell: (row) => (
      <DataGridText style={row.value ? { color: "red" } : {}}>
        {row.value ? format(new Date(row.value), DateFormat.date) || "-" : "-"}
      </DataGridText>
    ),
  },
  {
    field: "updatedAt",
    headerName: "Updated at",
    width: 160,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), DateFormat.datetime) || "-"}
      </DataGridText>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 160,

    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), DateFormat.datetime) || "-"}
      </DataGridText>
    ),
  },
];

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void;
}
function Users({ showSnackbar }: Props) {
  const intl = useIntl();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const payload = { limit: pageSize, page };
  const { data, loading } = useQuery<OrganizationsData, OrganizationsInput>(
    ORGANIZATIONS,
    {
      variables: { payload },
    },
  );

  const { data: subscriptionData, loading: subsLoading } =
    useQuery<SubscriptionPlanData>(SUBSCRIPTION_PLANS, {
      variables: { includeAll: true },
    });

  const records = data?.organizations.list || [];
  const onPageChange = (_page: number) => setPage(_page);
  const onPageSizeChange = (_pageSize: number) => setPageSize(_pageSize);

  const subscriptions = subscriptionData?.subscriptionPlans.list ?? [];
  const tableColumns = columns(intl, subscriptions);
  const isLoading = loading || subsLoading;
  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <Content>
        <Row>
          <PageTitle>
            {intl.formatMessage({ id: "label.organizations" })} (
            {data?.organizations.total})
          </PageTitle>
        </Row>
        <div style={{ height: "80vh" }}>
          <DataGridComp
            initialState={{ pagination: { page, pageSize } }}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={records}
            columns={tableColumns}
            rowCount={data?.organizations.total || 0}
            disableSelectionOnClick
            rowsPerPageOptions={[25, 50, 100]}
            paginationMode="server"
          />
        </div>
      </Content>
    </Container>
  );
}
export default withSnackbar(Users);
