import { createTheme } from "@mui/material/styles";
export const cardBoxShadow = "0px 0px 5px 0px #00000026";
export const cardBoxShadowHover = "0px 0px 15px 0px #00000026";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#83C9C7",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#F9F9F9",
      contrastText: "#707070",
    },
    background: {
      default: "#F9F9F9",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#0B3954",
      secondary: "#757F89",
      disabled: "#CDD2D8",
    },
    shared: {
      bgGray: "#F7F7F7",
      lightGray: "#F6F6F6",
      darkGray: "#EBEBEB",
      red: "#FF6663",
      lightRed: "#FFE8E8",
      lightGreen: "#EAFFFC",
      yellow: "#E0FF4F",
      lightBlue: "#DCF2FF",
    },
  },
  typography: {
    button: {
      textTransform: "none",
      fontFamily: "NoirPro",
    },
  },
});

interface SharedColors {
  bgGray: string;
  lightGray: string;
  darkGray: string;
  red: string;
  lightRed: string;
  lightGreen: string;
  lightBlue: string;
  yellow: string;
}
declare module "@mui/material/styles" {
  interface Palette {
    shared: SharedColors;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    shared?: SharedColors;
  }
}

export default theme;
