import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import InputRender, { formHasErrors } from "components/input/InputRender";
import { Button, ModalTitle, ModalDrawer, DrawerForm } from "components/shared";
import { useMutation } from "@apollo/client";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { CREATE_GROUP_TYPE, GROUP_TYPES } from "gql/group";
import { CreateGroupTypeData, CreateGroupTypeInput } from "types/group";
import { FileType } from "types/archive";
import { uploadMedia } from "lib/api";
import { inputs } from "./constants";
import { FormDataType } from "components/input/types";
import { FileEntry } from "types/shared";

interface ContentProps {
  open: boolean;
  toggleDrawer: () => void;
  showSnackbar?: (message: string, severity: Severity) => void;
}

const initialState: FormDataType = {
  title: "",
  description: "",
  titleDE: "",
  descriptionDE: "",
  published: "published", // default published
  priority: null,
  featured: false,
  organizationTypes: [],
};
function CreateDrawer({ open, toggleDrawer, showSnackbar }: ContentProps) {
  const intl = useIntl();
  const [showErrors, setShowErrors] = useState(false);
  const [files, setFiles] = useState<FileEntry[]>();
  const [data, setData] = useState<FormDataType>(initialState);

  // dropzone adds multiple files, so we only use the last one
  useEffect(() => {
    if (files && files?.length > 1) {
      setFiles([files[files.length - 1]]);
    }
  }, [files]);

  const onComplete = () => {
    toggleDrawer();
    setData(initialState);
  };

  const [createGroupType] = useMutation<
    CreateGroupTypeData,
    CreateGroupTypeInput
  >(CREATE_GROUP_TYPE, {
    onCompleted: onComplete,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: GROUP_TYPES, variables: { includeAll: true } }],
  });
  const formInputs = inputs();

  const createRecord = (archiveId?: string) => {
    const payload = {
      title: data.title as string,
      description: (data.description as string) || undefined,
      titleDE: (data.titleDE as string) || undefined,
      descriptionDE: (data.descriptionDE as string) || undefined,
      published: data.published === "published",
      priority: data.priority ? Number(data.priority) : null,
      coverImageId: archiveId,
      featured: data.featured,
      applicable: {
        organizationTypes: data.organizationTypes || null,
      },
    };
    createGroupType({ variables: { payload } });
  };

  const uploadCoverImage = async (cover: FileEntry): Promise<string | null> => {
    const result = await uploadMedia(cover.file, { type: FileType.image }, true)
      .then((response) => response.json())
      .catch((error) => error);

    // eslint-disable-next-line no-underscore-dangle
    const archiveId = result?.data?._id;
    if (archiveId) {
      return archiveId;
    } else {
      showSnackbar?.(
        intl.formatMessage({ id: "error.coverUploadFailed" }),
        Severity.ERROR,
      );
      return null;
    }
  };

  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formHasErrors(formInputs, data)) {
      setShowErrors(true);
      return;
    }
    const cover = files?.[0];

    if (cover) {
      const archiveId = await uploadCoverImage(cover);
      if (archiveId) createRecord(archiveId);
    } else {
      createRecord();
    }
  };

  return (
    <ModalDrawer anchor="right" open={open} onClose={toggleDrawer}>
      <ModalTitle p={2}>
        {intl.formatMessage({ id: "label.createGroupType" })}
      </ModalTitle>
      <DrawerForm noValidate onSubmit={handleOnsubmit}>
        {formInputs.map((input) => {
          return (
            <InputRender
              key={input.key}
              data={data}
              input={input}
              inputs={formInputs}
              setData={setData}
              showErrors={showErrors}
              files={files}
              setFiles={setFiles}
            />
          );
        })}
        <Button type="submit" fullWidth variant="contained" color="primary">
          {intl.formatMessage({ id: "label.create" })}
        </Button>
      </DrawerForm>
    </ModalDrawer>
  );
}

CreateDrawer.defaultProps = {
  showSnackbar: () => {},
};

export default withSnackbar(CreateDrawer);
