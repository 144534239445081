import React from "react";
import { Link } from "react-router-dom";
import ROUTES from "lib/routes";
import { BodyTextLight } from "components/typography";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { Organization } from "types/organization";

const LinkWrapper = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  textDecorationLine: "none",
  ":hover svg": {
    display: "flex",
  },
}));

export function RefNumberCell({ record }: { record: Organization }) {
  const theme = useTheme();
  return (
    <LinkWrapper to={ROUTES.DASHBOARD_ORGANIZATIONS_ROUTE(record.id)}>
      <BodyTextLight color={theme.palette.info.dark}>
        {record.referenceNumber ?? "-"}
      </BodyTextLight>
    </LinkWrapper>
  );
}
