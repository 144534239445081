import React from "react";
import { useTheme } from "@mui/material/styles";

interface Props {
  color?: string;
}
function Svg({ color }: Props) {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 6.25H3.75"
        stroke={color || theme.palette.shared.red}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 10.75V16.75"
        stroke={color || theme.palette.shared.red}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 10.75V16.75"
        stroke={color || theme.palette.shared.red}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 6.25V20.5C18.75 20.6989 18.671 20.8897 18.5303 21.0303C18.3897 21.171 18.1989 21.25 18 21.25H6C5.80109 21.25 5.61032 21.171 5.46967 21.0303C5.32902 20.8897 5.25 20.6989 5.25 20.5V6.25"
        stroke={color || theme.palette.shared.red}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 6.25V4.75C15.75 4.35218 15.592 3.97064 15.3107 3.68934C15.0294 3.40804 14.6478 3.25 14.25 3.25H9.75C9.35218 3.25 8.97064 3.40804 8.68934 3.68934C8.40804 3.97064 8.25 4.35218 8.25 4.75V6.25"
        stroke={color || theme.palette.shared.red}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Svg.defaultProps = {
  color: null,
};

export default Svg;
