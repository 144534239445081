import { Status } from "./shared";

export interface UserRequestsListData {
  userRequestsList: {
    list: UserRequestExtended[];
    total: number;
  };
}

export interface UserRequestsListInput {
  payload: { limit: number; page: number };
}

export interface UserRequest {
  id: string;
  userId: string;
  referenceNumber: string;
  type: UserRequestType;
  status: UserRequestStatus;
  message: string;
  // related reference id of the request,
  // in case the user wants to link this with an organization or workspace.
  // The user can make direct claims without linking this to any entity.
  referenceId?: string;
  referenceModel?: string;

  // replies thread for feedback to a users claims.
  // only platform admin/members can leave a reply or the user who created the claim.
  replies: UserRequestReply[];
}

interface UserRequestReply {
  id: string;
  userId: string;
  date: Date;
  message: string;
}

interface UserRequestReplyExtended extends UserRequestReply {
  user: UserRequestUser;
}

interface UserRequestUser {
  id: string;
  firstName: string;
  lastName?: string;
}

export interface UserRequestExtended extends UserRequest {
  user: UserRequestUser;
  replies: UserRequestReplyExtended[];
}

export enum UserRequestType {
  // refund claims, subscription cancelation claim, other issues.
  CLAIM = "CLAIM",
  // general user feedback
  FEEDBACK = "FEEDBACK",
  // questions from the user
  INQUIRY = "INQUIRY",
}

export enum UserRequestStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  REJECTED = "REJECTED",
  IN_PROGRESS = "IN_PROGRESS",
}
export enum UserRequestReferenceModel {
  ORGANIZATION = "organization",
  WORKSPACE = "workspace",
}

export interface ReplyToUserRequestData {
  replyToUserRequest: Status;
}

export interface ReplyToUserRequestInput {
  payload: {
    id: string;
    message: string;
  };
}
