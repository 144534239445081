import React from "react";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/material/styles";
import { Grid, IconButton } from "@mui/material";
import { BodyTextLight, H4 } from "components/typography";
import { get } from "lodash";
import InputRender from "components/input/InputRender";
import { FormDataType, InputType } from "components/input/types";
import { SubscriptionPlan } from "types/subscription";
import CheckMarkIcon from "@mui/icons-material/Check";

interface Props {
  limitationInputs: InputType[];
  limitations: SubscriptionPlan["limitations"];
  limitationsState: FormDataType;
  setLimitationsState: React.Dispatch<React.SetStateAction<FormDataType>>;
  showErrors: boolean;
  onAddLimitation: () => void;
}
function LimitationsSection({
  limitationInputs,
  limitations,
  limitationsState,
  setLimitationsState,
  showErrors,
  onAddLimitation,
}: Props) {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <Grid
      container
      mt={1}
      padding={1}
      alignItems="center"
      borderRadius={1}
      border={`1px solid ${theme.palette.action.active}`}
    >
      <Grid item xs={12} mb={1}>
        <H4>{intl.formatMessage({ id: "label.limitations" })}</H4>
      </Grid>

      {Object.keys(limitations).map((key) => (
        <Grid item xs={12} key={key}>
          <BodyTextLight>
            •{" "}
            {intl.formatMessage({
              id: `subscription.limitation.${key}`,
              defaultMessage: key,
            })}
            : {get(limitations, key)}
          </BodyTextLight>
        </Grid>
      ))}
      <Grid item sm={7} xs={7}>
        <InputRender
          key={limitationInputs[0].key}
          data={limitationsState}
          input={limitationInputs[0]}
          inputs={limitationInputs}
          setData={setLimitationsState}
          showErrors={showErrors}
        />
      </Grid>
      <Grid item sm={4} xs={4}>
        <InputRender
          key={limitationInputs[1].key}
          data={limitationsState}
          input={limitationInputs[1]}
          inputs={limitationInputs}
          setData={setLimitationsState}
          showErrors={showErrors}
        />
      </Grid>
      <Grid item sm={1} xs={1}>
        <IconButton
          size="large"
          style={{ marginTop: "30px" }}
          onClick={onAddLimitation}
        >
          <CheckMarkIcon color="primary" fontSize="medium" />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default LimitationsSection;
