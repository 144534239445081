import { gql } from "@apollo/client";

export const SUBSCRIPTION_PLANS = gql`
  query SUBSCRIPTION_PLANS($payload: SubscriptionPlansPayload!) {
    subscriptionPlans(payload: $payload) {
      total
      list {
        id
        type
        title
        titleDE
        description
        descriptionDE
        duration
        extraDuration
        active
        defaultPlan
        applicableTo
        limitations
        createdAt
        updatedAt
        deletedAt
        pricing {
          value
          currency
          countryCode
          tax
          refundDeadline
        }
      }
    }
  }
`;

export const CREATE_SUBSCRIPTION_PLAN = gql`
  mutation CREATE_SUBSCRIPTION_PLAN($payload: CreateSubscriptionPlanInput!) {
    createSubscriptionPlan(payload: $payload) {
      id
    }
  }
`;

export const DELETE_SUBSCRIPTION_PLAN = gql`
  mutation DELETE_SUBSCRIPTION_PLAN($id: ID!) {
    deleteSubscriptionPlan(id: $id) {
      status
    }
  }
`;

export const UPDATE_SUBSCRIPTION_PLAN = gql`
  mutation UPDATE_SUBSCRIPTION_PLAN($payload: UpdateSubscriptionPlanInput!) {
    updateSubscriptionPlan(payload: $payload) {
      id
    }
  }
`;
