import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import InputRender, { formHasErrors } from "components/input/InputRender";
import { Button, ModalTitle, ModalDrawer, DrawerForm } from "components/shared";
import { useMutation } from "@apollo/client";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { inputs } from "./constants";
import { FileType } from "types/archive";
import { uploadMedia } from "lib/api";
import { DELETE_GROUP_TYPE, GROUP_TYPES, UPDATE_GROUP_TYPE } from "gql/group";
import {
  DeleteGroupTypeData,
  DeleteGroupTypeInput,
  GroupType,
  UpdateGroupTypeData,
  UpdateGroupTypeInput,
} from "types/group";
import { FormDataType } from "components/input/types";
import { FileEntry } from "types/shared";

interface ContentProps {
  open: boolean;
  toggleDrawer: () => void;
  record: GroupType;
  showSnackbar?: (message: string, severity: Severity) => void;
}

function EditDrawer({
  open,
  toggleDrawer,
  record,
  showSnackbar,
}: ContentProps) {
  const intl = useIntl();
  const [showErrors, setShowErrors] = useState(false);
  const [files, setFiles] = useState<FileEntry[]>();
  const [data, setData] = useState<FormDataType>({
    title: record.title,
    description: record.description || "",
    titleDE: record.titleDE || "",
    descriptionDE: record.descriptionDE || "",
    published: record.published ? "published" : "unpublished",
    priority: record.priority?.toString() || null,
    featured: record.featured || false,
    organizationTypes: record.applicable?.organizationTypes || [],
  });

  // dropzone adds multiple files, so we only use the last one
  useEffect(() => {
    if (files && files?.length > 1) {
      setFiles([files[files.length - 1]]);
    }
  }, [files]);

  const onComplete = () => {
    toggleDrawer();
    setFiles([]);
  };
  const [updateGroupType] = useMutation<
    UpdateGroupTypeData,
    UpdateGroupTypeInput
  >(UPDATE_GROUP_TYPE, {
    onCompleted: onComplete,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: GROUP_TYPES, variables: { includeAll: true } }],
  });

  const [deleteGroupType] = useMutation<
    DeleteGroupTypeData,
    DeleteGroupTypeInput
  >(DELETE_GROUP_TYPE, {
    onCompleted: onComplete,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: GROUP_TYPES, variables: { includeAll: true } }],
  });
  const formInputs = inputs();

  const updateRecord = (archiveId?: string) => {
    const payload = {
      id: record.id,
      data: {
        title: data.title as string,
        description: data.description as string,
        titleDE: (data.titleDE as string) || undefined,
        descriptionDE: (data.descriptionDE as string) || undefined,
        published: data.published === "published",
        priority: data.priority ? Number(data.priority) : undefined,
        featured: data.featured as boolean,
        coverImageId: archiveId,
        applicable: {
          organizationTypes: (data.organizationTypes as string[]) || undefined,
        },
      },
    };
    updateGroupType({ variables: { payload } });
  };
  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formHasErrors(formInputs, data)) {
      setShowErrors(true);
      return;
    }
    const cover = files?.[0];
    if (cover) {
      const archiveId = await uploadCoverImage(cover);
      if (archiveId) updateRecord(archiveId);
    } else {
      updateRecord();
    }
  };

  const handleDelete = () => deleteGroupType({ variables: { id: record.id } });

  const uploadCoverImage = async (cover: FileEntry): Promise<string | null> => {
    const result = await uploadMedia(cover.file, { type: FileType.image }, true)
      .then((response) => response.json())
      .catch((error) => error);

    // eslint-disable-next-line no-underscore-dangle
    const archiveId = result?.data?._id;
    if (archiveId) {
      return archiveId;
    } else {
      showSnackbar?.(
        intl.formatMessage({ id: "error.coverUploadFailed" }),
        Severity.ERROR,
      );
      return null;
    }
  };

  return (
    <ModalDrawer anchor="right" open={open} onClose={toggleDrawer}>
      <ModalTitle p={2}>
        {intl.formatMessage({ id: "label.groupType" })}
      </ModalTitle>
      <DrawerForm noValidate onSubmit={handleOnsubmit}>
        {formInputs.map((input) => {
          return (
            <InputRender
              key={input.key}
              data={data}
              input={input}
              inputs={formInputs}
              setData={setData}
              showErrors={showErrors}
              files={files}
              setFiles={setFiles}
            />
          );
        })}
        <Button type="submit" fullWidth variant="contained" color="primary">
          {intl.formatMessage({ id: "label.update" })}
        </Button>
        {!record.deletedAt && (
          <Button
            fullWidth
            onClick={handleDelete}
            variant="contained"
            color="error"
            style={{ marginTop: "10px" }}
          >
            {intl.formatMessage({ id: "label.delete" })}
          </Button>
        )}
      </DrawerForm>
    </ModalDrawer>
  );
}

EditDrawer.defaultProps = {
  showSnackbar: () => {},
};

export default withSnackbar(EditDrawer);
