import React, { CSSProperties } from "react";
import { useTheme } from "@mui/material/styles";

interface Props {
  color?: string;
  width?: string;
  style?: CSSProperties;
}
function Svg({ color, width, style }: Props) {
  const theme = useTheme();
  return (
    <div style={{ width, display: "flex", ...(style ?? {}) }}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.675 13.7L16.675 4.61252C16.4907 4.44387 16.2499 4.35034 16 4.35034C15.7501 4.35034 15.5093 4.44387 15.325 4.61252L5.325 13.7C5.22437 13.7951 5.14378 13.9093 5.08796 14.0359C5.03215 14.1626 5.00224 14.2991 5 14.4375V26C5 26.2652 5.10536 26.5196 5.29289 26.7071C5.48043 26.8947 5.73478 27 6 27H26C26.2652 27 26.5196 26.8947 26.7071 26.7071C26.8946 26.5196 27 26.2652 27 26V14.4375C26.9978 14.2991 26.9679 14.1626 26.912 14.0359C26.8562 13.9093 26.7756 13.7951 26.675 13.7V13.7Z"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="5"
          y1="20"
          x2="27"
          y2="20"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
        />
        <line
          x1="20"
          y1="27"
          x2="20"
          y2="8"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}

Svg.defaultProps = {
  color: null,
  width: null,
  style: {},
};

export default Svg;
