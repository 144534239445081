import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useIntl } from "react-intl";
import image from "assets/images/maintenance.svg";
import { H1, H3, H5 } from "components/typography";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 1,
  minHeight: "100vh",
  justifyContent: "center",
  alignItems: "center",
}));
const Content = styled("div")(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: "600px",
}));

const Img = styled("img")(({ theme }) => ({
  height: "100px",
  objectFit: "contain",
  marginBottom: "30px",
}));

function UnderMaintenance() {
  const intl = useIntl();
  const theme = useTheme();

  const textStyle = {
    textAlign: "center",
    color: theme.palette.text.primary,
  };

  return (
    <Root>
      <Content>
        <Img src={image} />
        <H1 sx={textStyle}>
          {intl.formatMessage({ id: "label.maintenance.title" })}
        </H1>
        <br />

        <H3 sx={textStyle}>
          {intl.formatMessage({ id: "label.maintenance.subtitle" })}
        </H3>
        <br />
        <H5 sx={textStyle}>
          {intl.formatMessage({ id: "label.maintenance.content" })}
        </H5>
      </Content>
    </Root>
  );
}
export default UnderMaintenance;
