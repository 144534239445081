import React from "react";
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { UserRoles } from "types/auth";
import ROUTES from "lib/routes";
import { BodyTextLight } from "components/typography";
import { User } from "types/user";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

interface RoleProps {
  row: GridRenderCellParams;
  onChange?: (rowId: string, event: SelectChangeEvent) => void;
}
export function RoleCell({ row, onChange }: RoleProps) {
  const handleOnChange = (event: SelectChangeEvent) =>
    onChange?.(row.id as string, event);
  return (
    <FormControl fullWidth>
      <InputLabel>-</InputLabel>
      <Select value={row.value ?? ""} label="-" onChange={handleOnChange}>
        {Object.values(UserRoles).map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const LinkWrapper = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  textDecorationLine: "none",
  ":hover svg": {
    display: "flex",
  },
}));

export function UserCell({ record }: { record: User }) {
  const theme = useTheme();
  const name = `${record.firstName ?? ""} ${record.lastName ?? ""}`;
  return (
    <LinkWrapper to={ROUTES.DASHBOARD_USERS_ROUTE(record.id)}>
      <BodyTextLight color={theme.palette.info.dark}>
        {name.trim() ?? "-"}
      </BodyTextLight>
    </LinkWrapper>
  );
}
