import { IntlShape } from "react-intl";
import { isValidNumber } from "utils/validators";
import {
  InputType,
  isOption,
  Option,
  isString,
  FormDataValue,
  FormDataType,
} from "./types";

/*
 * Process input value with price template _,__$
 */
export function processPriceInput(text: string): string {
  const value = text.replaceAll(",", "").replaceAll(".", "");
  if (value && !isValidNumber(value)) throw new Error("Not a number!");

  let stringValue = Number(value).toString();

  while (stringValue.length < 3) {
    stringValue = `0${stringValue}`;
  }

  return `${stringValue.substring(
    0,
    stringValue.length - 2,
  )}.${stringValue.substring(stringValue.length - 2)}`;
}

// convert to number price input
export function defaultPriceValue(price: string) {
  let value = Number(price).toString();
  while (
    value.indexOf(".") < 0 ||
    value.substring(value.indexOf(".") + 1).length < 2
  ) {
    value = value.indexOf(".") > 0 ? `${value}0` : `${value}.0`;
  }
  return value;
}

export function getOptionValue(selection: Option | string | null) {
  if (!selection) return null;
  return isOption(selection) ? selection?.value : selection;
}

export function getAutocompleteValue(input: InputType, value: FormDataValue) {
  const valueInOptions = input.options?.find((item) => item.value === value);
  const stringOptionValue =
    isString(value) && input.freeSolo ? (value as string) : undefined;
  const autocompleteValue = valueInOptions || stringOptionValue;
  return autocompleteValue;
}

export const getErrorLabel = (
  inputs: InputType[],
  data: FormDataType,
  key: string,
): string | undefined => {
  const input = inputs.find((item) => item.key === key);
  const validationRules = input?.validators;
  const errorLabel = validationRules?.find(
    (item) => !item.method(data[key], data, input?.options),
  )?.label;
  return errorLabel;
};

export function getHelperText(
  input: InputType,
  inputs: InputType[],
  intl: IntlShape,
  data: FormDataType,
  showErrors: boolean,
) {
  if (showErrors && !!getErrorLabel(inputs, data, input.key)) {
    return intl.formatMessage({
      id: getErrorLabel(inputs, data, input.key),
    });
  }
  if (input.infoLabel) {
    return intl.formatMessage({
      id: input.infoLabel,
      defaultMessage: input.infoLabel,
    });
  }
  return undefined;
}

export const getUnitErrorLabel = (
  inputs: InputType[],
  data: FormDataType,
  key: string,
): string | undefined => {
  const input = inputs.find((item) => item.key === key);
  const unitValidationRules = input?.unitValidators;
  const unitErrorLabel = unitValidationRules?.find(
    (item) => !item.method(data[key], data, input?.options),
  )?.label;

  return unitErrorLabel;
};
