import React, { useCallback, useState } from "react";
import {
  Column,
  Container,
  Content,
  DataGridComp,
  PageTitle,
  Row,
  RowSpaced,
} from "components/shared";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import LoadingIndicator from "components/LoadingIndicator";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import {
  BodyText,
  BodyTextLight,
  CaptionText,
  H2,
  H3,
} from "components/typography";
import { useData, useMutations } from "./hooks";
import { orderBy } from "lodash";
import { Button, Divider, IconButton, useTheme } from "@mui/material";
import { getDate, isPastDate } from "utils/datetime";
import { columns, getStatusColor } from "./utils";
import { getCurrencyLabel } from "utils/monetary";
import { cardBoxShadow } from "assets/styles/theme";
import {
  InvoiceStatus,
  SubscriptionOrderExtended,
  SubscriptionStatus,
} from "types/subscription";
import { Add } from "@mui/icons-material";
import FormModal from "components/FormModal";
import { SuggestionSection } from "components/SuggestionsSection";
import { JsonObject } from "types/shared";
import {
  Section,
  getEditInputs,
  getInitialState,
  getSectionWithId,
} from "./constants";
import {
  ConfirmationData,
  withConfirmationModal,
} from "components/hoc/ConfirmationModalHoc";

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void;
  requestConfirmation?: (data: ConfirmationData) => void;
}
function UserDetails({ showSnackbar, requestConfirmation }: Props) {
  const params = useParams() as { organizationId: string };
  const intl = useIntl();
  const theme = useTheme();
  const [editing, setEditing] = useState<SuggestionSection<Section>>();
  const [editingId, setEditingId] = useState<string>();
  const hideEditingModal = useCallback(() => setEditing(undefined), []);

  const handleOnEditWithId = (sectionId: Section, recordId: string) => {
    const section = getSectionWithId(sectionId, intl);
    setEditingId(recordId);
    setEditing(section);
  };
  const {
    data: { organization },
    loading,
  } = useData(params?.organizationId);

  const {
    loading: mutating,
    mutations: { adminExtendSubscription, adminDeleteSubscription },
  } = useMutations(params?.organizationId, {
    showSnackbar,
    hideEditingModal,
  });

  function handleOnUpdate<Section>(formData: JsonObject, sectionId?: Section) {
    switch (sectionId) {
      case Section.extendSubscription:
        if (!editingId) return;
        const payload = {
          duration: Number(formData.duration),
          subscriptionId: editingId,
        };
        adminExtendSubscription({ variables: { payload } });
        break;

      default:
        // 🤷
        return;
    }
  }

  const handleDelete = (subscriptionId: string) =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: "label.deletingSubscription" }),
      description: intl.formatMessage({
        id: "label.deletingSubscriptionConfirmation",
      }),
      actions: [
        {
          label: intl.formatMessage({ id: "label.cancel" }),
        },
        {
          label: intl.formatMessage({ id: "label.delete" }),
          color: "error",
          onClick: () =>
            adminDeleteSubscription({ variables: { subscriptionId } }),
        },
      ],
    });

  const subscriptions = orderBy(
    organization?.subscriptions ?? [],
    [(obj) => new Date(obj.createdAt)],
    ["desc"]
  );
  const orderedOrders = (orders: SubscriptionOrderExtended[]) =>
    orderBy(orders ?? [], [(obj) => new Date(obj.date)], ["desc"]);
  const initialState = editing ? getInitialState(editing.id) : {};
  const inputs = getEditInputs(intl, editing?.id) ?? [];
  const isLoading = mutating || loading;
  return (
    <Container>
      <LoadingIndicator visible={isLoading} />
      <Content>
        <RowSpaced>
          <div />
          <PageTitle>• {organization?.referenceNumber}</PageTitle>
        </RowSpaced>
        <H2>
          {intl.formatMessage({ id: "label.subscriptions" })} (
          {subscriptions.length})
        </H2>
        <Divider style={{ marginBottom: "20px" }} />
        {subscriptions.map((subscription) => {
          const hasExpired = isPastDate(subscription.endDate);
          const status = hasExpired ? "EXPIRED" : subscription.status;

          return (
            <Column
              key={subscription.id}
              style={{
                boxShadow: cardBoxShadow,
                marginBottom: "12px",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              <RowSpaced>
                <Row>
                  <H3>
                    {subscription.subscriptionPlan.title}-
                    {subscription.subscriptionPlan.duration}
                  </H3>

                  {subscription.status === SubscriptionStatus.ACTIVE && (
                    <IconButton
                      size="small"
                      sx={{ ml: "4px" }}
                      color="error"
                      onClick={() =>
                        handleOnEditWithId(
                          Section.extendSubscription,
                          subscription.id
                        )
                      }
                    >
                      <Add fontSize="small" />
                    </IconButton>
                  )}
                </Row>

                {subscription.status === SubscriptionStatus.PENDING && (
                  <Button onClick={() => handleDelete(subscription.id)}>
                    <BodyText>
                      {intl.formatMessage({ id: "label.delete" })}
                    </BodyText>
                  </Button>
                )}
              </RowSpaced>
              <Divider style={{ marginBottom: "10px" }} />
              <BodyText>
                • {intl.formatMessage({ id: "label.status" })}:{" "}
                <BodyTextLight
                  color={getStatusColor(theme, status)}
                  style={{
                    textDecoration: "underline",
                    backgroundColor: hasExpired
                      ? theme.palette.shared.lightRed
                      : theme.palette.shared.lightBlue,
                    flex: 0,
                    width: "120px",
                    textAlign: "center",
                    borderRadius: "100px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    padding: "4px",

                    color:
                      subscription.status === SubscriptionStatus.CANCELED
                        ? theme.palette.shared.red
                        : theme.palette.text.primary,
                  }}
                >
                  {intl.formatMessage({
                    id: `subscription.status.${status}`,
                    defaultMessage: status,
                  })}
                </BodyTextLight>
              </BodyText>
              <BodyText>
                • {intl.formatMessage({ id: "label.startDate" })}:{" "}
                <BodyTextLight color={theme.palette.primary.main}>
                  {getDate(subscription.startDate)}
                </BodyTextLight>
              </BodyText>
              <BodyText>
                • {intl.formatMessage({ id: "label.endDate" })}:{" "}
                <BodyTextLight color={theme.palette.primary.main}>
                  {getDate(subscription.endDate)}
                </BodyTextLight>
              </BodyText>
              <BodyText>
                • {intl.formatMessage({ id: "label.orders" })}:
              </BodyText>

              {orderedOrders(subscription.orders).map((order) => (
                <Column
                  key={order.id}
                  style={{
                    margin: "5px 0 0 10px",
                    padding: "10px",
                    borderRadius: "4px",
                    border: `1px solid ${theme.palette.shared.darkGray}`,
                    backgroundColor: theme.palette.shared.lightBlue,
                  }}
                >
                  <Row>
                    <BodyText>
                      {`⇒`} {intl.formatMessage({ id: "label.orderNumber" })}:{" "}
                      <BodyTextLight color={theme.palette.primary.main}>
                        {order.orderNumber}
                      </BodyTextLight>
                    </BodyText>
                  </Row>
                  <BodyText>
                    {intl.formatMessage({ id: "label.invoices" })}:
                  </BodyText>

                  <Column>
                    {order.invoices.map((invoice) => (
                      <Column
                        key={invoice.id}
                        style={{
                          margin: "5px 0 0 10px",
                          borderTop: `1px solid ${theme.palette.background.default}`,
                        }}
                      >
                        <CaptionText>
                          {intl.formatMessage({ id: "label.invoiceNumber" })}:{" "}
                          <CaptionText color={theme.palette.primary.main}>
                            {invoice.invoiceNumber}
                          </CaptionText>
                        </CaptionText>
                        <CaptionText>
                          {intl.formatMessage({ id: "label.status" })}:{" "}
                          <CaptionText
                            color={
                              invoice.status === InvoiceStatus.PAID
                                ? theme.palette.primary.main
                                : theme.palette.shared.red
                            }
                          >
                            {intl.formatMessage({
                              id: `subscription.invoiceStatus.${invoice.status}`,
                              defaultMessage: invoice.status,
                            })}
                          </CaptionText>
                        </CaptionText>
                        <CaptionText>
                          {intl.formatMessage({ id: "label.date" })}:{" "}
                          <CaptionText color={theme.palette.primary.main}>
                            {getDate(invoice.date)}
                          </CaptionText>
                        </CaptionText>
                        <CaptionText>
                          {intl.formatMessage({ id: "label.price" })}:{" "}
                          <CaptionText color={theme.palette.primary.main}>
                            {invoice.pricing.value}{" "}
                            {getCurrencyLabel(invoice.pricing.currency)}
                          </CaptionText>
                        </CaptionText>
                        <CaptionText>
                          {intl.formatMessage({ id: "label.totalPrice" })}:{" "}
                          <CaptionText color={theme.palette.primary.main}>
                            {invoice.pricing.totalAmount}{" "}
                            {getCurrencyLabel(invoice.pricing.currency)}
                          </CaptionText>
                        </CaptionText>
                        <CaptionText>
                          {intl.formatMessage({ id: "label.country" })}:{" "}
                          <CaptionText color={theme.palette.primary.main}>
                            {invoice.pricing.countryCode}
                          </CaptionText>
                        </CaptionText>
                        <CaptionText>
                          {intl.formatMessage({ id: "label.tax" })}:{" "}
                          <CaptionText color={theme.palette.primary.main}>
                            {invoice.pricing.tax}%
                          </CaptionText>
                        </CaptionText>
                        <DataGridComp
                          style={{ minHeight: "200px" }}
                          initialState={{
                            pagination: {
                              page: 0,
                              pageSize: invoice?.items.length,
                            },
                          }}
                          rows={invoice?.items ?? []}
                          columns={columns()}
                          rowCount={invoice?.items.length || 0}
                          disableSelectionOnClick
                        />
                      </Column>
                    ))}
                  </Column>
                </Column>
              ))}
            </Column>
          );
        })}
      </Content>
      <FormModal
        toggleModal={hideEditingModal}
        inputs={inputs}
        extraInputs={[]}
        section={editing}
        initialState={initialState}
        onSubmit={handleOnUpdate}
        singleFileSelect
      />
    </Container>
  );
}

export default withConfirmationModal(withSnackbar(UserDetails));
