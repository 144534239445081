// this file refers to the file archive

export enum MediaType {
  image = "IMAGE",
  document = "DOCUMENT",
}

// this is an extension of MediaType
export enum FileType {
  image = "IMAGE",
  document = "DOCUMENT",
  avatar = "AVATAR",
}

export enum DocumentType {
  INVOICE = "INVOICE",
  RECEIPT = "RECEIPT",
  INSURANCE = "INSURANCE",
  WARRANTY = "WARRANTY",
}

export interface ArchiveFile {
  id: string;
  path: string;
  media?: {
    thumbnail?: string;
    medium?: string;
    full?: string;
    avatar?: string;
  };
  workspaceId: string;
  type: FileType;
  userId?: string;
  isPublic: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface WorkspaceArchiveData {
  workspaceArchiveFiles: {
    list: ArchiveFile[];
    total: number;
  };
}

export interface AddFileToItemData {
  addFileToItem: ArchiveFile;
}

export interface AddFileToNestData {
  addFileToNest: ArchiveFile;
}

export interface AddFileToBoxData {
  addFileToBox: ArchiveFile;
}
