import { JsonObject, Session } from "types/shared";

export const localStorageKeys = {
  session: "session",
};

export function save(key: string, data: JsonObject) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function load(key: string) {
  return JSON.parse(localStorage.getItem(key) || "{}");
}

export function remove(key: string) {
  localStorage.removeItem(key);
}

export function clearStorage() {
  localStorage.clear();
}

export function saveAuth(data: Session) {
  localStorage.setItem(localStorageKeys.session, JSON.stringify(data));
}

export function clearSession() {
  remove(localStorageKeys.session);
}

export function getAuth(): Session | null {
  try {
    return load(localStorageKeys.session) as Session;
  } catch {
    return null;
  }
}
