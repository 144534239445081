import { IntlShape } from "react-intl";
import ROUTES from "lib/routes";
import ExitToAppSharp from "@mui/icons-material/ExitToAppSharp";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import BusinessIcon from "@mui/icons-material/Business";
import {
  Book,
  Cardholder,
  CellSignalHigh,
  HouseSimple,
  Users,
} from "assets/svg";
import { ChatBubbleOutline } from "@mui/icons-material";

export const getMenuItems = (intl: IntlShape) => [
  {
    section: intl.formatMessage({ id: "label.general" }),
    entries: [
      // {
      //   key: "dashboard",
      //   title: intl.formatMessage({ id: "label.overview" }),
      //   icon: SortSharp,
      //   route: ROUTES.DASHBOARD,
      // },
      {
        key: "categories",
        title: intl.formatMessage({ id: "label.categories" }),
        icon: Book,
        route: ROUTES.DASHBOARD_CATEGORIES_ROUTE,
      },
      {
        key: "service-provider-categories",
        title: intl.formatMessage({ id: "label.serviceProviderCategories" }),
        icon: CellSignalHigh,
        route: ROUTES.DASHBOARD_PROVIDER_CATEGORIES_ROUTE,
      },
      {
        key: "nestTypes",
        title: intl.formatMessage({ id: "label.nestTypes" }),
        icon: HouseSimple,
        route: ROUTES.DASHBOARD_NEST_TYPES_ROUTE,
      },
      // {
      //   key: "serviceTypes",
      //   title: intl.formatMessage({ id: "label.serviceTypes" }),
      //   icon: DesignServicesIcon,
      //   route: ROUTES.DASHBOARD_SERVICE_TYPES_ROUTE,
      // },
      {
        key: "groupTypes",
        title: intl.formatMessage({ id: "label.groupTypes" }),
        icon: Cardholder,
        route: ROUTES.DASHBOARD_GROUP_TYPES_ROUTE,
      },
      {
        key: "workspaceTypes",
        title: intl.formatMessage({ id: "label.boardTypes" }),
        icon: DeveloperBoardIcon,
        route: ROUTES.DASHBOARD_WORKSPACE_TYPES_ROUTE,
      },
      {
        key: "subscription-plans",
        title: intl.formatMessage({ id: "label.subscriptionPlans" }),
        icon: CardMembershipIcon,
        route: ROUTES.DASHBOARD_SUBSCRIPTION_PLANS_ROUTE,
      },
    ],
  },
  {
    section: intl.formatMessage({ id: "label.content" }),
    entries: [
      {
        key: "users",
        title: intl.formatMessage({ id: "label.users" }),
        icon: Users,
        route: ROUTES.DASHBOARD_USERS_LIST_ROUTE,
      },
      // {
      //   key: "questionnaires",
      //   title: intl.formatMessage({ id: "label.questionnaires" }),
      //   icon: CategorySharp,
      //   route: ROUTES.DASHBOARD_QUESTIONNAIRES,
      // },
      {
        key: "workspaces",
        title: intl.formatMessage({ id: "label.boards" }),
        icon: WorkspacesIcon,
        route: ROUTES.DASHBOARD_WORKSPACES_ROUTE,
      },
      {
        key: "organizations",
        title: intl.formatMessage({ id: "label.organizations" }),
        icon: BusinessIcon,
        route: ROUTES.DASHBOARD_ORGANIZATIONS_LIST_ROUTE,
      },
      {
        key: "user-requests",
        title: intl.formatMessage({ id: "label.userRequests" }),
        icon: ChatBubbleOutline,
        route: ROUTES.DASHBOARD_USER_REQUESTS_ROUTE,
      },
    ],
  },
  {
    section: intl.formatMessage({ id: "label.management" }),
    entries: [
      {
        key: "logout",
        title: intl.formatMessage({ id: "label.logout" }),
        icon: ExitToAppSharp,
        route: "logout", // custom action for logout route
      },
    ],
  },
];
