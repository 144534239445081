import { compareAsc, format } from "date-fns";

export enum DateFormat {
  date = "dd/MM/yyyy",
  time = "HH:mm:ss",
  datetime = "dd/MM/yyyy HH:mm:ss",
}

export const getDateTime = (value: string | Date) =>
  format(new Date(value), DateFormat.datetime) || "-";

export const getDate = (value: string | Date) =>
  format(new Date(value), DateFormat.date) || "-";

export const getTime = (value: string | Date) =>
  format(new Date(value), DateFormat.time) || "-";

export const formatDate = (value: string | Date, dateFormat: DateFormat) =>
  format(new Date(value), dateFormat) || "-";

function getStartOfDay(date: Date) {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

export function todayStartOfDay() {
  return getStartOfDay(new Date());
}

export function yesterdayStartOfDay() {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return getStartOfDay(yesterday);
}

/**
 * Method to check if a provided date is a date in the past.
 */
export function isPastDate(date: Date) {
  return compareAsc(new Date(), new Date(date)) > 0;
}
