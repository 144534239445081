import React, { useState } from "react";
import { useIntl } from "react-intl";
import InputRender, { formHasErrors } from "components/input/InputRender";
import { Button, ModalTitle, ModalDrawer, DrawerForm } from "components/shared";
import { useMutation } from "@apollo/client";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { inputs, limitationsInputs, pricingInputs } from "./constants";
import { Box } from "@mui/material";
import { FormDataType } from "components/input/types";
import {
  DeleteSubscriptionPlanData,
  DeleteSubscriptionPlanInput,
  Limitations,
  SubscriptionPlan,
  UpdateSubscriptionPlanData,
  UpdateSubscriptionPlanInput,
} from "types/subscription";
import {
  DELETE_SUBSCRIPTION_PLAN,
  SUBSCRIPTION_PLANS,
  UPDATE_SUBSCRIPTION_PLAN,
} from "gql/subscription";
import { Currencies, OrganizationType } from "types/shared";
import {
  ConfirmationData,
  withConfirmationModal,
} from "components/hoc/ConfirmationModalHoc";
import LimitationsSection from "./LimitationsSection";
import PricingSection from "./PricingSection";
import { omit } from "lodash";

interface ContentProps {
  open: boolean;
  toggleDrawer: () => void;
  record: SubscriptionPlan;
  showSnackbar?: (message: string, severity: Severity) => void;
  requestConfirmation?: (data: ConfirmationData) => void;
}
const limitationsInitialState: FormDataType = {
  limitation: null,
  value: null,
};

const pricingInitialState: FormDataType = {
  countryCode: "DE",
  tax: "19", // vat tax in Germany
  value: "0",
  currency: Currencies.EUR,
  refundDeadline: "0",
};

function EditDrawer({
  open,
  toggleDrawer,
  record,
  showSnackbar,
  requestConfirmation,
}: ContentProps) {
  const intl = useIntl();
  const [showErrors, setShowErrors] = useState(false);
  const [data, setData] = useState<FormDataType>({
    type: record.type,
    title: record.title,
    titleDE: record.titleDE,
    description: record.description || "",
    descriptionDE: record.descriptionDE || "",
    duration: record.duration?.toString(),
    extraDuration: record.extraDuration?.toString(),
    active: record.active,
    defaultPlan: record.defaultPlan,
    applicableTo: record.applicableTo ?? [],
  });
  // used for input form management
  const [limitationsState, setLimitationsState] = useState<FormDataType>(
    limitationsInitialState,
  );
  const [limitations, setLimitations] = useState<
    SubscriptionPlan["limitations"]
  >(record.limitations ?? {});

  const [pricingState, setPricingState] =
    useState<FormDataType>(pricingInitialState);
  const [pricing, setPricing] = useState<SubscriptionPlan["pricing"]>(
    record.pricing.map((item) => omit(item, "__typename")) ?? [],
  );

  const formInputs = inputs(intl);
  const limitationInputs = limitationsInputs(intl);
  const pricingInputFields = pricingInputs(intl);

  const onComplete = () => toggleDrawer();
  const [updateSubscriptionPlan] = useMutation<
    UpdateSubscriptionPlanData,
    UpdateSubscriptionPlanInput
  >(UPDATE_SUBSCRIPTION_PLAN, {
    onCompleted: onComplete,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      {
        query: SUBSCRIPTION_PLANS,
        variables: { payload: { includeAll: true } },
      },
    ],
  });

  const [deleteSubscriptionPlan] = useMutation<
    DeleteSubscriptionPlanData,
    DeleteSubscriptionPlanInput
  >(DELETE_SUBSCRIPTION_PLAN, {
    onCompleted: onComplete,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      {
        query: SUBSCRIPTION_PLANS,
        variables: { payload: { includeAll: true } },
      },
    ],
  });

  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formHasErrors(formInputs, data)) {
      setShowErrors(true);
      return;
    }
    const payload = {
      id: record.id,
      data: {
        type: data.type as SubscriptionPlan["type"],
        title: data.title as string,
        titleDE: data.titleDE as string,
        description: data.description as string,
        descriptionDE: data.descriptionDE as string,
        duration: Number(data.duration),
        extraDuration: Number(data.extraDuration),
        active: data.active as boolean,
        defaultPlan: data.defaultPlan as boolean,
        applicableTo: data.applicableTo as OrganizationType[],
        limitations: limitations as SubscriptionPlan["limitations"],
        pricing: pricing,
      },
    };
    updateSubscriptionPlan({ variables: { payload } });
  };

  const onAddLimitation = () => {
    if (formHasErrors(limitationInputs, limitationsState)) {
      setShowErrors(true);
      return;
    }
    setLimitations({
      ...limitations,
      [limitationsState.limitation as Limitations]: Number(
        limitationsState.value,
      ),
    });
  };

  const onRemovePricing = (countryCode: string) => {
    setPricing(pricing.filter((price) => price.countryCode !== countryCode));
  };

  const onAddPricing = () => {
    if (pricingState && formHasErrors(pricingInputFields, pricingState)) {
      setShowErrors(true);
      return;
    }
    setPricing([
      ...pricing.filter(
        (price) => price.countryCode !== pricingState.countryCode,
      ),
      {
        countryCode: pricingState.countryCode as string,
        value: Number(pricingState.value),
        currency: pricingState.currency as Currencies,
        tax: Number(pricingState.tax),
        refundDeadline: Number(pricingState.refundDeadline),
      },
    ]);
    setPricingState(pricingInitialState);
  };

  const handleDelete = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: "label.deletingSubscriptionPlan" }),
      description: intl.formatMessage({
        id: "label.deletingSubscriptionPlanConfirmation",
      }),
      actions: [
        {
          label: intl.formatMessage({ id: "label.cancel" }),
        },
        {
          label: intl.formatMessage({ id: "label.delete" }),
          color: "error",
          onClick: () =>
            deleteSubscriptionPlan({ variables: { id: record.id } }),
        },
      ],
    });

  return (
    <ModalDrawer anchor="right" open={open} onClose={toggleDrawer}>
      <ModalTitle p={2}>
        {intl.formatMessage({ id: "label.nestType" })}
      </ModalTitle>
      <DrawerForm noValidate onSubmit={handleOnsubmit}>
        {formInputs.map((input) => {
          return (
            <InputRender
              key={input.key}
              data={data}
              input={input}
              inputs={formInputs}
              setData={setData}
              showErrors={showErrors}
            />
          );
        })}

        <PricingSection
          showErrors={showErrors}
          pricingState={pricingState}
          setPricingState={setPricingState}
          onAddPricing={onAddPricing}
          onRemovePricing={onRemovePricing}
          pricing={pricing}
        />

        <LimitationsSection
          limitationInputs={limitationInputs}
          limitations={limitations}
          limitationsState={limitationsState}
          setLimitationsState={setLimitationsState}
          showErrors={showErrors}
          onAddLimitation={onAddLimitation}
        />

        <Box height={30} />
        <Button type="submit" fullWidth variant="contained" color="primary">
          {intl.formatMessage({ id: "label.update" })}
        </Button>
        {!record?.deletedAt && (
          <Button
            fullWidth
            onClick={handleDelete}
            variant="contained"
            color="error"
            style={{ marginTop: "10px" }}
          >
            {intl.formatMessage({ id: "label.delete" })}
          </Button>
        )}
      </DrawerForm>
    </ModalDrawer>
  );
}

EditDrawer.defaultProps = {
  showSnackbar: () => {},
};

export default withConfirmationModal(withSnackbar(EditDrawer));
