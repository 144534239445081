import { isPositiveNumber, isValidText } from "utils/validators";
import { OrganizationType } from "types/shared";
import { InputType, Type } from "components/input/types";
import { FileType } from "types/archive";

const orgTypes = [
  OrganizationType.personal,
  OrganizationType.family,
  OrganizationType.business,
];
const publishedOptions = [
  { label: "published", value: "published" },
  { label: "unpublished", value: "unpublished" },
];

export const inputs = (): InputType[] => [
  {
    key: "coverImageId",
    label: "label.addCover",
    type: Type.DROPZONE,
    margin: "none",
    fullWidth: true,
    validators: [],
    fileType: FileType.image,
  },
  {
    key: "title",
    label: "label.title",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: true,
    fullWidth: true,
    margin: "none",
    validators: [
      {
        method: isValidText,
        label: "label.required",
      },
    ],
  },
  {
    key: "description",
    label: "label.description",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  {
    key: "titleDE",
    label: "label.titleDE",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    validators: [],
  },
  {
    key: "descriptionDE",
    label: "label.descriptionDE",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  {
    key: "featured",
    label: "label.featured",
    type: Type.CHECKBOX,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    validators: [],
  },
  {
    key: "priority",
    label: "label.priority",
    type: Type.NUMBER,
    autoComplete: "",
    autoFocus: false,
    fullWidth: true,
    margin: "dense",
    validators: [
      {
        method: isPositiveNumber,
        label: "label.positiveNumber",
      },
    ],
  },
  {
    key: "organizationTypes",
    label: "label.applicableOrganizationTypes",
    type: Type.MULTI_SELECT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    useTranslation: true,
    fullWidth: true,
    options:
      orgTypes.map((input) => ({
        value: input,
        label: input.toLowerCase(),
      })) || [],
    validators: [],
  },

  {
    key: "published",
    label: "label.published",
    type: Type.SINGLE_SELECT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    options: publishedOptions,
    validators: [],
  },
];
