import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import InputBase from "@mui/material/InputBase";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";

export const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  backgroundColor: theme.palette.background.default,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.background.default,
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const ListSection = styled("li")(({ theme }) => ({
  backgroundColor: "inherit",
}));

export const UL = styled("ul")(({ theme }) => ({
  backgroundColor: "inherit",
  padding: 0,
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const LogoImg = styled("img")(({ theme }) => ({
  height: "40px",
  objectFit: "contain",
}));

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  // backgroundColor: alpha(theme.palette.primary.main, 0.15),
  // '&:hover': {
  //   backgroundColor: alpha(theme.palette.primary.main, 0.25),
  // },
  display: "flex",
  justifyContent: "flex-end",
  marginRight: 0,
  marginLeft: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "104px",
  backgroundColor: "transparent",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 0, 1, 2),
    boxShadow: "0px 0px 21px 1px #00000012",
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    borderRadius: "104px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  elevation: 0,
  boxShadow: "none",
  [theme.breakpoints.up("md")]: {
    paddingLeft: `${drawerWidth}px`,
  },
}));

export const HeaderSeparator = styled(Toolbar)(({ theme }) => ({
  borderBottom: "1px solid #E0E0E0",
  display: "flex",
  position: "absolute",
  right: "30px",
  left: "30px",
  width: `90%`,
}));
