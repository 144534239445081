import { Session, Status } from "./shared";

export enum UserRoles {
  admin = "ADMIN",
  manager = "MANAGER",
}

export interface LoginInput {
  payload: {
    email: string;
    password: string;
  };
}

export interface RegisterInput {
  payload: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  };
}

export interface LoginData {
  login: Session;
}

export interface RegisterData {
  register: Session;
}

export interface ConfirmEmailInput {
  token: string;
}

export interface ConfirmEmailData {
  confirmEmail: Status;
}
