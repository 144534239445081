import React from "react";
import { useTheme } from "@mui/material/styles";

interface Props {
  color?: string;
  width?: string;
}
function Svg({ color, width }: Props) {
  const theme = useTheme();
  return (
    <div style={{ width, display: "flex" }}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26 5H6C5.44772 5 5 5.44772 5 6V26C5 26.5523 5.44772 27 6 27H26C26.5523 27 27 26.5523 27 26V6C27 5.44772 26.5523 5 26 5Z"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27 19.9999L21.7125 14.7124C21.6195 14.6179 21.5085 14.5429 21.3862 14.4917C21.2639 14.4404 21.1326 14.4141 21 14.4141C20.8674 14.4141 20.7361 14.4404 20.6138 14.4917C20.4915 14.5429 20.3805 14.6179 20.2875 14.7124L14.7125 20.2874C14.6195 20.3819 14.5085 20.4569 14.3862 20.5081C14.2639 20.5593 14.1326 20.5857 14 20.5857C13.8674 20.5857 13.7361 20.5593 13.6138 20.5081C13.4915 20.4569 13.3805 20.3819 13.2875 20.2874L10.7125 17.7124C10.6195 17.6179 10.5085 17.5429 10.3862 17.4917C10.2639 17.4404 10.1326 17.4141 10 17.4141C9.86739 17.4141 9.7361 17.4404 9.61378 17.4917C9.49146 17.5429 9.38055 17.6179 9.2875 17.7124L5 21.9999"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 13C13.3284 13 14 12.3284 14 11.5C14 10.6716 13.3284 10 12.5 10C11.6716 10 11 10.6716 11 11.5C11 12.3284 11.6716 13 12.5 13Z"
          fill={color ?? theme.palette.text.secondary}
        />
      </svg>
    </div>
  );
}

Svg.defaultProps = {
  color: null,
  width: null,
};

export default Svg;
