import { currencies } from "lib/constants";

export function getMonetaryValue(amount?: {
  value?: number;
  currency?: string;
}) {
  const currency = getCurrencyLabel(amount?.currency);
  return typeof amount?.value === "number"
    ? `${amount?.value} ${currency}`
    : "-";
}

export function getCurrencyLabel(currency?: string) {
  return currencies?.find((i) => i.value === currency)?.label || "";
}
