import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/material/styles";
import { SelectChangeEvent } from "@mui/material/Select";
import { useDropzone } from "react-dropzone";
import { GridRowsProp } from "@mui/x-data-grid";
import { bytesToMb } from "utils/file";
import { FileEntry } from "types/shared";
import { H5 } from "components/typography";
import { FileType } from "types/archive";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import {
  Container,
  DropzoneFocus,
  DropZoneContainer,
  Span,
  DataGridWrapper,
  ListFooter,
  ImageThumbnails,
  DocumentsList,
} from "./fragments";
import { columns } from "./constants";
import { DataGridComp } from "components/shared";
import { ErrorType } from "hooks/api/fileUpload";

interface Props {
  files: FileEntry[];
  fileType: FileType;
  showThumbnails?: boolean;
  dropzoneTitle?: string;
  showSnackbar?: (message: string, severity: Severity) => void;
  setFiles: React.Dispatch<React.SetStateAction<FileEntry[] | undefined>>;
  clipboardEnabled?: boolean; // @default true
  errors?: ErrorType[]; // upload errors
}
// TODO: fix upload retry after failures.
function DropZone(props: Props) {
  const {
    files,
    errors,
    setFiles,
    fileType,
    showThumbnails,
    dropzoneTitle,
    showSnackbar,
    clipboardEnabled,
  } = props;
  const intl = useIntl();
  const theme = useTheme();
  const thumbnails =
    fileType !== FileType.document ? files.map((item) => item.file) : [];
  const documents =
    fileType === FileType.document ? files.map((item) => item.file) : [];

  const accept =
    fileType === FileType.document
      ? ["image/*", "application/pdf"]
      : ["image/*"];

  React.useEffect(() => {
    const captureArea = document.getElementById("dropzone-focus");
    // focus the capture area when a key is pressed
    document.addEventListener("keydown", () => captureArea?.focus());

    return () =>
      document.removeEventListener("keydown", () => captureArea?.focus());
  });
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];
      const fileConstruct: FileEntry = {
        file: fileObject,
      };
      setFiles([...files, fileConstruct]);
    } else {
      showSnackbar?.(
        intl.formatMessage({ id: "error.noImageInClipboard" }),
        Severity.ERROR,
      );
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    const fileEntries = acceptedFiles.map((file) => ({ file })) ?? [];
    const newState = [...files, ...fileEntries];
    setFiles(newState);
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept });

  const activeStyle = { borderColor: theme.palette.primary.main };
  const acceptStyle = { borderColor: theme.palette.success.light };
  const rejectStyle = { borderColor: theme.palette.error.light };

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive, isDragReject, isDragAccept],
  );

  const rows: GridRowsProp = useMemo(() => {
    return (
      files?.map((item, index) => ({
        id: index + 1,
        fileName: item.file.name,
        title: item.title,
        fileSize: bytesToMb(item.file.size),
        documentType: item.documentType,
      })) ?? []
    );
  }, [files]);

  const onTypeChange = (rowIndex: number, event: SelectChangeEvent) => {
    // const fileAtIndex = files?.[rowIndex]
    // if (fileAtIndex) {
    //   const newFile = {
    //     ...fileAtIndex,
    //     documentType: event.target.value,
    //   }
    //   const newFiles = [...(files ?? [])]
    //   newFiles[rowIndex] = newFile
    //   setFiles(newFiles)

    const updatedRecords: FileEntry[] = files.map((item, index) => {
      if (index === rowIndex) {
        const documentType = event.target.value
          ? (event.target.value as unknown as DocumentType)
          : undefined;
        return { ...item, documentType } as FileEntry;
      }
      return item;
    });
    setFiles(updatedRecords);
  };

  const onRemove = (rowIndex: number) => {
    const newFiles = [...(files ?? [])];
    newFiles.splice(rowIndex, 1);
    setFiles(newFiles);
  };

  const onTitleChange = (rowIndex: Number, title: string) => {
    const updatedRecords = files.map((item, index) => {
      if (index === rowIndex) {
        return { ...item, title };
      }
      return item;
    });
    setFiles(updatedRecords);
  };

  const gridData = columns(
    onTypeChange,
    onRemove,
    onTitleChange,
    errors ?? [],
  ).filter((column) =>
    fileType !== FileType.document ? column.field !== "documentType" : true,
  );

  return (
    <Container onPaste={handlePaste}>
      <DropzoneFocus
        contentEditable={clipboardEnabled ? "true" : "false"}
        id="dropzone-focus"
      />
      <DropZoneContainer {...getRootProps()} style={style}>
        <input {...getInputProps()} />
        <Span>{isDragActive ? "📂" : "📁"}</Span>
        <H5>
          {dropzoneTitle ?? intl.formatMessage({ id: "label.drag_drop_file" })}
        </H5>
      </DropZoneContainer>
      {showThumbnails ? (
        <React.Fragment>
          <ImageThumbnails files={thumbnails} onRemove={onRemove} />
          <DocumentsList files={documents} onRemove={onRemove} />
        </React.Fragment>
      ) : (
        <DataGridWrapper>
          <DataGridComp
            sx={{ borderWidth: 0, "& td": { border: 0 } }}
            rows={rows}
            columns={gridData}
            rowsPerPageOptions={[rows.length]}
            disableSelectionOnClick
            components={{ Footer: ListFooter }}
          />
        </DataGridWrapper>
      )}
    </Container>
  );
}

DropZone.defaultProps = {
  showThumbnails: false,
  dropzoneTitle: undefined,
  showSnackbar: () => {},
  clipboardEnabled: true,
  errors: null,
};

// @ts-ignore
export default withSnackbar(DropZone);
