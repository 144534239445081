import React from "react";
import { Container, Content, PageTitle, Row } from "components/shared";
import { useIntl } from "react-intl";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridComp, DataGridText } from "components/shared";
import { USERS, SET_USER_ROLE } from "gql/user";
import { UsersData } from "types/profile";
import { useMutation, useQuery } from "@apollo/client";
import LoadingIndicator from "components/LoadingIndicator";
import { SelectChangeEvent } from "@mui/material/Select";
import { format } from "date-fns";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { UpdateRoleData, UpdateRoleInput } from "types/user";
import { UserRoles } from "types/auth";
import { RoleCell, UserCell } from "./fragments";
import { DateFormat } from "utils/datetime";

const columns = (
  onRoleChange: (userId: string, event: SelectChangeEvent) => void,
): GridColDef[] => [
  {
    field: "id",
    headerName: "#",
    width: 0,
    sortable: false,
    headerAlign: "center",
    hide: true,
  },
  {
    field: "name",
    headerName: "Name",
    width: 180,
    headerAlign: "center",
    renderCell: ({ row }) => <UserCell record={row} />,
  },

  {
    field: "email",
    headerName: "Email",
    width: 200,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
  },
  {
    field: "confirmed",
    headerName: "Confirmed",
    width: 150,
    align: "center",
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{row.value ? "YES" : "NO"}</DataGridText>
    ),
  },
  {
    field: "deletedAt",
    headerName: "Deleted at",
    headerAlign: "center",
    width: 100,
    align: "center",
    renderCell: (row) => (
      <DataGridText style={row.value ? { color: "red" } : {}}>
        {row.value ? format(new Date(row.value), DateFormat.date) || "-" : "-"}
      </DataGridText>
    ),
  },
  {
    field: "updatedAt",
    headerName: "Updated at",
    width: 160,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), DateFormat.datetime) || "-"}
      </DataGridText>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 160,

    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), DateFormat.datetime) || "-"}
      </DataGridText>
    ),
  },
  {
    field: "role",
    headerName: "Role",
    width: 150,
    renderCell: (row) => <RoleCell row={row} onChange={onRoleChange} />,
  },
];

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void;
}
function Users({ showSnackbar }: Props) {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const intl = useIntl();
  const payload = { limit: pageSize, page };
  const { data, loading } = useQuery<UsersData>(USERS, {
    variables: { payload },
  });
  const [setUserRole] = useMutation<UpdateRoleData, UpdateRoleInput>(
    SET_USER_ROLE,
    {
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      refetchQueries: [{ query: USERS, variables: { payload } }],
    },
  );

  const users = data?.users.list || [];
  const onPageChange = (_page: number) => setPage(_page);
  const onPageSizeChange = (_pageSize: number) => setPageSize(_pageSize);

  const onRoleChange = (userId: string, event: SelectChangeEvent) => {
    const { value } = event.target;
    const payloadData = { userId, role: value as UserRoles };
    setUserRole({ variables: { payload: payloadData } });
  };
  const tableColumns = columns(onRoleChange);

  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <PageTitle>
            {intl.formatMessage({ id: "label.users" })} ({data?.users.total})
          </PageTitle>
        </Row>
        <div style={{ height: "80vh" }}>
          <DataGridComp
            initialState={{ pagination: { page, pageSize } }}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={users}
            columns={tableColumns}
            rowCount={data?.users.total || 0}
            disableSelectionOnClick
            rowsPerPageOptions={[25, 50, 100]}
            paginationMode="server"
          />
        </div>
      </Content>
    </Container>
  );
}
export default withSnackbar(Users);
