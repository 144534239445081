import { isValidText } from "utils/validators";
import { ServiceType } from "types/service";
import { InputType, Type } from "components/input/types";

const publishedOptions = [
  { label: "published", value: "published" },
  { label: "unpublished", value: "unpublished" },
];

export const inputs = (serviceTypes: ServiceType[]): InputType[] => [
  {
    key: "title",
    label: "label.title",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: true,
    fullWidth: true,
    margin: "none",
    validators: [
      {
        method: isValidText,
        label: "label.required",
      },
    ],
  },
  {
    key: "description",
    label: "label.description",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  {
    key: "titleDE",
    label: "label.titleDE",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    validators: [],
  },
  {
    key: "descriptionDE",
    label: "label.descriptionDE",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  {
    key: "parentId",
    label: "label.parent",
    type: Type.AUTOCOMPLETE,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    options: serviceTypes.map((item) => ({
      label: item.title,
      value: item.id,
    })),
    validators: [],
  },
  {
    key: "published",
    label: "label.published",
    type: Type.SINGLE_SELECT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    options: publishedOptions,
    validators: [],
  },
];
