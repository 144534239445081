import { gql } from "@apollo/client";

export const USER_REQUESTS_LIST = gql`
  query USER_REQUESTS_LIST($payload: UserRequestsListInput!) {
    userRequestsList(payload: $payload) {
      total
      list {
        id
        userId
        user {
          id
          firstName
          lastName
        }
        type
        referenceNumber
        status
        message
        replies {
          id
          userId
          message
          date
          user {
            id
            firstName
            lastName
          }
        }
        referenceId
        referenceModel
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const REPLY_TO_USER_REQUEST = gql`
  mutation REPLY_TO_USER_REQUEST($payload: ReplyToRequestInput!) {
    replyToUserRequest(payload: $payload) {
      status
    }
  }
`;
