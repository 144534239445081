import { SuggestionSection } from "components/SuggestionsSection";
import { InputType, Type } from "components/input/types";
import { IntlShape } from "react-intl";
import { isPositiveNumber, isValidNumber } from "utils/validators";

export enum Section {
  extendSubscription = "extendSubscription",
}

const getExtendInputs = (intl: IntlShape): InputType[] => [
  {
    key: "duration",
    label: "label.extraDuration",

    type: Type.NUMBER,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    infoLabel: "label.subscriptionDurationInMonths",
    validators: [
      { method: isValidNumber, label: "label.required" },
      { method: isPositiveNumber, label: "label.positiveNumber" },
    ],
  },
];

export const getEditInputs = (intl: IntlShape, editId?: Section) => {
  if (!editId) return;
  switch (editId) {
    case Section.extendSubscription:
      return getExtendInputs(intl);
    default:
      return [];
  }
};

export const getInitialState = (sectionId: Section) => {
  switch (sectionId) {
    case Section.extendSubscription:
      return { duration: "1" };
    default:
      return {};
  }
};

export const editingSections = (
  intl: IntlShape,
): SuggestionSection<Section>[] => [
  {
    id: Section.extendSubscription,
    title: intl.formatMessage({ id: "label.general" }),
    description: "",
    cta: "",
  },
];

export const getSectionWithId = (id: string, intl: IntlShape) => {
  return editingSections(intl).find((section) => section.id === id);
};
