import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MAIN_ROUTES } from "./lib/navigation";
import { styled } from "@mui/material/styles";
import { useQuery } from "@apollo/client";
import { SERVER_STATUS } from "gql/app";
import LoadingIndicator from "components/LoadingIndicator";
import UnderMaintenance from "pages/static/UnderMaintenance";

export const Container = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

// TODO configure tracking
if (process.env.NODE_ENV === "production") {
  // firebase.analytics();
}
interface ServerStatus {
  serverStatus: { status: string };
}

function App() {
  const { loading, error } = useQuery<ServerStatus>(SERVER_STATUS, {
    fetchPolicy: "network-only",
  });

  if (loading) return <LoadingIndicator visible={loading} />;
  if (error) return <UnderMaintenance />;

  return (
    <Container>
      <Router>
        <Routes>
          {MAIN_ROUTES.map((item) => {
            const Element = item.component;
            return (
              <Route
                key={`route_${item.route}`}
                path={item.route}
                // @ts-expect-error
                element={<Element />}
              />
            );
          })}
        </Routes>
      </Router>
    </Container>
  );
}

export default App;
