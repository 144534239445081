import { isValidText } from "utils/validators";
import { SpaceOptions } from "types/nest";
import { InputType, Type } from "components/input/types";

export const inputs = (spaceOptions: SpaceOptions): InputType[] => [
  {
    key: "title",
    label: "label.title",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: true,
    fullWidth: true,
    margin: "none",
    validators: [
      {
        method: isValidText,
        label: "label.required",
      },
    ],
  },
  {
    key: "description",
    label: "label.description",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  {
    key: "titleDE",
    label: "label.titleDE",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    validators: [],
  },
  {
    key: "descriptionDE",
    label: "label.descriptionDE",
    type: Type.TEXT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    multiline: true,
    validators: [],
  },
  {
    key: "featuresOptions",
    label: "label.applicableFeatures",
    type: Type.MULTI_SELECT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    useTranslation: true,
    fullWidth: true,
    options:
      spaceOptions.featuresOptions.map((input) => ({
        value: input,
        label: input.toLocaleLowerCase(),
      })) || [],
    validators: [],
  },

  {
    key: "securityFeaturesOptions",
    label: "label.applicableSecurityFeatures",
    type: Type.MULTI_SELECT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    useTranslation: true,
    fullWidth: true,
    options:
      spaceOptions.securityFeaturesOptions.map((input) => ({
        value: input,
        label: input.toLocaleLowerCase(),
      })) || [],
    validators: [],
  },

  {
    key: "spaceUsageOptions",
    label: "label.applicableSpaceUsage",
    type: Type.MULTI_SELECT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    useTranslation: true,
    options:
      spaceOptions.spaceUsageOptions.map((input) => ({
        value: input,
        label: input.toLocaleLowerCase(),
      })) || [],
    validators: [],
  },

  {
    key: "elevationOptions",
    label: "label.applicableElevation",
    type: Type.MULTI_SELECT,
    autoComplete: "",
    autoFocus: false,
    margin: "dense",
    fullWidth: true,
    useTranslation: true,
    options:
      spaceOptions.elevationOptions.map((input) => ({
        value: input,
        label: input.toLocaleLowerCase(),
      })) || [],
    validators: [],
  },
];
