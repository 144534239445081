import React, { useState } from "react";
import { useIntl } from "react-intl";
import InputRender, { formHasErrors } from "components/input/InputRender";
import { Button, DrawerForm, ModalDrawer, ModalTitle } from "components/shared";
import { useMutation, useQuery } from "@apollo/client";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { inputs } from "./constants";
import {
  CREATE_NEST_TYPE,
  NEST_TYPES,
  SPACE_AVAILABLE_OPTIONS,
} from "gql/nest";
import {
  CreateNestTypeData,
  CreateNestTypeInput,
  SpaceAvailableOptionsData,
} from "types/nest";
import { FormDataType } from "components/input/types";

interface ContentProps {
  open: boolean;
  toggleDrawer: () => void;
  showSnackbar?: (message: string, severity: Severity) => void;
}

const initialState: FormDataType = {
  title: "",
  description: "",
  titleDE: "",
  descriptionDE: "",
  featuresOptions: [],
  securityFeaturesOptions: [],
  spaceUsageOptions: [],
  elevationOptions: [],
};
function CreateDrawer({ open, toggleDrawer, showSnackbar }: ContentProps) {
  const intl = useIntl();
  const [showErrors, setShowErrors] = useState(false);
  const [data, setData] = useState<FormDataType>(initialState);

  const onComplete = () => {
    toggleDrawer();
    setData(initialState);
  };

  const { data: options } = useQuery<SpaceAvailableOptionsData>(
    SPACE_AVAILABLE_OPTIONS,
  );

  const spaceOptions = options?.spaceAvailableOptions;

  const [createNesType] = useMutation<CreateNestTypeData, CreateNestTypeInput>(
    CREATE_NEST_TYPE,
    {
      onCompleted: onComplete,
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      refetchQueries: [{ query: NEST_TYPES, variables: { includeAll: true } }],
    },
  );

  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!spaceOptions) return;
    if (formHasErrors(inputs(spaceOptions), data)) {
      setShowErrors(true);
      return;
    }
    const payload = {
      title: data.title as string,
      description: (data.description as string) || undefined,
      titleDE: (data.titleDE as string) || undefined,
      descriptionDE: (data.descriptionDE as string) || undefined,
      featuresOptions: data.featuresOptions as string[],
      securityFeaturesOptions: data.securityFeaturesOptions as string[],
      spaceUsageOptions: data.spaceUsageOptions as string[],
      elevationOptions: data.elevationOptions as string[],
    };
    createNesType({ variables: { payload } });
  };

  return (
    <ModalDrawer anchor="right" open={open} onClose={toggleDrawer}>
      <ModalTitle p={2}>
        {intl.formatMessage({ id: "label.createNestType" })}
      </ModalTitle>
      <DrawerForm noValidate onSubmit={handleOnsubmit}>
        {spaceOptions &&
          inputs(spaceOptions).map((input) => {
            return (
              <InputRender
                key={input.key}
                data={data}
                input={input}
                inputs={inputs(spaceOptions)}
                setData={setData}
                showErrors={showErrors}
              />
            );
          })}
        <Button type="submit" fullWidth variant="contained" color="primary">
          {intl.formatMessage({ id: "label.create" })}
        </Button>
      </DrawerForm>
    </ModalDrawer>
  );
}

CreateDrawer.defaultProps = {
  showSnackbar: () => {},
};

export default withSnackbar(CreateDrawer);
