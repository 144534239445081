import React, { useState } from "react";
import {
  ActionCell,
  Container,
  Content,
  PageTitle,
  Row,
} from "components/shared";
import { useIntl } from "react-intl";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridComp, DataGridText } from "components/shared";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/LoadingIndicator";
import { format } from "date-fns";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddSharp";
import { NEST_TYPES } from "gql/nest";
import { NestType, NestTypeData } from "types/nest";
import CreateDrawer from "./CreateDrawer";
import EditDrawer from "./EditDrawer";

const columns = (onEdit: (data: NestType) => void): GridColDef[] => [
  {
    field: "actions",
    headerName: "Actions",
    width: 100,
    headerAlign: "center",
    renderCell: (row) => <ActionCell rowData={row} onEdit={onEdit} />,
  },
  {
    field: "id",
    headerName: "Id",
    width: 200,
    sortable: false,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
    hide: true,
  },
  {
    field: "title",
    headerName: "Title",
    width: 200,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
  },
  {
    field: "deletedAt",
    headerName: "Deleted at",
    width: 120,
    renderCell: (row) => (
      <DataGridText style={row.value ? { color: "red" } : {}}>
        {row.value ? format(new Date(row.value), "dd/MM/yyyy") || "-" : "-"}
      </DataGridText>
    ),
  },
  {
    field: "featuresOptions",
    headerName: "Feature Options",
    width: 400,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{row.value.join(", ").toLowerCase()}</DataGridText>
    ),
  },
  {
    field: "securityFeaturesOptions",
    headerName: "Security Feature Options",
    width: 400,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{row.value.join(", ").toLowerCase()}</DataGridText>
    ),
  },
  {
    field: "elevationOptions",
    headerName: "Elevation Options",
    width: 400,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{row.value.join(", ").toLowerCase()}</DataGridText>
    ),
  },
  {
    field: "spaceUsageOptions",
    headerName: "Space Usage Options",
    width: 400,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{row.value.join(", ").toLowerCase()}</DataGridText>
    ),
  },

  {
    field: "updatedAt",
    headerName: "Updated at",
    width: 200,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), "dd/MM/yyyy HH:mm:ss") || "-"}
      </DataGridText>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 200,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), "dd/MM/yyyy HH:mm:ss") || "-"}
      </DataGridText>
    ),
  },
];

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void;
}
function NestTypes({ showSnackbar }: Props) {
  // const [page, setPage] = useState(0);
  // const [pageSize, setPageSize] = useState(25);
  const [createOpen, setCreateOpen] = useState(false);
  const [editNestType, setEditNestType] = useState<NestType>(); // used also as a flag to show the drawer

  const intl = useIntl();
  const { data, loading } = useQuery<NestTypeData>(NEST_TYPES, {
    variables: { includeAll: true },
  });
  const nestTypes = data?.nestTypes.list || [];

  // const onPageChange = (_page: number) => setPage(_page);
  // const onPageSizeChange = (_pageSize: number) => setPageSize(_pageSize);
  const toggleCreateDrawer = () => setCreateOpen(!createOpen);
  const toggleEditDrawer = () => setEditNestType(undefined);
  const handleOnEdit = (nestType: NestType) => setEditNestType(nestType);
  const tableColumns = columns(handleOnEdit);

  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <PageTitle>
            {intl.formatMessage({ id: "label.nestTypes" })} (
            {data?.nestTypes.total ?? 0})
          </PageTitle>
          <Button onClick={toggleCreateDrawer}>
            <AddIcon />
          </Button>
        </Row>
        <div style={{ height: "80vh" }}>
          <DataGridComp
            // initialState={{ pagination: { page, pageSize } }}
            // onPageChange={onPageChange}
            // onPageSizeChange={onPageSizeChange}
            rows={nestTypes}
            columns={tableColumns}
            // rowCount={data?.nestTypes.total ?? 0}
            disableSelectionOnClick
            hideFooter
            rowsPerPageOptions={[data?.nestTypes.total ?? 0]}
            // rowsPerPageOptions={[25, 50, 100]}
            // paginationMode="server"
          />
        </div>
      </Content>
      <CreateDrawer open={createOpen} toggleDrawer={toggleCreateDrawer} />
      {editNestType && (
        <EditDrawer
          open={!!editNestType}
          nestType={editNestType}
          toggleDrawer={toggleEditDrawer}
        />
      )}
    </Container>
  );
}
export default withSnackbar(NestTypes);
