import React from "react";

export const linkStyle: React.CSSProperties = {
  textTransform: "none",
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0em",
  textAlign: "center",
  color: "#707070",
  fontFamily: "NoirPro",
};

export const buttonLinkStyle: React.CSSProperties = {
  textTransform: "none",
  backgroundColor: "#83C9C7",
  color: "#F9F9F9",
  fontSize: "15px",
  lineHeight: "17px",
  padding: "14px 20px 14px 20px",
  borderRadius: "104px",
  fontFamily: "NoirPro",
  boxShadow: "inset -2px 0px 18px -3px #00000026, 0px 7px 25px 0px #0000001A",
};

export const buttonDarkLinkStyle: React.CSSProperties = {
  textTransform: "none",
  backgroundColor: "#707070",
  color: "#F9F9F9",
  fontSize: "15px",
  lineHeight: "17px",
  padding: "10px 20px 10px 20px",
  borderRadius: "104px",
  fontFamily: "NoirPro",
};
