import { useMutation, useQuery } from "@apollo/client";
import { Severity } from "components/providers/SnackbarHOC";
import {
  ADMIN_DELETE_SUBSCRIPTION,
  ADMIN_EXTEND_SUBSCRIPTION,
  ORGANIZATION_ADMIN_DATA,
} from "gql/organization";
import {
  AdminDeleteSubscriptionData,
  AdminDeleteSubscriptionInput,
  AdminExtendSubscriptionData,
  AdminExtendSubscriptionInput,
  OrganizationAdminDataQuery,
} from "types/organization";

export function useData(organizationId: string) {
  const { data: orgData, loading: orgLoading } =
    useQuery<OrganizationAdminDataQuery>(ORGANIZATION_ADMIN_DATA, {
      variables: { organizationId },
    });

  const organization = orgData?.organizationAdminData;
  const loading = orgLoading;
  return {
    loading,
    data: { organization },
  };
}

export function useMutations(
  organizationId: string,
  methods: {
    showSnackbar?: (message: string, severity: Severity) => void;
    hideEditingModal: () => void;
  }
) {
  const [adminExtendSubscription, { loading }] = useMutation<
    AdminExtendSubscriptionData,
    AdminExtendSubscriptionInput
  >(ADMIN_EXTEND_SUBSCRIPTION, {
    onCompleted: () => methods.hideEditingModal(),
    onError: (error) => methods.showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      { query: ORGANIZATION_ADMIN_DATA, variables: { organizationId } },
    ],
  });

  const [adminDeleteSubscription, { loading: deleteLoading }] = useMutation<
    AdminDeleteSubscriptionData,
    AdminDeleteSubscriptionInput
  >(ADMIN_DELETE_SUBSCRIPTION, {
    onCompleted: (response) =>
      methods.showSnackbar?.(
        response.adminDeleteSubscription.status,
        Severity.SUCCESS
      ),
    onError: (error) => methods.showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [
      { query: ORGANIZATION_ADMIN_DATA, variables: { organizationId } },
    ],
  });

  return {
    loading: loading || deleteLoading,
    mutations: { adminExtendSubscription, adminDeleteSubscription },
  };
}
