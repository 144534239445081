import React from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Category } from "types/category";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/icons-material/EditSharp";
import Chip from "@mui/material/Chip";
import { format } from "date-fns";

export const ContainerGrid = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
});

export const GridItem = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minHeight: "300px",
  justifyContent: "flex-start",
  backgroundColor: theme.palette.background.default,
  border: "1px solid #0000001A",
  borderRadius: "4px",
}));

export const PagerColumn = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.paper,
  margin: "30px",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "NoirPro",
  fontWeight: 400,
  fontSize: "16px",
  color: `${theme.palette.text.primary}`,
  borderBottom: "1px solid #0000001A",
  paddingBottom: "4px",
}));

const ContainerCard = styled(Card)({
  display: "flex",
  margin: "5px 10px 0 0",
  padding: "8px 0 8px 4px",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const ActionsContainer = styled(CardActions)({
  display: "flex",
  padding: "0px",
});

const CardTitle = styled(Typography)({
  fontFamily: "NoirPro",
  fontWeight: 400,
  fontSize: "14px",
});

const ContentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});
const LabelChip = styled(Chip)(({ theme }) => ({
  margin: "0px",
  padding: "0px",
  fontFamily: "NoirPro",
  fontSize: "10px",
  color: theme.palette.background.paper,
  height: "16px",
  marginTop: "4px",
}));

interface Props {
  category: Category;
  focused?: boolean;
  onClick: (category: Category) => void;
  onInfoClick: (category: Category) => void;
}

export function CategoryCard({
  category,
  focused,
  onClick,
  onInfoClick,
}: Props) {
  const theme = useTheme();
  const handleClick = () => onClick(category);
  const handleInfoClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onInfoClick(category);
  };

  const style = focused
    ? { borderColor: theme.palette.primary.main, borderWidth: "2px" }
    : {};

  return (
    <ContainerCard variant="outlined" style={style} onClick={handleClick}>
      <ContentWrapper>
        <CardTitle>{category.title}</CardTitle>
        {!category.published && (
          <LabelChip label="unpublished" color="warning" />
        )}
        {category.deletedAt && (
          <LabelChip
            label={
              category?.deletedAt
                ? format(new Date(category?.deletedAt), "dd/MM/yyyy")
                : "-"
            }
            color="error"
          />
        )}
      </ContentWrapper>
      <ActionsContainer disableSpacing>
        <IconButton aria-label="remove" size="small" onClick={handleInfoClick}>
          <Icon fontSize="small" />
        </IconButton>
      </ActionsContainer>
    </ContainerCard>
  );
}

CategoryCard.defaultProps = {
  focused: false,
};
