import { isValid, parse } from "date-fns";
import { DateFormat, getDate } from "utils/datetime";
import { FormDataType } from "components/input/types";

export function isValidText<T = string>(text?: T): boolean {
  return typeof text === "string" && (text?.trim?.().length ?? 0) > 0;
}

export const isValidNumber = <T = string>(text?: T): boolean =>
  typeof text === "string" && (text?.length ?? 0) > 0 && !isNaN(Number(text));

// only check if a number is provided, default to true
export const isPositiveNumber = <T = string>(text?: T): boolean =>
  typeof text === "string" &&
  ((text?.trim()?.length ?? 0) > 0
    ? !isNaN(Number(text)) && Number(text) >= 0
    : true);

// only check if a email is provided, default to true
export const isValidEmail = <T = string>(email?: T): boolean =>
  email && typeof email === "string"
    ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    : true;

export const isValidPassword = <T = string>(password: T): boolean =>
  typeof password === "string" && password.length > 5;

export const isValidOption = <T = string>(
  option: T,
  _formData?: FormDataType,
  options?: {
    value: T;
    label: string;
  }[],
): boolean => !!options && options?.some((opt) => opt.value === option);

export const isNotValidOption = <T = string>(
  option: T,
  _formData?: FormDataType,
  options?: {
    value: T;
    label: string;
  }[],
): boolean => !!options && !options?.some((opt) => opt.value === option);

export const isValidUrl = (text: string): boolean => {
  let url;
  try {
    url = new URL(text);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export function isValidDate<T = string>(dateStr?: T) {
  if (!dateStr || typeof dateStr !== "string") return false;
  try {
    return (
      isValid(parse(dateStr, DateFormat.date, new Date())) ||
      isValid(parse(getDate(new Date(dateStr)), DateFormat.date, new Date()))
    );
  } catch (error) {
    // Logger.logException(error);
    return false;
  }
}
