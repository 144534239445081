import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

function LoadingIndicator({ visible = false }) {
  if (!visible) {
    return null;
  }
  return (
    <Box sx={{ width: "100%", position: "absolute", zIndex: 10 }}>
      <LinearProgress color="primary" />
    </Box>
  );
}

export default LoadingIndicator;
