import { gql } from "@apollo/client";

export const CATEGORIES = gql`
  query CATEGORIES($includeAll: Boolean!) {
    categories(includeAll: $includeAll) {
      id
      slug
      title
      description
      published
      titleDE
      descriptionDE
      parentId
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CREATE_CATEGORY($payload: CreateCategoryInput!) {
    createCategory(payload: $payload) {
      id
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DELETE_CATEGORY($categoryId: ID!) {
    deleteCategory(categoryId: $categoryId) {
      status
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UPDATE_CATEGORY($payload: UpdateCategoryInput!) {
    updateCategory(payload: $payload) {
      id
    }
  }
`;
