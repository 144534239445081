import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { clearSession, getAuth } from "lib/localStorage";
import { hasSessionExpired } from "lib/errorResolver";
import appConfig from "config/app";

const httpLink = createHttpLink({ uri: appConfig.graphql });
const authLink = setContext(({ variables }, { headers }) => {
  const auth = getAuth();
  return {
    headers: {
      ...headers,
      authorization: auth?.token ? `Bearer ${auth.token}` : "",
    },
  };
});

const errorLink = onError((error) => {
  const { graphQLErrors, networkError } = error;
  if (hasSessionExpired(error)) {
    // TODO: might need to reset apollo store
    clearSession();
  }
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  // eslint-disable-next-line
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});

interface Props {
  children: React.ReactNode;
}
function GraphqlProvider({ children }: Props) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default GraphqlProvider;
