import { gql } from "@apollo/client";

export const QUESTIONNAIRE_SETUP_OPTIONS = gql`
  query QUESTIONNAIRE_SETUP_OPTIONS {
    questionnaireSetupOptions {
      questionTypes
      questionnaireTypes
      questionsWithOptions
    }
  }
`;
