import { Theme } from "@mui/material/styles";
import {
  UserRequestExtended,
  UserRequestStatus,
  UserRequestType,
} from "types/userRequest";

export function getStatusColor(status: UserRequestStatus, theme: Theme) {
  switch (status) {
    case UserRequestStatus.CLOSED:
      return theme.palette.shared.red;
    case UserRequestStatus.OPEN:
      return theme.palette.text.primary;
    case UserRequestStatus.REJECTED:
      return theme.palette.text.disabled;
    default:
      return theme.palette.primary.main;
  }
}

export function getTypeColor(type: UserRequestType, theme: Theme) {
  switch (type) {
    case UserRequestType.INQUIRY:
      return theme.palette.primary.main;
    case UserRequestType.CLAIM:
      return theme.palette.shared.red;
    default:
      return theme.palette.grey[700];
  }
}

export const getUserRequest = (
  requestId: string,
  requests: UserRequestExtended[],
) => requests.find((req) => req.id === requestId);
