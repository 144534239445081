import React from "react";
import { Container, Content, PageTitle, Row } from "components/shared";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/LoadingIndicator";
import Divider from "@mui/material/Divider";

import Grid from "@mui/material/Grid";
import { QUESTIONNAIRE_SETUP_OPTIONS } from "gql/questionnaire";
import {
  QuestionnaireFormData,
  QuestionnaireSetupOptionsData,
} from "types/questionnaire";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import MuiButton from "@mui/material/Button";
import InputRender from "components/input/InputRender";
import { DeleteForever } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Type } from "components/input/types";

const ButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: "5px",
}));

const Button = styled(MuiButton)(({ theme }) => ({
  fontSize: "14px",
  borderRadius: "104px",
}));

function CreateQuestionnaire() {
  const intl = useIntl();
  const theme = useTheme();
  const [focusedStep, setFocusedStep] = React.useState(0);
  const [formData, setFormData] = React.useState<QuestionnaireFormData>({
    title: "",
    subtitle: "",
    steps: [
      {
        title: "",
        subtitle: "",
      },
    ],
  });

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: options, loading } = useQuery<QuestionnaireSetupOptionsData>(
    QUESTIONNAIRE_SETUP_OPTIONS,
  );
  const {
    questionTypes,
    // questionnaireTypes
  } = options?.questionnaireSetupOptions ?? {};

  const addStep = () => {
    setFormData({
      ...formData,
      steps: [...(formData?.steps ?? []), { title: "", subtitle: "" }],
    });
  };

  const removeStep = (index: number) => {
    setFormData({
      ...formData,
      steps: formData?.steps?.filter((_, i) => i !== index),
    });
  };

  const addQuestionToStep = (type: string) => {
    setFormData({
      ...formData,
      steps: formData?.steps?.map((step, i) => {
        if (i === focusedStep) {
          return {
            ...step,
            questions: [
              ...(step?.questions ?? []),
              {
                key: `${type}-question-${Math.random()}`,
                question: "",
                type,
              },
            ],
          };
        }
        return step;
      }),
    });
  };

  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <PageTitle>
            {intl.formatMessage({ id: "label.createQuestionnaire" })}
          </PageTitle>
        </Row>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <InputRender
              key="title"
              data={{ title: formData.title as string }}
              input={{
                key: "title",
                label: "label.title",
                type: Type.TEXT,
                margin: "none",
                autoComplete: "",
                fullWidth: true,
                validators: [],
              }}
              showErrors={false}
              inputs={[]}
              setData={(data) => {
                setFormData({ ...formData, title: data.title as string });
              }}
            />
            <InputRender
              key="subtitle"
              data={{ subtitle: formData.subtitle as string }}
              input={{
                key: "subtitle",
                label: "label.subtitle",
                type: Type.TEXT,
                margin: "none",
                autoComplete: "",
                fullWidth: true,
                validators: [],
                multiline: true,
              }}
              showErrors={false}
              inputs={[]}
              setData={(data) => {
                setFormData({ ...formData, subtitle: data.subtitle as string });
              }}
            />
            {formData.steps?.map((step, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    onClick={() => setFocusedStep(index)}
                    style={{
                      margin: "12px 0",
                      padding: "20px 10px ",
                      borderRadius: "4px",
                      boxShadow: `0px 0px 11px 0px ${
                        focusedStep === index
                          ? `${theme.palette.primary.main}`
                          : "#0000001A"
                      }`,
                    }}
                  >
                    <Row>
                      <p>Step {index + 1}</p>
                      <IconButton onClick={() => removeStep(index)}>
                        <DeleteForever color="primary" />
                      </IconButton>
                    </Row>
                    <InputRender
                      data={{ title: step.title as string }}
                      input={{
                        key: `title`,
                        label: "label.title",
                        type: Type.TEXT,
                        margin: "none",
                        autoComplete: "",
                        fullWidth: true,
                        validators: [],
                      }}
                      showErrors={false}
                      inputs={[]}
                      setData={(data) => {
                        const updatedSteps = [...(formData?.steps ?? [])];
                        updatedSteps[index] = {
                          ...updatedSteps[index],
                          ...data,
                        };
                        setFormData({
                          ...formData,
                          steps: updatedSteps,
                        });
                      }}
                    />
                    <InputRender
                      data={{ subtitle: step.subtitle as string }}
                      input={{
                        key: `subtitle`,
                        label: "label.subtitle",
                        type: Type.TEXT,
                        margin: "none",
                        autoComplete: "",
                        fullWidth: true,
                        validators: [],
                        multiline: true,
                      }}
                      showErrors={false}
                      inputs={[]}
                      setData={(data) => {
                        const updatedSteps = [...(formData?.steps ?? [])];
                        updatedSteps[index] = {
                          ...updatedSteps[index],
                          ...data,
                        };
                        setFormData({
                          ...formData,
                          steps: updatedSteps,
                        });
                      }}
                    />
                    <div>
                      <span>Questions:</span>
                      {step.questions?.map((question) => {
                        return (
                          <div
                            key={question.key}
                            style={{
                              margin: "10px 20px 0px 20px",
                              padding: "10px",
                              boxShadow: `0px 0px 11px 0px #0000001A`,
                            }}
                          >
                            <p>{question.key}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <Divider />
                </React.Fragment>
              );
            })}
            <ButtonWrapper>
              <Button onClick={addStep}>
                <AddIcon />
                <span>Add Step</span>
              </Button>
            </ButtonWrapper>
          </Grid>
          {!isMobileScreen && (
            <Grid item sm={3}>
              {questionTypes?.map((questionType) => (
                <ButtonWrapper key={questionType}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => addQuestionToStep(questionType)}
                  >
                    <AddIcon />
                    {questionType.toLowerCase().replaceAll("_", " ")}
                  </Button>
                </ButtonWrapper>
              ))}
            </Grid>
          )}
        </Grid>
      </Content>
    </Container>
  );
}
export default CreateQuestionnaire;
