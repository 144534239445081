import React, { useState } from "react";
import {
  ActionCell,
  Container,
  Content,
  PageTitle,
  Row,
} from "components/shared";
import { useIntl } from "react-intl";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridComp, DataGridText } from "components/shared";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/LoadingIndicator";
import { format } from "date-fns";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddSharp";
import CreateDrawer from "./CreateDrawer";
import EditDrawer from "./EditDrawer";
import { PROVIDER_CATEGORIES } from "gql/provider-category";
import {
  ProviderCategoriesData,
  ProviderCategory,
} from "types/provider-category";

const columns = (onEdit: (data: ProviderCategory) => void): GridColDef[] => [
  {
    field: "actions",
    headerName: "Actions",
    width: 100,
    headerAlign: "center",
    renderCell: (row) => <ActionCell rowData={row} onEdit={onEdit} />,
  },
  {
    field: "id",
    headerName: "Id",
    width: 200,
    sortable: false,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
    hide: true,
  },
  {
    field: "title",
    headerName: "Title",
    width: 200,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
  },

  {
    field: "published",
    headerName: "Published",
    width: 150,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{row.value ? "Published" : "Unpublished"}</DataGridText>
    ),
  },
  {
    field: "deletedAt",
    headerName: "Deleted at",
    width: 120,
    renderCell: (row) => (
      <DataGridText style={row.value ? { color: "red" } : {}}>
        {row.value ? format(new Date(row.value), "dd/MM/yyyy") || "-" : "-"}
      </DataGridText>
    ),
  },
  {
    field: "updatedAt",
    headerName: "Updated at",
    width: 200,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), "dd/MM/yyyy HH:mm:ss") || "-"}
      </DataGridText>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 200,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), "dd/MM/yyyy HH:mm:ss") || "-"}
      </DataGridText>
    ),
  },
];

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void;
}
function ProviderCategories({ showSnackbar }: Props) {
  const [createOpen, setCreateOpen] = useState(false);
  const [editingRecord, setEditingRecord] = useState<ProviderCategory>(); // used also as a flag to show the drawer
  const intl = useIntl();
  const { data: categoriesData, loading } = useQuery<ProviderCategoriesData>(
    PROVIDER_CATEGORIES,
    { variables: { includeAll: true } },
  );
  const categories = categoriesData?.providerCategories ?? [];

  const toggleCreateDrawer = () => setCreateOpen(!createOpen);
  const toggleEditDrawer = () => setEditingRecord(undefined);
  const handleOnEdit = (record: ProviderCategory) => setEditingRecord(record);
  const tableColumns = columns(handleOnEdit);

  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <PageTitle>
            {intl.formatMessage({ id: "label.serviceProviderCategories" })} (
            {categories?.length ?? 0})
          </PageTitle>
          <Button onClick={toggleCreateDrawer}>
            <AddIcon />
          </Button>
        </Row>
        <div style={{ height: "80vh" }}>
          <DataGridComp
            rows={categories}
            columns={tableColumns}
            disableSelectionOnClick
            hideFooter
            rowsPerPageOptions={[categories.length]}
          />
        </div>
      </Content>
      <CreateDrawer open={createOpen} toggleDrawer={toggleCreateDrawer} />
      {editingRecord && (
        <EditDrawer
          open={!!editingRecord}
          record={editingRecord}
          toggleDrawer={toggleEditDrawer}
        />
      )}
    </Container>
  );
}
export default withSnackbar(ProviderCategories);
