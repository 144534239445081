import React from "react";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { GridRenderCellParams } from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiRemoveIcon from "@mui/icons-material/Remove";
import { DocumentType } from "types/archive";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import { Row } from "components/shared";
import Card from "@mui/material/Card";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { ErrorType } from "hooks/api/fileUpload";
import Tooltip from "@mui/material/Tooltip";

export const Container = styled("div")(({ theme }) => ({}));

export const DropzoneFocus = styled("div")(({ theme }) => ({
  height: 1,
  width: 1,
  overflow: "hidden",
  opacity: 0,
}));

export const DropZoneContainer = styled("div")(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette.background.default,
  borderRadius: "4px",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "40px",
  border: `2px dashed ${theme.palette.action.active}`,
}));

export const Span = styled("span")(({ theme }) => ({
  fontSize: "20px",
}));

export const DataGridWrapper = styled("div")(({ theme }) => ({
  height: "50vh",
  marginTop: "12px",
}));

export const Icon = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.error.main,
  margin: "0 auto",
}));

interface RemoveIconProps {
  rowData: GridRenderCellParams;
  onRemove: (rowIndex: number) => void;
}
export function RemoveIcon({ rowData, onRemove }: RemoveIconProps) {
  const handleOnClick = () => onRemove(Number(rowData.id) - 1);
  return (
    <IconButton onClick={handleOnClick}>
      <Icon />
    </IconButton>
  );
}

interface StatusIconProps {
  rowData: GridRenderCellParams;
  errors: ErrorType[];
}
export function StatusIcon({ rowData, errors }: StatusIconProps) {
  const rowIndex = ((rowData?.id as number) ?? 0) - 1;
  const rowError = errors.find((error) => error.index === rowIndex);
  return (
    <IconButton>
      {rowError && (
        <Tooltip title={rowError.error.message}>
          <ErrorOutlineIcon color="error" />
        </Tooltip>
      )}
      {!rowError && <CheckIcon color="primary" />}
    </IconButton>
  );
}
interface TypeProps {
  rowData: GridRenderCellParams;
  onChange: (rowIndex: number, event: SelectChangeEvent) => void;
}

export function DocumentTypeCell({ rowData, onChange }: TypeProps) {
  const intl = useIntl();
  const handleOnChange = (event: SelectChangeEvent) =>
    onChange?.(Number(rowData.id) - 1, event);
  const options = Object.values(DocumentType);

  return (
    <FormControl fullWidth>
      <InputLabel>-</InputLabel>
      {/* @ts-ignore */}
      <Select value={rowData.value ?? ""} label="-" onChange={handleOnChange}>
        {options.map((type) => (
          <MenuItem key={type} value={type}>
            {intl.formatMessage({
              id: `static.${type}`,
              defaultMessage: type,
            })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function ListFooter() {
  return <Box />;
}

const ThumbnailContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  padding: "10px 20px",
  justifyContent: "flex-start",
}));

const ThumbnailBox = styled(Box)(({ theme }) => ({
  borderRadius: "4px",
  overflow: "hidden",
  marginRight: "4px",
  position: "relative",
  ":hover": {
    button: {
      display: "flex",
    },
  },
}));

const Thumbnail = styled("img")(({ theme }) => ({
  width: "60px",
  height: "60px",
  objectFit: "cover",
  overflow: "hidden",
}));

const RemoveThumbnail = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "2px",
  right: "2px",
  backgroundColor: theme.palette.error.main,
  width: "16px",
  height: "16px",
  display: "none",
}));

const RemoveIconThumbnail = styled(MuiRemoveIcon)(({ theme }) => ({
  width: "14px",
  height: "14px",
  color: theme.palette.background.default,
}));

// ------------------------------------------------------------------
//  Image thumbnails
// ------------------------------------------------------------------

interface ThumbnailProps {
  file: File;
  onRemove: () => void;
}
function ThumbnailCard({ file, onRemove }: ThumbnailProps) {
  return (
    <ThumbnailBox>
      <Thumbnail src={URL.createObjectURL(file)} />
      {onRemove && (
        <RemoveThumbnail onClick={onRemove}>
          <RemoveIconThumbnail />
        </RemoveThumbnail>
      )}
    </ThumbnailBox>
  );
}

interface ThumbnailsProps {
  files: File[];
  onRemove?: (index: number) => void;
}

export function ImageThumbnails({ files, onRemove }: ThumbnailsProps) {
  return (
    <ThumbnailContainer>
      {files.map((file, index) => {
        const handleOnRemove = () => onRemove?.(index);
        return (
          <ThumbnailCard
            key={JSON.stringify(file)}
            file={file}
            onRemove={handleOnRemove}
          />
        );
      })}
    </ThumbnailContainer>
  );
}

ImageThumbnails.defaultProps = {
  onRemove: undefined,
};

// ------------------------------------------------------------------
//  Documents list
// ------------------------------------------------------------------

const DocumentCard = styled(Card)(({ theme }) => ({
  boxShadow: "0px 0px 21px 1px #00000012",
  display: "flex",
  margin: 0,
}));

function PdfDoc({ file, onRemove }: ThumbnailProps) {
  return (
    <ThumbnailBox style={{ width: "80px" }}>
      <DocumentCard>
        {/* @ts-ignore */}
        <Document file={file} renderMode="svg">
          {/* @ts-ignore */}
          <Page pageNumber={1} width={80} />
        </Document>
      </DocumentCard>
      {onRemove && (
        <RemoveThumbnail onClick={onRemove}>
          <RemoveIconThumbnail />
        </RemoveThumbnail>
      )}
    </ThumbnailBox>
  );
}

interface DocumentListProps {
  files: File[];
  onRemove?: (index: number) => void;
}

export function DocumentsList({ files, onRemove }: DocumentListProps) {
  return (
    <Row>
      {files.map((file, index) => {
        const isPdfDocument = file.name?.toLowerCase().endsWith(".pdf");
        const handleOnRemove = () => onRemove?.(index);
        return isPdfDocument ? (
          <PdfDoc key={file.name} file={file} onRemove={handleOnRemove} />
        ) : (
          <ThumbnailCard file={file} onRemove={handleOnRemove} />
        );
      })}
    </Row>
  );
}

DocumentsList.defaultProps = {
  onRemove: undefined,
};
