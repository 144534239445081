import { InputType, Type } from "components/input/types";
import { isValidEmail, isValidText } from "utils/validators";

export const inputs: InputType[] = [
  {
    key: "email",
    label: "label.emailAddress",
    type: Type.EMAIL,
    autoComplete: "email",
    autoFocus: true,
    fullWidth: true,
    margin: "none",
    validators: [
      {
        method: isValidText,
        label: "label.required",
      },
      {
        method: isValidEmail,
        label: "label.invalidEmail",
      },
    ],
  },
  {
    key: "password",
    label: "label.password",
    type: Type.PASSWORD,
    autoComplete: "current-password",
    autoFocus: false,
    fullWidth: true,
    margin: "normal",
    validators: [
      {
        method: isValidText,
        label: "label.required",
      },
    ],
  },
];
