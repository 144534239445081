import React, { useState } from "react";
import { Container, Content, PageTitle, Row } from "components/shared";
import { useIntl } from "react-intl";
import { DataGridComp } from "components/shared";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/LoadingIndicator";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import AddIcon from "@mui/icons-material/AddSharp";
import {
  SubscriptionPlan,
  SubscriptionPlanData,
  SubscriptionPlanInput,
} from "types/subscription";
import { Button } from "@mui/material";
import { SUBSCRIPTION_PLANS } from "gql/subscription";
import CreateDrawer from "./CreateDrawer";
import { columns } from "./constants";
import EditDrawer from "./EditDrawer";

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void;
}
function Workspaces({ showSnackbar }: Props) {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const intl = useIntl();
  const [createOpen, setCreateOpen] = useState(false);
  const [editRecord, setEditRecord] = useState<SubscriptionPlan>(); // used also as a flag to show the drawer

  const { data, loading } = useQuery<
    SubscriptionPlanData,
    SubscriptionPlanInput
  >(SUBSCRIPTION_PLANS, {
    variables: { payload: { includeAll: true } },
  });

  const records = data?.subscriptionPlans.list || [];
  const onPageChange = (_page: number) => setPage(_page);
  const onPageSizeChange = (_pageSize: number) => setPageSize(_pageSize);
  const toggleCreateDrawer = () => setCreateOpen(!createOpen);
  const toggleEditDrawer = () => setEditRecord(undefined);
  const handleOnEdit = (record: SubscriptionPlan) => setEditRecord(record);
  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <PageTitle>
            {intl.formatMessage({ id: "label.subscriptionPlans" })} (
            {data?.subscriptionPlans.total ?? 0})
          </PageTitle>
          <Button onClick={toggleCreateDrawer}>
            <AddIcon />
          </Button>
        </Row>
        <div style={{ height: "80vh" }}>
          <DataGridComp
            initialState={{ pagination: { page, pageSize } }}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={records}
            columns={columns(handleOnEdit, intl)}
            rowCount={data?.subscriptionPlans.total || 0}
            disableSelectionOnClick
            rowsPerPageOptions={[25, 50, 100]}
            paginationMode="server"
          />
        </div>
      </Content>
      <CreateDrawer open={createOpen} toggleDrawer={toggleCreateDrawer} />
      {editRecord && (
        <EditDrawer
          open={!!editRecord}
          record={editRecord}
          toggleDrawer={toggleEditDrawer}
        />
      )}
    </Container>
  );
}
export default withSnackbar(Workspaces);
