import React from "react";
import { useTheme } from "@mui/material/styles";

interface Props {
  color?: string;
  width?: string;
}
function Svg({ color, width }: Props) {
  const theme = useTheme();
  return (
    <div style={{ width, display: "flex" }}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22Z"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.9627 8.13745C23.2794 8.42912 23.5794 8.72912 23.8627 9.03745L27.2752 9.52495C27.8312 10.4906 28.2598 11.5242 28.5502 12.6L26.4752 15.3625C26.4752 15.3625 26.5127 16.2125 26.4752 16.6375L28.5502 19.4C28.2611 20.4762 27.8325 21.5099 27.2752 22.475L23.8627 22.9625C23.8627 22.9625 23.2752 23.5749 22.9627 23.8624L22.4752 27.275C21.5096 27.831 20.476 28.2595 19.4002 28.5499L16.6377 26.475C16.2135 26.5125 15.7869 26.5125 15.3627 26.475L12.6002 28.5499C11.524 28.2609 10.4902 27.8322 9.5252 27.275L9.0377 23.8624C8.72936 23.5708 8.42936 23.2708 8.1377 22.9625L4.7252 22.475C4.16917 21.5093 3.74061 20.4757 3.4502 19.4L5.5252 16.6375C5.5252 16.6375 5.4877 15.7875 5.5252 15.3625L3.4502 12.6C3.73926 11.5237 4.1679 10.49 4.7252 9.52495L8.1377 9.03745C8.42936 8.72912 8.72936 8.42912 9.0377 8.13745L9.5252 4.72495C10.4908 4.16893 11.5244 3.74036 12.6002 3.44995L15.3627 5.52495C15.7869 5.48744 16.2135 5.48744 16.6377 5.52495L19.4002 3.44995C20.4764 3.73902 21.5102 4.16765 22.4752 4.72495L22.9627 8.13745Z"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

Svg.defaultProps = {
  color: null,
  width: null,
};

export default Svg;
