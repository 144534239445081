import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Logo from "../../assets/images/headerLogo.png";

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 1,
  backgroundColor: theme.palette.background.default,
  justifyContent: "center",
}));

export const LoginLeft = styled(Grid)(({ theme }) => ({
  flex: 1,
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
  padding: "50px",
  minHeight: "100vh",
}));

export const FillerBg = styled("div")(({ theme }) => ({
  width: "50%",
  height: "100%",
  right: 0,
  position: "absolute",
  backgroundColor: "white",
  boxShadow: "0px 0px 30px 0px #00000014",
  borderRadius: "100px 0px 0px 100px",
}));

const BrandImg = styled("img")(({ theme }) => ({
  height: "40px",
  objectFit: "contain",
  marginBottom: "23px",
}));

export const BrandLogo = () => <BrandImg src={Logo} />;

export const LoginTitle = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.text.primary}`,
  fontSize: "22px",
  fontWeight: 400,
  lineHeight: "22px",
  letterSpacing: "0em",
  textAlign: "left",
  marginBottom: "13px",
  fontFamily: "NoirPro",
}));

export const LoginForm = styled("form")(({ theme }) => ({
  width: "100%", // Fix IE 11 issue.
  marginTop: theme.spacing(4),
  maxWidth: "400px",
}));
