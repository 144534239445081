import React from "react";
import { styled } from "@mui/material/styles";
import { Content } from "components/shared";
import logo from "assets/images/logo.svg";
import Toolbar from "@mui/material/Toolbar";
import { getAuth } from "lib/localStorage";
import { useNavigate } from "react-router-dom";
import ROUTES from "lib/routes";
import AppHeader from "./MuiHeader";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
}));

const Logo = styled("img")(({ theme }) => ({
  width: "300px",
  objectFit: "contain",
  alignSelf: "center",
  marginTop: "50px",
}));

function Landing() {
  const auth = getAuth();
  const navigate = useNavigate();
  if (auth?.userId) {
    navigate(ROUTES.DASHBOARD);
  }

  return (
    <Container>
      <AppHeader />
      <Toolbar />
      <Content>
        <Logo src={logo} alt="itemsnest" />
      </Content>
    </Container>
  );
}

export default Landing;
