import React, { useState } from "react";
import {
  Container,
  Content,
  DataGridComp,
  PageTitle,
  Row,
} from "components/shared";
import { useIntl } from "react-intl";
import { CATEGORIES } from "gql/category";
import { useQuery } from "@apollo/client";
import { CategoriesData, Category } from "types/category";
import LoadingIndicator from "components/LoadingIndicator";
import AddIcon from "@mui/icons-material/AddSharp";
import Button from "@mui/material/Button";
import { CategoryCard, ContainerGrid, GridItem, Title } from "./fragments";
import CreateCategoryDrawer from "./CreateCategoryDrawer";
import EditCategoryDrawer from "./EditCategoryDrawer";
import {
  categoryDown,
  categoryLeft,
  categoryRight,
  categoryUp,
  columns,
  groupCategories,
} from "./constants";

function Categories() {
  const spacing = 1;
  const intl = useIntl();
  const [createOpen, setCreateOpen] = useState(false);
  const [focusedCategory, setFocused] = useState<Category>();
  const [editCategory, setEditCategory] = useState<Category>(); // used also as a flag to show the drawer
  const { data: categoriesData, loading } = useQuery<CategoriesData>(
    CATEGORIES,
    { variables: { includeAll: true } },
  );
  const categories = categoriesData?.categories ?? [];
  const toggleCreateDrawer = () => setCreateOpen(!createOpen);
  const toggleEditDrawer = () => setEditCategory(undefined);

  const firstLevelCategories = categories?.filter(
    (category) => !category.parentId,
  );

  const categoryGroups = focusedCategory
    ? [firstLevelCategories, ...groupCategories(categories, focusedCategory)]
    : [firstLevelCategories];

  const handleClick = (category: Category) => setFocused(category);
  const handleInfoClick = (category: Category) => setEditCategory(category);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!focusedCategory) return;
    let next = null;
    switch (event.key) {
      case "ArrowUp":
        next = categoryUp(categoryGroups, focusedCategory);
        break;
      case "ArrowDown":
        next = categoryDown(categoryGroups, focusedCategory);
        break;
      case "ArrowLeft":
        next = categoryLeft(categoryGroups, focusedCategory);
        break;
      case "ArrowRight":
        next = categoryRight(categoryGroups, focusedCategory);
        break;
      case "Enter":
        if (focusedCategory) {
          setEditCategory(focusedCategory);
        }
        break;
    }
    if (next) setFocused(next);
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, false);
    return () => {
      window.removeEventListener("keydown", handleKeyDown, false);
    };
  });
  const handleOnEdit = (record: Category) => handleInfoClick(record);

  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <PageTitle>
            {intl.formatMessage({ id: "label.categories" })} (
            {categories.length})
          </PageTitle>
          <Button onClick={toggleCreateDrawer}>
            <AddIcon />
          </Button>
        </Row>
        <ContainerGrid container spacing={spacing}>
          {categoryGroups.map((group, index) => (
            <GridItem item key={`column_${group[0]?.id}`}>
              <Title>
                {intl.formatMessage({ id: "label.categories" })} #{index + 1}
              </Title>
              {group.map((category) => (
                <CategoryCard
                  key={category.id}
                  focused={focusedCategory?.id === category.id}
                  category={category}
                  onClick={handleClick}
                  onInfoClick={handleInfoClick}
                />
              ))}
            </GridItem>
          ))}
        </ContainerGrid>
        <div style={{ height: "80vh" }}>
          <DataGridComp
            rows={categories}
            columns={columns(handleOnEdit)}
            disableSelectionOnClick
            hideFooter
            rowsPerPageOptions={[categories.length]}
          />
        </div>
      </Content>
      <CreateCategoryDrawer
        open={createOpen}
        toggleDrawer={toggleCreateDrawer}
        categories={categories}
      />
      {editCategory && (
        <EditCategoryDrawer
          open={!!editCategory}
          category={editCategory}
          toggleDrawer={toggleEditDrawer}
          categories={categories}
        />
      )}
    </Container>
  );
}
export default Categories;
