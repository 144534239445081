import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import ROUTES from "lib/routes";
import { useLazyQuery } from "@apollo/client";
import { Button } from "components/shared";
import LoadingIndicator from "components/LoadingIndicator";
import { saveAuth } from "lib/localStorage";
import { LOGIN } from "gql/auth";
import { LoginData, LoginInput } from "types/auth";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import InputRender, { formHasErrors } from "components/input/InputRender";
import {
  Container,
  LoginLeft,
  BrandLogo,
  LoginTitle,
  LoginForm,
  FillerBg,
} from "./fragments";
import { inputs } from "./constants";
import { FormDataType } from "components/input/types";

interface LoginProps {
  showSnackbar?: (message: string, severity: Severity) => void;
}

function LoginScreen(props: LoginProps) {
  const { showSnackbar } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [showErrors, setShowErrors] = useState(false);
  const [data, setData] = useState<FormDataType>({ email: "", password: "" });

  const [loginUser, { loading }] = useLazyQuery<LoginData, LoginInput>(LOGIN, {
    onCompleted: (response) => {
      if (response && response.login) {
        saveAuth(response.login);
        navigate(ROUTES.DASHBOARD);
      }
    },
    onError: (error) => {
      showSnackbar?.(error.message, Severity.ERROR);
    },
  });

  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formHasErrors(inputs, data)) {
      setShowErrors(true);
      return;
    }
    const payload = {
      email: data.email as string,
      password: data.password as string,
      admin: true,
    };
    loginUser({ variables: { payload } });
  };

  return (
    <Container>
      <LoadingIndicator visible={loading} />
      {!!isBigScreen && <FillerBg />}
      <LoginLeft item xs={12} sm={6}>
        <Link to={ROUTES.INDEX}>
          <BrandLogo />
        </Link>
        <LoginTitle>{intl.formatMessage({ id: "label.login" })}</LoginTitle>
        <LoginForm noValidate onSubmit={handleOnsubmit}>
          {inputs.map((input) => {
            return (
              <InputRender
                key={input.key}
                data={data}
                input={input}
                inputs={inputs}
                setData={setData}
                showErrors={showErrors}
              />
            );
          })}
          <Button type="submit" fullWidth variant="contained" color="primary">
            {intl.formatMessage({ id: "label.signIn" })}
          </Button>
        </LoginForm>
      </LoginLeft>
    </Container>
  );
}

export default withSnackbar(LoginScreen);
