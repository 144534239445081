import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  ModalTitle,
  ModalDrawer,
  RowSpaced,
  Row,
  Column,
} from "components/shared";
import { useMutation } from "@apollo/client";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { FormDataType } from "components/input/types";
import { Box, Chip, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  ReplyToUserRequestData,
  ReplyToUserRequestInput,
  UserRequestExtended,
} from "types/userRequest";
import { BodyText, H6 } from "components/typography";
import { cardBoxShadow } from "assets/styles/theme";
import Avatar from "components/Avatar";
import InputRender, { formHasErrors } from "components/input/InputRender";
import { Form } from "components/input/fragments";
import { userRequestInputs } from "./constants";
import { REPLY_TO_USER_REQUEST } from "gql/userRequest";

interface ContentProps {
  open: boolean;
  record?: UserRequestExtended;
  toggleDrawer: () => void;
  showSnackbar?: (message: string, severity: Severity) => void;
  refetch: () => void;
}

function UserRequestDrawer({
  open,
  record,
  refetch,
  toggleDrawer,
  showSnackbar,
}: ContentProps) {
  const intl = useIntl();
  const theme = useTheme();
  const [showErrors, setShowErrors] = useState(false);
  const [data, setData] = useState<FormDataType>({
    message: "",
  });

  const [replyToRequest] = useMutation<
    ReplyToUserRequestData,
    ReplyToUserRequestInput
  >(REPLY_TO_USER_REQUEST, {
    onCompleted: () => {
      setData({ message: "" });
      refetch();
    },
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
  });
  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formHasErrors(inputs, data)) {
      setShowErrors(true);
      return;
    }
    const payload = {
      message: data.message as string,
      id: record?.id ?? "",
    };
    replyToRequest({ variables: { payload } });
  };

  if (!record) return null;
  const inputs = record.type ? userRequestInputs(record.type) : [];
  return (
    <ModalDrawer anchor="right" open={open} onClose={toggleDrawer}>
      <RowSpaced style={{ alignItems: "center", padding: "10px" }}>
        <ModalTitle>
          {intl.formatMessage({ id: `input.userRequest.type.${record.type}` })}
        </ModalTitle>
        <Chip
          label={intl.formatMessage({
            id: `input.userRequest.status.${record.status}`,
          })}
        />
      </RowSpaced>
      <Divider />
      <div style={{ padding: "30px 20px", width: "400px" }}>
        <MessageCard
          isThread={false}
          message={record.message}
          user={record.user}
        />
        <Box height={5} />
        <Column
          style={{
            backgroundColor: theme.palette.shared.lightBlue,
            padding: "10px",
          }}
        >
          {record.replies.map((reply) => (
            <div key={reply.id}>
              <MessageCard isThread message={reply.message} user={reply.user} />
              <Box height={10} />
            </div>
          ))}
        </Column>
        <Divider />

        <Form noValidate onSubmit={handleOnSubmit}>
          {inputs.map((input) => {
            return (
              <InputRender
                key={input.key}
                data={data}
                input={input}
                inputs={inputs}
                setData={setData}
                showErrors={showErrors}
              />
            );
          })}
          <Button fullWidth type="submit" variant="contained" color="primary">
            {intl.formatMessage({ id: "label.reply" })}
          </Button>
        </Form>
        <Box height={30} />
      </div>
    </ModalDrawer>
  );
}

UserRequestDrawer.defaultProps = {
  showSnackbar: () => {},
};

interface MessageCardProps {
  message: string;
  user: UserRequestExtended["user"];
  isThread: boolean;
}
function MessageCard({ message, user, isThread }: MessageCardProps) {
  const theme = useTheme();
  const name = `${user.firstName} ${user?.lastName ?? ""}`;
  const placeholder = `${user?.firstName.substring(0, 1) ?? ""}${
    user?.lastName?.substring(0, 1) ?? ""
  }`;
  return (
    <Column
      style={{
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.shared.darkGray}`,
        boxShadow: isThread ? "none" : cardBoxShadow,
      }}
    >
      <Row style={{ padding: "8px 2px", alignItems: "center" }}>
        <Avatar placeholder={placeholder} size={30} alt={name} />
        <H6 style={{ marginLeft: "4px" }}>{name}</H6>
      </Row>
      <Divider />
      <BodyText style={{ whiteSpace: "pre-line", padding: "8px" }}>
        {message}
      </BodyText>
    </Column>
  );
}

export default withSnackbar(UserRequestDrawer);
