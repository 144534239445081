import React, { useState } from "react";
import { useIntl } from "react-intl";
import InputRender, { formHasErrors } from "components/input/InputRender";
import { Button, ModalTitle, ModalDrawer, DrawerForm } from "components/shared";
import { useMutation } from "@apollo/client";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { inputs } from "./constants";
import {
  DeleteServiceTypeData,
  DeleteServiceTypeInput,
  ServiceType,
  UpdateServiceTypeData,
  UpdateServiceTypeInput,
} from "types/service";
import {
  DELETE_SERVICE_TYPE,
  SERVICE_TYPES,
  UPDATE_SERVICE_TYPE,
} from "gql/service";
import { FormDataType } from "components/input/types";

interface ContentProps {
  open: boolean;
  toggleDrawer: () => void;
  serviceType: ServiceType;
  showSnackbar?: (message: string, severity: Severity) => void;
  serviceTypes: ServiceType[];
}

function EditDrawer({
  open,
  toggleDrawer,
  serviceType,
  showSnackbar,
  serviceTypes,
}: ContentProps) {
  const intl = useIntl();
  const [showErrors, setShowErrors] = useState(false);
  const [data, setData] = useState<FormDataType>({
    title: serviceType.title,
    description: serviceType.description || "",
    titleDE: serviceType.titleDE || "",
    descriptionDE: serviceType.descriptionDE || "",
    parentId: serviceType.parentId || "",
    published: serviceType.published ? "published" : "unpublished",
  });

  const onComplete = () => toggleDrawer();
  const [updateServiceType] = useMutation<
    UpdateServiceTypeData,
    UpdateServiceTypeInput
  >(UPDATE_SERVICE_TYPE, {
    onCompleted: onComplete,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: SERVICE_TYPES, variables: { includeAll: true } }],
  });

  const [deleteServiceType] = useMutation<
    DeleteServiceTypeData,
    DeleteServiceTypeInput
  >(DELETE_SERVICE_TYPE, {
    onCompleted: onComplete,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: SERVICE_TYPES, variables: { includeAll: true } }],
  });
  const formInputs = inputs(
    serviceTypes.filter(
      (type) => type.id !== serviceType.id && serviceType.id !== type.parentId,
    ), // remove self and children that have self as parent
  );
  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formHasErrors(formInputs, data)) {
      setShowErrors(true);
      return;
    }
    const payload = {
      id: serviceType.id,
      data: {
        title: data.title as string,
        description: data.description as string,
        titleDE: (data.titleDE as string) || undefined,
        descriptionDE: (data.descriptionDE as string) || undefined,
        parentId: !!data.parentId ? (data.parentId as string) : null,
        published: data.published === "published",
      },
    };
    updateServiceType({ variables: { payload } });
  };

  const handleDelete = () =>
    deleteServiceType({ variables: { id: serviceType.id } });

  return (
    <ModalDrawer anchor="right" open={open} onClose={toggleDrawer}>
      <ModalTitle p={2}>
        {intl.formatMessage({ id: "label.serviceType" })}
      </ModalTitle>
      <DrawerForm noValidate onSubmit={handleOnsubmit}>
        {formInputs.map((input) => {
          return (
            <InputRender
              key={input.key}
              data={data}
              input={input}
              inputs={formInputs}
              setData={setData}
              showErrors={showErrors}
            />
          );
        })}
        <Button type="submit" fullWidth variant="contained" color="primary">
          {intl.formatMessage({ id: "label.update" })}
        </Button>
        {!serviceType.deletedAt && (
          <Button
            fullWidth
            onClick={handleDelete}
            variant="contained"
            color="error"
            style={{ marginTop: "10px" }}
          >
            {intl.formatMessage({ id: "label.delete" })}
          </Button>
        )}
      </DrawerForm>
    </ModalDrawer>
  );
}

EditDrawer.defaultProps = {
  showSnackbar: () => {},
};

export default withSnackbar(EditDrawer);
