import { gql } from "@apollo/client";

export const PROVIDER_CATEGORIES = gql`
  query PROVIDER_CATEGORIES($includeAll: Boolean!) {
    providerCategories(includeAll: $includeAll) {
      id
      title
      description
      published
      titleDE
      descriptionDE
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const CREATE_PROVIDER_CATEGORY = gql`
  mutation CREATE_PROVIDER_CATEGORY($payload: ProviderCategoryBase!) {
    createProviderCategory(payload: $payload) {
      id
    }
  }
`;

export const DELETE_PROVIDER_CATEGORY = gql`
  mutation DELETE_PROVIDER_CATEGORY($categoryId: ID!) {
    deleteProviderCategory(categoryId: $categoryId) {
      status
    }
  }
`;

export const UPDATE_PROVIDER_CATEGORY = gql`
  mutation UPDATE_PROVIDER_CATEGORY($payload: UpdateProviderCategoryInput!) {
    updateProviderCategory(payload: $payload) {
      id
    }
  }
`;
