import { Currencies, OrganizationType, Status } from "./shared";

export enum Limitations {
  ACCOUNT_OWNERS = "ACCOUNT_OWNERS",
  BOARDS = "BOARDS",
  SPACES = "SPACES",
  ITEMS = "ITEMS",
  CONTAINERS = "CONTAINERS",
  GROUPS = "GROUPS",
  CONTRACTS = "CONTRACTS",
  MEMBERS = "MEMBERS",
  STORAGE = "STORAGE",
}

export enum SubscriptionPlanType {
  FREE = "FREE", // used for trail plans
  STARTER = "STARTER",
  STANDARD = "STANDARD",
  PREMIUM = "PREMIUM",
}

export interface SubscriptionPlan {
  type: SubscriptionPlanType;
  id: string;
  title: string;
  titleDE: string;
  description?: string;
  descriptionDE?: string;
  // duration in months
  duration: number;
  extraDuration: number;
  pricing: SubscriptionPlanPricing[];
  // subscription plan is active for the public
  active: boolean;
  // The default subscription plan to assign on account creation.
  // Can be only one set to true and must be an active subscription plan
  // Applies as default to TRAIL or FREE plans.
  defaultPlan: boolean;
  // Applicable to organization types. If empty applies to all types.
  applicableTo?: OrganizationType[];
  limitations: { [key in Limitations]?: number };
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface SubscriptionPlanData {
  subscriptionPlans: { list: SubscriptionPlan[]; total: number };
}
export interface SubscriptionPlanInput {
  payload: {
    includeAll?: boolean;
  };
}

export interface CreateSubscriptionPlanData {
  createSubscriptionPlan: SubscriptionPlan;
}

export interface SubscriptionPlanPricing {
  countryCode: string;
  value: number;
  currency: Currencies;
  // tax percentage 1-100
  tax: number;
  // number of days
  refundDeadline: number;
}

export interface CreateSubscriptionPlanInput {
  payload: {
    type: SubscriptionPlanType;
    title: string;
    titleDE: string;
    description?: string;
    descriptionDE?: string;
    duration: number;
    extraDuration: number;
    active?: boolean;
    defaultPlan?: boolean;
    applicableTo?: OrganizationType[];
    limitations?: SubscriptionPlan["limitations"];
    pricing: SubscriptionPlanPricing[];
  };
}

export interface UpdateSubscriptionPlanInput {
  payload: {
    id: string;
    data: {
      type: SubscriptionPlanType;
      title?: string;
      titleDE?: string;
      description?: string;
      descriptionDE?: string;
      duration?: number;
      extraDuration?: number;
      active?: boolean;
      pricing?: SubscriptionPlanPricing[];
      defaultPlan?: boolean;
      applicableTo?: OrganizationType[];
      limitations?: SubscriptionPlan["limitations"];
    };
  };
}

export interface UpdateSubscriptionPlanData {
  updateSubscriptionPlan: SubscriptionPlan;
}

export interface DeleteSubscriptionPlanData {
  deleteSubscriptionPlan: Status;
}

export interface DeleteSubscriptionPlanInput {
  id: string;
}

export interface SubscriptionOrderExtended extends SubscriptionOrder {
  invoices: SubscriptionInvoice[];
}
export interface SubscriptionExtended extends Subscription {
  orders: SubscriptionOrderExtended[];
  subscriptionPlan: SubscriptionPlan;
}

export enum SubscriptionStatus {
  // when the subscription is being purchased and it will be created in a draft status.
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  EXPIRED = "EXPIRED",
}

export interface Subscription {
  id: string;
  subscriptionPlanId: string;
  organizationId: string;
  startDate: Date;
  endDate: Date;
  cancelationDate?: Date;
  status: SubscriptionStatus;
  createdAt: Date;
  updatedAt: Date;
}

export interface SubscriptionOrder {
  id: string;
  orderNumber: string;
  subscriptionIds: string[];
  organizationId: string;
  date: Date;
  createdAt: Date;
  updatedAt: Date;
}

export enum InvoiceStatus {
  DRAFT = "DRAFT",
  PAID = "PAID",
  REFUNDED = "REFUNDED",
}

export interface SubscriptionInvoice {
  id: string;
  // generated number of reference sent to the customer
  invoiceNumber: string;
  orderId: string;
  // invoice Date
  date: Date;
  // the items/subscriptions being ordered. This will be based on the country code selection, in case of subscription plans.
  items: {
    id: string;
    invoiceReference?: string;
    productReference: string; // product number or title
    productDescription: string;
    // subscription plan duration applicable for this purchase
    fromDate: Date;
    toDate: Date;
    price: {
      value: number;
      currency: Currencies;
    };
  }[];
  // total price of the invoice
  pricing: {
    countryCode: string; // relevant for tax applications
    value: number;
    totalAmount: number; // value + tax
    tax: number;
    currency: Currencies;
  };
  isRefunded: boolean;
  // document related to this transaction - can be invoices or refunds - cancelation documents, etc.
  archiveIds: string[];
  status: InvoiceStatus;
  createdAt: Date;
  updatedAt: Date;
}
