import React, { useState } from "react";
import { useIntl } from "react-intl";
import InputRender, { formHasErrors } from "components/input/InputRender";
import { Button, ModalTitle, ModalDrawer, DrawerForm } from "components/shared";
import { useMutation } from "@apollo/client";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { inputs } from "./constants";
import { CREATE_SERVICE_TYPE, SERVICE_TYPES } from "gql/service";
import {
  CreateServiceTypeData,
  CreateServiceTypeInput,
  ServiceType,
} from "types/service";
import { FormDataType } from "components/input/types";

interface ContentProps {
  open: boolean;
  toggleDrawer: () => void;
  showSnackbar?: (message: string, severity: Severity) => void;
  serviceTypes: ServiceType[];
}

const initialState: FormDataType = {
  title: "",
  description: "",
  titleDE: "",
  descriptionDE: "",
  published: "published", // default published
  parentId: "",
};
function CreateDrawer({
  open,
  toggleDrawer,
  showSnackbar,
  serviceTypes,
}: ContentProps) {
  const intl = useIntl();
  const [showErrors, setShowErrors] = useState(false);
  const [data, setData] = useState<FormDataType>(initialState);

  const onComplete = () => {
    toggleDrawer();
    setData(initialState);
  };

  const [createServiceType] = useMutation<
    CreateServiceTypeData,
    CreateServiceTypeInput
  >(CREATE_SERVICE_TYPE, {
    onCompleted: onComplete,
    onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
    refetchQueries: [{ query: SERVICE_TYPES, variables: { includeAll: true } }],
  });
  const formInputs = inputs(serviceTypes);

  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formHasErrors(formInputs, data)) {
      setShowErrors(true);
      return;
    }
    const payload = {
      title: data.title as string,
      description: (data.description as string) || undefined,
      titleDE: (data.titleDE as string) || undefined,
      descriptionDE: (data.descriptionDE as string) || undefined,
      parentId: (data.parentId as string) || undefined,
      published: data.published === "published",
    };
    createServiceType({ variables: { payload } });
  };

  return (
    <ModalDrawer anchor="right" open={open} onClose={toggleDrawer}>
      <ModalTitle p={2}>
        {intl.formatMessage({ id: "label.createServiceType" })}
      </ModalTitle>
      <DrawerForm noValidate onSubmit={handleOnsubmit}>
        {formInputs.map((input) => {
          return (
            <InputRender
              key={input.key}
              data={data}
              input={input}
              inputs={formInputs}
              setData={setData}
              showErrors={showErrors}
            />
          );
        })}
        <Button type="submit" fullWidth variant="contained" color="primary">
          {intl.formatMessage({ id: "label.create" })}
        </Button>
      </DrawerForm>
    </ModalDrawer>
  );
}

CreateDrawer.defaultProps = {
  showSnackbar: () => {},
};

export default withSnackbar(CreateDrawer);
