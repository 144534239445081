import { gql } from "@apollo/client";

// group-type gql

export const GROUP_TYPES = gql`
  query GROUP_TYPES($includeAll: Boolean!) {
    groupTypesList(includeAll: $includeAll) {
      total
      list {
        id
        title
        description
        titleDE
        descriptionDE
        published
        priority
        featured
        applicable {
          organizationTypes
        }
        coverImage {
          full
          medium
          thumbnail
        }
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const CREATE_GROUP_TYPE = gql`
  mutation CREATE_GROUP_TYPE($payload: GroupTypeInput!) {
    createGroupType(payload: $payload) {
      id
    }
  }
`;

export const DELETE_GROUP_TYPE = gql`
  mutation DELETE_GROUP_TYPE($id: ID!) {
    deleteGroupType(id: $id) {
      status
    }
  }
`;

export const UPDATE_GROUP_TYPE = gql`
  mutation UPDATE_GROUP_TYPE($payload: UpdateGroupTypeInput!) {
    updateGroupType(payload: $payload) {
      id
    }
  }
`;
