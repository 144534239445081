import React from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import { GridColDef } from "@mui/x-data-grid";
import { ErrorType } from "hooks/api/fileUpload";
import { DocumentTypeCell, RemoveIcon, StatusIcon } from "./fragments";

export const columns = (
  onTypeChange: (rowIndex: number, event: SelectChangeEvent) => void,
  onRemove: (rowIndex: number) => void,
  onTitleChange: (rowIndex: number, title: string) => void,
  errors: ErrorType[],
): GridColDef[] => [
  {
    field: "id",
    headerName: "#",
    width: 20,
    sortable: false,
    headerAlign: "center",
    hide: true,
  },
  {
    field: "error",
    headerName: "Status",
    width: 60,
    headerAlign: "center",
    hide: !errors || errors.length === 0,
    renderCell: (row) => <StatusIcon rowData={row} errors={errors} />,
  },
  {
    field: "fileName",
    headerName: "File name",
    width: 200,
    headerAlign: "center",
  },
  {
    field: "title",
    headerName: "Title",
    width: 250,
    headerAlign: "center",
    hideable: false,
    editable: true,
    preProcessEditCellProps: (params) => {
      const { props, row } = params;
      onTitleChange(Number(row.id) - 1, props.value as string);
      return params.props;
    },
  },
  {
    field: "fileSize",
    headerName: "Size",
    width: 150,
    headerAlign: "center",
    valueFormatter: ({ value }) => `${value} MB`,
  },
  {
    field: "documentType",
    headerName: "Document type",
    width: 180,
    sortable: false,
    headerAlign: "center",
    renderCell: (row) => (
      <DocumentTypeCell rowData={row} onChange={onTypeChange} />
    ),
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 100,
    sortable: false,
    headerAlign: "center",
    renderCell: (row) => <RemoveIcon rowData={row} onRemove={onRemove} />,
  },
];
