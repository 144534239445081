import React from "react";
import { Container, Content, PageTitle, Row } from "components/shared";
import { useIntl } from "react-intl";
import { DataGridComp } from "components/shared";
import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/LoadingIndicator";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { USER_REQUESTS_LIST } from "gql/userRequest";
import { UserRequestsListData, UserRequestsListInput } from "types/userRequest";
import { useTheme } from "@mui/material/styles";
import { columns } from "./constants";
import UserRequestDrawer from "./UserRequestDrawer";
import { getUserRequest } from "./utils";

interface Props {
  showSnackbar?: (message: string, severity: Severity) => void;
}
function Page({ showSnackbar }: Props) {
  const intl = useIntl();
  const theme = useTheme();
  const [activeRequestId, setActiveRequestId] = React.useState<string>();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const payload = { limit: pageSize, page };
  const { data, loading, refetch } = useQuery<
    UserRequestsListData,
    UserRequestsListInput
  >(USER_REQUESTS_LIST, {
    variables: { payload },
  });
  const onRequestSelect = (id: string) => setActiveRequestId(id);
  const toggleDrawer = () => setActiveRequestId(undefined);
  const records = data?.userRequestsList.list || [];
  const onPageChange = (_page: number) => setPage(_page);
  const onPageSizeChange = (_pageSize: number) => setPageSize(_pageSize);
  const tableColumns = columns(intl, theme, onRequestSelect);
  const request = activeRequestId
    ? getUserRequest(activeRequestId, records)
    : undefined;

  return (
    <Container>
      <LoadingIndicator visible={loading} />
      <Content>
        <Row>
          <PageTitle>
            {intl.formatMessage({ id: "label.userRequests" })} (
            {data?.userRequestsList.total})
          </PageTitle>
        </Row>
        <div style={{ height: "80vh" }}>
          <DataGridComp
            initialState={{ pagination: { page, pageSize } }}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={records}
            columns={tableColumns}
            rowCount={data?.userRequestsList.total || 0}
            disableSelectionOnClick
            rowsPerPageOptions={[25, 50, 100]}
            paginationMode="server"
          />
        </div>
      </Content>
      <UserRequestDrawer
        open={!!request}
        refetch={refetch}
        toggleDrawer={toggleDrawer}
        record={request}
      />
    </Container>
  );
}
export default withSnackbar(Page);
