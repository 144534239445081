import React from "react";
import { IntlShape } from "react-intl";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridText } from "components/shared";
import { format } from "date-fns";
import { DateFormat } from "utils/datetime";
import { RefNumberCell } from "./fragments";
import { Theme } from "@mui/material/styles";
import { getStatusColor, getTypeColor } from "./utils";
import { UserRequestType } from "types/userRequest";
import { isValidText } from "utils/validators";
import { InputType, Type } from "components/input/types";

export const columns = (
  intl: IntlShape,
  theme: Theme,
  onSelect: (requestId: string) => void,
): GridColDef[] => [
  {
    field: "id",
    headerName: "#",
    width: 0,
    sortable: false,
    headerAlign: "center",
    hide: true,
  },
  {
    field: "referenceNumber",
    headerName: "Reference number",
    width: 200,
    headerAlign: "center",
    renderCell: ({ row }) => <RefNumberCell onClick={onSelect} record={row} />,
  },
  {
    field: "status",
    headerName: "Status",
    align: "center",
    width: 110,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText style={{ color: getStatusColor(row.value, theme) }}>
        {intl.formatMessage({
          id: `input.userRequest.status.${row.value}`,
          defaultMessage: row.value,
        })}
      </DataGridText>
    ),
  },
  {
    field: "type",
    headerName: "Type",
    align: "center",
    width: 100,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText style={{ color: getTypeColor(row.value, theme) }}>
        {intl.formatMessage({
          id: `input.userRequest.type.${row.value}`,
          defaultMessage: row.value,
        })}
      </DataGridText>
    ),
  },
  {
    field: "message",
    headerName: "Message",
    width: 300,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{row?.value?.substring(0, 50)}...</DataGridText>
    ),
  },
  {
    field: "replies",
    headerName: "Replies",
    width: 60,
    align: "center",
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{row?.value?.length ?? "0"} </DataGridText>
    ),
  },
  {
    field: "updatedAt",
    headerName: "Updated at",
    width: 160,
    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), DateFormat.datetime) || "-"}
      </DataGridText>
    ),
  },
  {
    field: "createdAt",
    headerName: "Created at",
    width: 160,

    renderCell: (row) => (
      <DataGridText>
        {format(new Date(row.value), DateFormat.datetime) || "-"}
      </DataGridText>
    ),
  },
];

export const userRequestInputs = (
  type: UserRequestType,
  infoLabel?: string,
): InputType[] => {
  return [
    {
      key: "message",
      label: `label.writeReply`,
      type: Type.TEXT,
      autoComplete: "",
      autoFocus: false,
      margin: "dense",
      fullWidth: true,
      multiline: true,
      infoLabel,
      validators: [
        {
          method: isValidText,
          label: "label.required",
        },
      ],
    },
  ];
};
