import React from "react";
import { BodyTextLight } from "components/typography";
import { useTheme } from "@mui/material/styles";
import { UserRequest } from "types/userRequest";
import { Button } from "@mui/material";

export function RefNumberCell({
  record,
  onClick,
}: {
  record: UserRequest;
  onClick: (id: string) => void;
}) {
  const theme = useTheme();
  const handleOnClick = () => onClick(record.id);
  return (
    <Button onClick={handleOnClick}>
      <BodyTextLight color={theme.palette.info.dark}>
        {record.referenceNumber ?? "-"}
      </BodyTextLight>
    </Button>
  );
}
