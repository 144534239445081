import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { IntlProvider } from "react-intl";
import GraphqlProvider from "components/providers/GraphqlProvider";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// TODO: delete files if not needed - after social auth
// import firebaseConfig from './config/firebase.test'
// import german from "./assets/lang/de.json";
import english from "./assets/lang/en.json";
import theme from "./assets/styles/theme";
import "./index.css";

const locale = navigator.language; // get system locality
// const locale = 'de'

const lang = english;
// if (locale === "de") {
//   lang = german;
// } else {
//   lang = english;
// }
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <IntlProvider defaultLocale="en" locale={locale} messages={lang}>
      <ThemeProvider theme={theme}>
        <GraphqlProvider>
          <App />
        </GraphqlProvider>
      </ThemeProvider>
    </IntlProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
