import { ErrorResponse } from "@apollo/client/link/error";

function getErrorCode({ graphQLErrors }: ErrorResponse) {
  const error = graphQLErrors?.[0];
  return error?.extensions.code;
}

export const hasSessionExpired = (error: ErrorResponse): boolean => {
  const errorCode = getErrorCode(error);
  return errorCode === "UNAUTHENTICATED";
};
