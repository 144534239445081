import { gql } from "@apollo/client";

export const ORGANIZATIONS = gql`
  query ORGANIZATIONS($payload: OrganizationsInput!) {
    organizations(payload: $payload) {
      total
      list {
        id
        type
        country

        referenceNumber
        company {
          name
          description
        }
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const ORGANIZATION_ADMIN_DATA = gql`
  query ORGANIZATION_ADMIN_DATA($organizationId: ID) {
    organizationAdminData(organizationId: $organizationId) {
      id
      type
      defaultOptions {
        currency
        measurementSystem
      }
      company {
        name
        description
      }
      referenceNumber
      createdAt
      updatedAt
      subscriptions {
        id
        subscriptionPlanId
        organizationId
        startDate
        endDate
        cancelationDate
        status
        createdAt
        updatedAt
        subscriptionPlan {
          id
          titleDE
          title
          description
          descriptionDE
          duration
          extraDuration
          active
          defaultPlan
          applicableTo
          limitations
          createdAt
          updatedAt
        }
        orders {
          id
          orderNumber
          subscriptionIds
          organizationId
          date
          createdAt
          updatedAt
          invoices {
            id
            invoiceNumber
            orderId
            date
            items {
              id
              invoiceReference
              productReference
              productDescription
              fromDate
              toDate
              price {
                value
                currency
              }
            }
            pricing {
              value
              currency
              countryCode
              tax
              totalAmount
            }
            isRefunded
            archiveIds
            status
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const ADMIN_EXTEND_SUBSCRIPTION = gql`
  mutation ADMIN_EXTEND_SUBSCRIPTION($payload: ExtendSubscriptionInput!) {
    adminExtendSubscription(payload: $payload) {
      status
    }
  }
`;

export const ADMIN_DELETE_SUBSCRIPTION = gql`
  mutation ADMIN_DELETE_SUBSCRIPTION($subscriptionId: ID!) {
    adminDeleteSubscription(subscriptionId: $subscriptionId) {
      status
    }
  }
`;
