import Categories from "pages/dashboard/categories/Categories";
import Dashboard from "pages/dashboard/Dashboard";
import Overview from "pages/dashboard/overview/Overview";
import Users from "pages/dashboard/users/Users";
import Workspaces from "pages/dashboard/workspaces/Workspaces";
import NestTypes from "pages/dashboard/nestTypes/NestTypes";
import UserDetails from "pages/dashboard/users/UserDetails";
import Landing from "pages/landing/Landing";
import Login from "pages/login/Login";
import ServiceTypes from "pages/dashboard/serviceTypes/ServiceTypes";
import WorkspaceTypes from "pages/dashboard/workspaceTypes/WorkspaceTypes";
import Questionnaires from "pages/dashboard/questionnaire/Questionnaire";
import CreateQuestionnaire from "pages/dashboard/questionnaire/CreateQuestionnaire";
import GroupTypes from "pages/dashboard/groupTypes/GroupTypes";
import ProviderCategories from "pages/dashboard/providerCategories/ProviderCategories";
import SubscriptionPlans from "pages/dashboard/subscriptionPlans/Page";
import OrganizationsList from "pages/dashboard/organizations/list/Page";
import OrganizationsDetail from "pages/dashboard/organizations/show/Page";
import UserRequestsList from "pages/dashboard/userRequests/list/Page";
import ROUTES from "./routes";

export const MAIN_ROUTES = [
  {
    route: ROUTES.INDEX,
    component: Landing,
  },
  {
    route: ROUTES.LOGIN,
    component: Login,
  },

  {
    route: `${ROUTES.DASHBOARD}/*`,
    component: Dashboard,
  },
];

export const DASHBOARD_ROUTES = [
  {
    route: "/",
    component: Overview,
  },
  {
    route: ROUTES.DASHBOARD_CATEGORIES,
    component: Categories,
  },
  {
    route: ROUTES.DASHBOARD_PROVIDER_CATEGORIES,
    component: ProviderCategories,
  },
  {
    route: ROUTES.DASHBOARD_QUESTIONNAIRES,
    component: Questionnaires,
  },
  {
    route: ROUTES.DASHBOARD_QUESTIONNAIRES_CREATE,
    component: CreateQuestionnaire,
  },
  {
    route: ROUTES.DASHBOARD_USERS,
    component: Users,
  },
  {
    route: ROUTES.DASHBOARD_WORKSPACES,
    component: Workspaces,
  },
  {
    route: ROUTES.DASHBOARD_USER_ID,
    component: UserDetails,
  },
  {
    route: ROUTES.DASHBOARD_NEST_TYPES,
    component: NestTypes,
  },
  {
    route: ROUTES.DASHBOARD_SERVICE_TYPES,
    component: ServiceTypes,
  },
  {
    route: ROUTES.DASHBOARD_WORKSPACE_TYPES,
    component: WorkspaceTypes,
  },
  {
    route: ROUTES.DASHBOARD_GROUP_TYPES,
    component: GroupTypes,
  },
  {
    route: ROUTES.DASHBOARD_SUBSCRIPTION_PLANS,
    component: SubscriptionPlans,
  },
  {
    route: ROUTES.DASHBOARD_ORGANIZATIONS,
    component: OrganizationsList,
  },
  {
    route: ROUTES.DASHBOARD_USER_REQUESTS,
    component: UserRequestsList,
  },
  {
    route: ROUTES.DASHBOARD_ORGANIZATIONS_DETAIL,
    component: OrganizationsDetail,
  },
];
