import React, { useState } from "react";
import { useIntl } from "react-intl";
import InputRender, { formHasErrors } from "components/input/InputRender";
import { Button, ModalTitle, ModalDrawer, DrawerForm } from "components/shared";
import { useMutation, useQuery } from "@apollo/client";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import {
  DeleteNestTypeData,
  DeleteNestTypeInput,
  NestType,
  SpaceAvailableOptionsData,
  UpdateNestTypeData,
  UpdateNestTypeInput,
} from "types/nest";
import {
  DELETE_NEST_TYPE,
  NEST_TYPES,
  SPACE_AVAILABLE_OPTIONS,
  UPDATE_NEST_TYPE,
} from "gql/nest";
import { inputs } from "./constants";
import { FormDataType } from "components/input/types";

interface ContentProps {
  open: boolean;
  toggleDrawer: () => void;
  nestType: NestType;
  showSnackbar?: (message: string, severity: Severity) => void;
}

function EditDrawer({
  open,
  toggleDrawer,
  nestType,
  showSnackbar,
}: ContentProps) {
  const intl = useIntl();
  const [showErrors, setShowErrors] = useState(false);
  const [data, setData] = useState<FormDataType>({
    title: nestType.title,
    description: nestType.description || "",
    titleDE: nestType.titleDE || "",
    descriptionDE: nestType.descriptionDE || "",
    featuresOptions: nestType.featuresOptions || [],
    securityFeaturesOptions: nestType.securityFeaturesOptions || [],
    spaceUsageOptions: nestType.spaceUsageOptions || [],
    elevationOptions: nestType.elevationOptions || [],
  });

  const onComplete = () => toggleDrawer();
  const [updateNestType] = useMutation<UpdateNestTypeData, UpdateNestTypeInput>(
    UPDATE_NEST_TYPE,
    {
      onCompleted: onComplete,
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      refetchQueries: [{ query: NEST_TYPES, variables: { includeAll: true } }],
    },
  );

  const [deleteNestType] = useMutation<DeleteNestTypeData, DeleteNestTypeInput>(
    DELETE_NEST_TYPE,
    {
      onCompleted: onComplete,
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      refetchQueries: [{ query: NEST_TYPES, variables: { includeAll: true } }],
    },
  );

  const { data: options } = useQuery<SpaceAvailableOptionsData>(
    SPACE_AVAILABLE_OPTIONS,
  );

  const spaceOptions = options?.spaceAvailableOptions;

  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!spaceOptions) return;
    if (formHasErrors(inputs(spaceOptions), data)) {
      setShowErrors(true);
      return;
    }
    const payload = {
      id: nestType.id,
      data: {
        title: data.title as string,
        description: data.description as string,
        titleDE: data.titleDE as string,
        descriptionDE: data.descriptionDE as string,
        featuresOptions: data.featuresOptions as string[],
        securityFeaturesOptions: data.securityFeaturesOptions as string[],
        spaceUsageOptions: data.spaceUsageOptions as string[],
        elevationOptions: data.elevationOptions as string[],
      },
    };
    updateNestType({ variables: { payload } });
  };

  const handleDelete = () => deleteNestType({ variables: { id: nestType.id } });

  return (
    <ModalDrawer anchor="right" open={open} onClose={toggleDrawer}>
      <ModalTitle p={2}>
        {intl.formatMessage({ id: "label.nestType" })}
      </ModalTitle>
      <DrawerForm noValidate onSubmit={handleOnsubmit}>
        {spaceOptions &&
          inputs(spaceOptions).map((input) => {
            return (
              <InputRender
                key={input.key}
                data={data}
                input={input}
                inputs={inputs(spaceOptions)}
                setData={setData}
                showErrors={showErrors}
              />
            );
          })}
        <Button type="submit" fullWidth variant="contained" color="primary">
          {intl.formatMessage({ id: "label.update" })}
        </Button>
        {!nestType?.deletedAt && (
          <Button
            fullWidth
            onClick={handleDelete}
            variant="contained"
            color="error"
            style={{ marginTop: "10px" }}
          >
            {intl.formatMessage({ id: "label.delete" })}
          </Button>
        )}
      </DrawerForm>
    </ModalDrawer>
  );
}

EditDrawer.defaultProps = {
  showSnackbar: () => {},
};

export default withSnackbar(EditDrawer);
