/**
 * Registered font families int he app.
 * Must match registered fonts from #src/index.css
 */
enum Fonts {
  NoirPro = "NoirPro",
  NoirProMedium = "NoirPro-Medium",
  OpenSans = "Open Sans",
  OpenSansMedium = "Open Sans Medium",
}

export default Fonts;
