import { gql } from "@apollo/client";

export const USERS = gql`
  query USERS($payload: UsersInput!) {
    users(payload: $payload) {
      total
      list {
        id
        firstName
        lastName
        email
        role
        confirmed
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const SET_USER_ROLE = gql`
  mutation SET_USER_ROLE($payload: UserRoleInput!) {
    setUserRole(payload: $payload) {
      status
    }
  }
`;

export const USER_BY_ID = gql`
  query USER_BY_ID($userId: ID!) {
    userById(userId: $userId) {
      id
      firstName
      lastName
      email
      role
      confirmed
      createdAt
      updatedAt
    }
  }
`;

export const PROFILE = gql`
  query PROFILE {
    profile {
      id
      firstName
      lastName
      email
      createdAt
    }
  }
`;
