import * as React from "react";
import { useNavigate } from "react-router-dom";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import ROUTES from "lib/routes";

const MemberWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
}));

interface MemberProps {
  rowData: GridRenderCellParams;
}
interface Member {
  firstName: string;
  userId: string;
}
export function WorkspaceMembersCell({ rowData }: MemberProps) {
  const navigate = useNavigate();
  const members: Member[] = rowData.row.members;

  const renderChip = (member: Member) => {
    const handleClick = () => {
      member.userId && navigate(ROUTES.DASHBOARD_USERS_ROUTE(member.userId));
    };
    return (
      <Chip
        key={`${member.firstName}-${member.userId}`}
        color="primary"
        sx={member.userId ? { textDecoration: "underline" } : {}}
        label={member.firstName}
        onClick={handleClick}
      />
    );
  };

  return <MemberWrapper>{members.map(renderChip)}</MemberWrapper>;
}
