import { gql } from "@apollo/client";

export const LOGIN = gql`
  query LOGIN($payload: LoginInput!) {
    login(payload: $payload) {
      token
      userId
      expiresAt
    }
  }
`;
