import React from "react";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { styled, Theme } from "@mui/material/styles";
import { H5 } from "components/typography";

const AvatarWrapper = styled("div")<{ size?: number; theme?: Theme }>(
  ({ theme, size = 35 }) => ({
    justifyContent: "center",
    alignItems: "center",
    width: size,
    height: size,
    borderRadius: size / 2,
    display: "flex",
    backgroundColor: theme.palette.shared.darkGray,
  }),
);
function PlaceholderBadge({
  placeholder,
  size,
}: {
  placeholder: string;
  size?: number;
}) {
  const fontSize = size ? { fontSize: size * 0.55 } : {};
  return (
    <AvatarWrapper size={size}>
      <H5 style={{ userSelect: "none", ...fontSize }}>{placeholder}</H5>
    </AvatarWrapper>
  );
}

PlaceholderBadge.defaultProps = {
  size: 35,
};

interface Props {
  src?: string;
  placeholder?: string;
  tooltip?: string;
  alt?: string;
  onClick?: () => void;
  size?: number;
}

function AvatarBadge({ onClick, src, size, placeholder, alt, tooltip }: Props) {
  const Wrapper = tooltip ? Tooltip : React.Fragment;
  const Button = onClick ? IconButton : React.Fragment;
  return (
    <Wrapper {...{ title: tooltip ?? "" }} style={{ padding: "6px" }}>
      <Button {...{ onClick }}>
        {src ? (
          <Avatar src={src} sx={{ width: size, height: size }} alt={alt} />
        ) : (
          <PlaceholderBadge size={size} placeholder={placeholder ?? ""} />
        )}
      </Button>
    </Wrapper>
  );
}

AvatarBadge.defaultProps = {
  src: null,
  placeholder: null,
  tooltip: null,
  alt: null,
  onClick: null,
  size: 35,
};

export default AvatarBadge;
