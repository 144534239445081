const ROUTES = {
  INDEX: "/",
  CITY_LANDING: "/city/:city",
  LOGIN: "/login",
  REGISTER: "/register",
  DASHBOARD: "/dashboard",

  // dashboard routes
  DASHBOARD_USERS: "/users",
  DASHBOARD_ORGANIZATIONS: "/organizations",
  DASHBOARD_USER_REQUESTS: "/user-requests",
  DASHBOARD_ORGANIZATIONS_DETAIL: "/organizations/:organizationId",
  DASHBOARD_WORKSPACES: "/workspaces",
  DASHBOARD_NEST_TYPES: "/nest-types",
  DASHBOARD_SERVICE_TYPES: "/service-types",
  DASHBOARD_WORKSPACE_TYPES: "/workspace-types",
  DASHBOARD_GROUP_TYPES: "/group-types",
  DASHBOARD_USER_ID: "/users/:userId",
  DASHBOARD_CATEGORIES: "/categories",
  DASHBOARD_PROVIDER_CATEGORIES: "/service-provider-categories",
  DASHBOARD_QUESTIONNAIRES: "/questionnaires",
  DASHBOARD_QUESTIONNAIRES_CREATE: "/questionnaires-create",
  DASHBOARD_SUBSCRIPTION_PLANS: "/subscription-plans",

  DASHBOARD_USERS_ROUTE: (userId: string) => `/dashboard/users/${userId}`,
  DASHBOARD_ORGANIZATIONS_ROUTE: (organizationId: string) =>
    `/dashboard/organizations/${organizationId}`,
  DASHBOARD_CATEGORIES_ROUTE: `/dashboard/categories`,
  DASHBOARD_PROVIDER_CATEGORIES_ROUTE: "/dashboard/service-provider-categories",
  DASHBOARD_QUESTIONNAIRES_ROUTE: `/dashboard/questionnaires`,
  DASHBOARD_QUESTIONNAIRES_CREATE_ROUTE: `/dashboard/questionnaires-create`,
  DASHBOARD_USERS_LIST_ROUTE: "/dashboard/users",
  DASHBOARD_WORKSPACES_ROUTE: "/dashboard/workspaces",
  DASHBOARD_NEST_TYPES_ROUTE: "/dashboard/nest-types",
  DASHBOARD_SERVICE_TYPES_ROUTE: "/dashboard/service-types",
  DASHBOARD_WORKSPACE_TYPES_ROUTE: "/dashboard/workspace-types",
  DASHBOARD_GROUP_TYPES_ROUTE: "/dashboard/group-types",
  DASHBOARD_SUBSCRIPTION_PLANS_ROUTE: "/dashboard/subscription-plans",
  DASHBOARD_ORGANIZATIONS_LIST_ROUTE: "/dashboard/organizations",
  DASHBOARD_USER_REQUESTS_ROUTE: "/dashboard/user-requests",
  DASHBOARD_ORGANIZATIONS_DETAIL_ROUTE:
    "/dashboard/organizations/:organizationId",
};

export default ROUTES;
