import { gql } from "@apollo/client";

export const NEST_TYPES = gql`
  query NEST_TYPES($includeAll: Boolean!) {
    nestTypes(includeAll: $includeAll) {
      total
      list {
        id
        title
        description
        titleDE
        descriptionDE
        featuresOptions
        securityFeaturesOptions
        spaceUsageOptions
        elevationOptions
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const CREATE_NEST_TYPE = gql`
  mutation CREATE_NEST_TYPE($payload: CreateNestTypeInput!) {
    createNestType(payload: $payload) {
      id
    }
  }
`;

export const DELETE_NEST_TYPE = gql`
  mutation DELETE_NEST_TYPE($id: ID!) {
    deleteNestType(id: $id) {
      status
    }
  }
`;

export const UPDATE_NEST_TYPE = gql`
  mutation UPDATE_NEST_TYPE($payload: UpdateNestTypeInput!) {
    updateNestType(payload: $payload) {
      id
    }
  }
`;

export const SPACE_AVAILABLE_OPTIONS = gql`
  query SPACE_AVAILABLE_OPTIONS {
    spaceAvailableOptions {
      featuresOptions
      securityFeaturesOptions
      spaceUsageOptions
      elevationOptions
    }
  }
`;
