import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, IconButton } from "@mui/material";
import { BodyTextLight, H4 } from "components/typography";
import InputRender from "components/input/InputRender";
import { FormDataType } from "components/input/types";
import { SubscriptionPlan } from "types/subscription";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import { pricingInputs } from "./constants";
import { Button, RowSpaced } from "components/shared";
import { getMonetaryValue } from "utils/monetary";
import { CountryFlag } from "components/input/fragments";

interface Props {
  showErrors: boolean;
  pricingState: FormDataType;
  setPricingState: React.Dispatch<React.SetStateAction<FormDataType>>;
  onAddPricing: () => void;
  onRemovePricing: (countryCode: string) => void;
  pricing: SubscriptionPlan["pricing"];
}

function PricingSection({
  showErrors,
  pricingState,
  setPricingState,
  onAddPricing,
  pricing,
  onRemovePricing,
}: Props) {
  const intl = useIntl();
  const theme = useTheme();
  const inputs = pricingInputs(intl);
  const [showForm, setShowForm] = useState(false);
  const toggleForm = () => setShowForm(!showForm);
  const HeaderIcon = showForm ? RemoveIcon : AddIcon;
  return (
    <Grid
      container
      mt={1}
      padding={1}
      alignItems="center"
      borderRadius={1}
      border={`1px solid ${theme.palette.action.active}`}
    >
      <Grid item xs={12} mb={1}>
        <RowSpaced style={{ alignItems: "center" }}>
          <H4>{intl.formatMessage({ id: "label.pricing" })}</H4>
          <IconButton size="large" onClick={toggleForm}>
            <HeaderIcon color="primary" fontSize="medium" />
          </IconButton>
        </RowSpaced>
      </Grid>

      {pricing.map((price) => (
        <Grid key={price.countryCode} item xs={12}>
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
            <CountryFlag
              loading="lazy"
              src={`https://flagcdn.com/w20/${price.countryCode.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${price.countryCode.toLowerCase()}.png 2x`}
              alt=""
            />
            <BodyTextLight>
              💰{getMonetaryValue(price)} + {`${price.tax}%`} - 🗓️{" "}
              {price.refundDeadline}
            </BodyTextLight>
            <IconButton
              size="large"
              onClick={() => onRemovePricing(price.countryCode)}
            >
              <DeleteIcon color="error" fontSize="small" />
            </IconButton>
          </Box>
        </Grid>
      ))}
      {showForm && (
        <React.Fragment>
          <Grid item xs={12}>
            {inputs.map((input) => {
              return (
                <InputRender
                  key={input.key}
                  data={pricingState}
                  input={input}
                  inputs={inputs}
                  setData={setPricingState}
                  showErrors={showErrors}
                />
              );
            })}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button variant="contained" color="primary" onClick={onAddPricing}>
              {intl.formatMessage({ id: "label.save" })}
            </Button>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
}

export default PricingSection;
