import React from "react";
import { Container, Content, PageTitle, Row } from "components/shared";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
// import LoadingIndicator from "components/LoadingIndicator";
import AddIcon from "@mui/icons-material/Add";
import ROUTES from "lib/routes";

function Questionnaire() {
  const intl = useIntl();

  return (
    <Container>
      {/* <LoadingIndicator visible={loading} /> */}
      <Content>
        <Row>
          <PageTitle>
            {intl.formatMessage({ id: "label.questionnaires" })}
          </PageTitle>
          <Link
            to={ROUTES.DASHBOARD_QUESTIONNAIRES_CREATE_ROUTE}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 10px",
            }}
          >
            <AddIcon color="primary" />
          </Link>
        </Row>
      </Content>
    </Container>
  );
}
export default Questionnaire;
