import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  Category,
  DeleteCategoryData,
  DeleteCategoryInput,
  UpdateCategoryData,
  UpdateCategoryInput,
} from "types/category";
import InputRender, { formHasErrors } from "components/input/InputRender";
import { Button, DrawerForm, ModalDrawer, ModalTitle } from "components/shared";
import { useMutation } from "@apollo/client";
import { Severity, withSnackbar } from "components/providers/SnackbarHOC";
import { CATEGORIES, DELETE_CATEGORY, UPDATE_CATEGORY } from "gql/category";
import {
  ConfirmationData,
  withConfirmationModal,
} from "components/hoc/ConfirmationModalHoc";
import { inputs } from "./constants";
import { FormDataType } from "components/input/types";

interface ContentProps {
  open: boolean;
  toggleDrawer: () => void;
  category: Category;
  categories: Category[];
  showSnackbar?: (message: string, severity: Severity) => void;
  requestConfirmation?: (data: ConfirmationData) => void;
}

function CreateCategoryDrawer({
  open,
  toggleDrawer,
  categories,
  category,
  showSnackbar,
  requestConfirmation,
}: ContentProps) {
  const intl = useIntl();
  const [showErrors, setShowErrors] = useState(false);
  const [data, setData] = useState<FormDataType>({
    title: category.title,
    description: category.description || "",
    titleDE: category.titleDE || "",
    descriptionDE: category.descriptionDE || "",
    published: category.published ? "published" : "unpublished",
    parentId: category.parentId || "",
  });

  const onComplete = () => toggleDrawer();
  const [updateCategory] = useMutation<UpdateCategoryData, UpdateCategoryInput>(
    UPDATE_CATEGORY,
    {
      onCompleted: onComplete,
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      refetchQueries: [{ query: CATEGORIES, variables: { includeAll: true } }],
    },
  );

  const [deleteCategory] = useMutation<DeleteCategoryData, DeleteCategoryInput>(
    DELETE_CATEGORY,
    {
      onCompleted: onComplete,
      onError: (error) => showSnackbar?.(error.message, Severity.ERROR),
      refetchQueries: [{ query: CATEGORIES, variables: { includeAll: true } }],
    },
  );

  const firstLevelCategories = categories.filter((cat) => !cat.parentId) ?? [];
  const secondLevelCategories = categories?.filter((cat) =>
    firstLevelCategories.some(
      (firstLevelCategory) => firstLevelCategory.id === cat.parentId,
    ),
  );
  const parentCategories = [
    ...firstLevelCategories,
    ...secondLevelCategories,
  ].filter((cat) => cat.id !== category.id); // remove self

  const handleOnsubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formHasErrors(inputs(parentCategories), data)) {
      setShowErrors(true);
      return;
    }
    const payload = {
      id: category.id,
      title: data.title as string,
      description: data.description as string,
      titleDE: data.titleDE as string,
      descriptionDE: data.descriptionDE as string,
      published: data.published === "published",
      parentId: !!data.parentId ? (data.parentId as string) : null,
    };
    updateCategory({ variables: { payload } });
  };

  const handleDelete = () =>
    deleteCategory({ variables: { categoryId: category.id } });

  const toggleDelete = () =>
    requestConfirmation?.({
      title: intl.formatMessage({ id: "label.deletingCategory" }),
      description: intl.formatMessage({
        id: "label.deletingCategoryConfirmation",
      }),
      actions: [
        {
          label: intl.formatMessage({ id: "label.cancel" }),
        },
        {
          label: intl.formatMessage({ id: "label.delete" }),
          color: "error",
          onClick: handleDelete,
        },
      ],
    });

  return (
    <ModalDrawer anchor="right" open={open} onClose={toggleDrawer}>
      <ModalTitle p={2}>
        {intl.formatMessage({ id: "label.category" })}
      </ModalTitle>
      <DrawerForm noValidate onSubmit={handleOnsubmit}>
        {inputs(parentCategories).map((input) => {
          return (
            <InputRender
              key={input.key}
              data={data}
              input={input}
              inputs={inputs(parentCategories)}
              setData={setData}
              showErrors={showErrors}
            />
          );
        })}
        <Button type="submit" fullWidth variant="contained" color="primary">
          {intl.formatMessage({ id: "label.update" })}
        </Button>
        {!category?.deletedAt && (
          <Button
            fullWidth
            onClick={toggleDelete}
            variant="contained"
            color="error"
            style={{ marginTop: "10px" }}
          >
            {intl.formatMessage({ id: "label.delete" })}
          </Button>
        )}
      </DrawerForm>
    </ModalDrawer>
  );
}

CreateCategoryDrawer.defaultProps = {
  showSnackbar: () => {},
  requestConfirmation: undefined,
};

export default withConfirmationModal(withSnackbar(CreateCategoryDrawer));
