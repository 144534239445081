import React from "react";
import { useTheme } from "@mui/material/styles";

interface Props {
  color?: string;
  width?: string;
}
function Svg({ color, width }: Props) {
  const theme = useTheme();
  return (
    <div style={{ width, display: "flex" }}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.9999 5.41239V25.9999C24.9999 26.2651 24.8945 26.5195 24.707 26.707C24.5195 26.8945 24.2651 26.9999 23.9999 26.9999H3.41239C3.2141 26.9996 3.02039 26.9403 2.85586 26.8296C2.69134 26.7189 2.56344 26.5618 2.48841 26.3783C2.41338 26.1947 2.39462 25.993 2.4345 25.7988C2.47438 25.6045 2.57111 25.4265 2.71239 25.2874L23.2874 4.71239C23.4265 4.57111 23.6045 4.47438 23.7988 4.4345C23.993 4.39462 24.1947 4.41338 24.3783 4.48841C24.5618 4.56344 24.7189 4.69134 24.8296 4.85586C24.9403 5.02039 24.9996 5.2141 24.9999 5.41239Z"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 8V27"
          stroke={color ?? theme.palette.text.secondary}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

Svg.defaultProps = {
  color: null,
  width: null,
};

export default Svg;
