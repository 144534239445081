import React from "react";
import { Theme } from "@mui/material";
import { SubscriptionStatus } from "types/subscription";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridText } from "components/shared";
import { getDate } from "utils/datetime";
import { getMonetaryValue } from "utils/monetary";

export function getStatusColor(
  theme: Theme,
  status: SubscriptionStatus | "EXPIRED",
) {
  switch (status) {
    case SubscriptionStatus.CANCELED:
      return theme.palette.shared.red;
    case SubscriptionStatus.PENDING:
      return theme.palette.text.secondary;
    case "EXPIRED":
      return theme.palette.shared.yellow;
    default:
      return theme.palette.text.primary;
  }
}

export const columns = (): GridColDef[] => [
  {
    field: "id",
    headerName: "#",
    width: 0,
    sortable: false,
    headerAlign: "center",
    hide: true,
  },

  {
    field: "productReference",
    headerName: "Product reference",
    align: "left",
    width: 140,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
  },
  {
    field: "productDescription",
    headerName: "Product description",
    align: "center",
    width: 280,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{row.value}</DataGridText>,
  },
  {
    field: "price",
    headerName: "Price",
    align: "center",
    width: 100,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{getMonetaryValue(row.value)}</DataGridText>
    ),
  },
  {
    field: "fromDate",
    headerName: "From Date",
    align: "center",
    width: 100,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{getDate(row.value)}</DataGridText>,
  },
  {
    field: "toDate",
    headerName: "To Date",
    align: "center",
    width: 100,
    headerAlign: "center",
    renderCell: (row) => <DataGridText>{getDate(row.value)}</DataGridText>,
  },
  {
    field: "invoiceReference",
    headerName: "Is refund",
    align: "center",
    width: 100,
    headerAlign: "center",
    renderCell: (row) => (
      <DataGridText>{!!row.value ? "Yes" : "No"}</DataGridText>
    ),
  },
];
